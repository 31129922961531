import { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import ContactEmail from './ContactEmail';
import CurrentPlan from './CurrentPlan';
import { useDispatch } from 'react-redux';
import { getCards } from 'store/slices/billingSlice';
import { options } from './utils/consts';
import PaymentMethod from './PaymentMethod';

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ManageBilling = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCards());
    }, [dispatch]);

    return (
        <>
            <div className="d-flex mx-5">
                <ContactEmail />
                <Elements stripe={stripePromise} options={options}>
                    <CurrentPlan />
                    <PaymentMethod />
                </Elements>
            </div>
        </>
    );
};

export default ManageBilling;
