import successImage from 'assets/img/success-img.png';
import unsuccessImage from 'assets/img/unsuccess-img.png';
import { Link } from 'react-router-dom';

export const ResultPayment = ({
    resultPayment,
    setIsShowModal,
    setResultPayment,
}) => {
    return (
        <div className="billing--modal-card">
            <div className="d-flex justify-content-center align-items-center flex-column">
                <div>
                    <img
                        src={
                            resultPayment.status === 'error'
                                ? unsuccessImage
                                : successImage
                        }
                        alt={resultPayment}
                        style={{ width: '150px', height: '150px' }}
                    />
                </div>
                <p className="mt-3">{resultPayment.message}</p>
                <Link
                    to="/billing"
                    onClick={() => {
                        setIsShowModal(false);
                        setResultPayment(null);
                    }}
                    className="link mt-3"
                >
                    Close
                </Link>
            </div>
        </div>
    );
};
