import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'ui/Modal';
import PagesListItem from './PagesListItem';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import { routeSelectors } from 'store/slices/routesSlice';
import { deletePage, getPages, getTrashedPages } from 'store/slices/pagesSlice';

const PagesList = ({ pages, selectedDropdown, toggleDropdown }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const isRoutesFetching = useSelector(routeSelectors.getIsRoutesFetching);

    const [isDeletePageModalShown, setIsDeletePageModalShown] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    useEffect(() => {
        dispatch(getPages({ projectUid: project.uid, apiId: api.id }));
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
    }, [api.id, dispatch, project.uid]);

    const handleClickAction = (e, data, action) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        switch (action) {
            case 'edit-page':
                history.push(
                    `/project/api/pages/${data.id}/edit?project=${project.uid}&api=${api.uid}`,
                );

                break;

            case 'delete-page':
                if (!data) {
                    toggleDropdown(undefined, null);
                }
                setIsDeletePageModalShown(!isDeletePageModalShown);
                setSelectedData(data);

                break;

            default:
                break;
        }
    };

    const onDeletePage = () => {
        if (selectedData) {
            dispatch(
                deletePage({
                    pageId: selectedData.id,
                    onSuccess: onDeletePageSuccess,
                }),
            );
        }
    };

    const onDeletePageSuccess = () => {
        setIsDeletePageModalShown(false);
        toast.success('Page successfuly deleted');
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getPages({ projectUid: project.uid, apiId: api.id }));
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
        handleClickAction(undefined, null, 'delete-page');
    };

    return (
        <>
            <ul className="nav flex-column">
                {!isRoutesFetching &&
                    pages.map((page) => {
                        return (
                            <PagesListItem
                                key={page.id}
                                page={page}
                                project={project}
                                api={api}
                                selectedDropdown={selectedDropdown}
                                toggleDropdown={toggleDropdown}
                                handleClickAction={handleClickAction}
                            />
                        );
                    })}
            </ul>

            <Modal
                show={isDeletePageModalShown}
                title="Delete"
                body={<p>This page will be deleted, are you sure ?</p>}
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDeletePage}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={(e) =>
                                handleClickAction(e, null, 'delete-page')
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </>
    );
};

export default PagesList;
