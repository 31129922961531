import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Modal = ({ customClasses, title, body, footer, show }) => {
    let classNames = ['modal', 'fade', 'modal-overlay'];
    if (show) {
        classNames.push('show');
    }

    const styleNames = {
        display: show ? 'block' : 'none',
        paddingRight: '15px',
    };

    return createPortal(
        <div className={classNames.join(' ')} style={styleNames} tabIndex="-1">
            <div className={`modal-dialog ${customClasses?.modalDialog || ''}`}>
                <div className="modal-content">
                    {title && (
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                        </div>
                    )}

                    {footer ? (
                        <Fragment>
                            <div className="modal-body">{body}</div>
                            <div className="modal-footer">{footer}</div>
                        </Fragment>
                    ) : (
                        <Fragment>{body}</Fragment>
                    )}
                </div>
            </div>
        </div>,
        document.getElementById('modal-root'),
    );
};

Modal.defaultProp = {
    show: false,
    title: '',
    body: '',
    footer: null,
};

Modal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customClasses: PropTypes.objectOf(PropTypes.string),
};
export default Modal;
