import { Link, NavLink } from 'react-router-dom';

import { isActiveApiTab } from 'utils/helpers';

const ApiNavigationPanel = (props) => {
    const { api, project } = props;

    return (
        <ul className="nav api-nav-tabs">
            <li className="nav-item">
                <NavLink
                    to={`/project/api/routes?project=${project.uid}&api=${api.uid}`}
                    className="nav-link tab-link-main px-3 py-3"
                    activeClassName="tab-link-main--active"
                    isActive={(match, location) =>
                        isActiveApiTab(location, 'routes') ||
                        isActiveApiTab(location, 'pages')
                    }
                >
                    Routes
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    to={`/project/api/components?project=${project.uid}&api=${api.uid}`}
                    className="nav-link tab-link-main px-3 py-3"
                    activeClassName="tab-link-main--active"
                >
                    Components
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    to={`/project/api/documentation?project=${project.uid}&api=${api.uid}`}
                    className="nav-link tab-link-main px-3 py-3"
                    activeClassName="tab-link-main--active"
                >
                    Documentation
                </NavLink>
            </li>
            <li className="nav-item">
                <Link
                    to="APIs"
                    className="nav-link tab-link-main px-3 py-3 disabled"
                >
                    Test
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    to="APIs"
                    className="nav-link tab-link-main px-3 py-3 disabled"
                >
                    Monitors
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    to="APIs"
                    className="nav-link tab-link-main px-3 py-3 disabled"
                >
                    Mock servers
                </Link>
            </li>
            {/* <li className="nav-item">
                <NavLink
                    to={`/project/api/history?project=${project.uid}&api=${api.uid}`}
                    className="nav-link tab-link-main px-3 py-3"
                    activeClassName="tab-link-main--active"
                >
                    History
                </NavLink>
            </li> */}
            <li className="nav-item">
                <NavLink
                    to={`/project/api/settings/summary?project=${project.uid}&api=${api.uid}`}
                    className="nav-link tab-link-main px-3 py-3"
                    activeClassName="tab-link-main--active"
                    isActive={(match, location) =>
                        isActiveApiTab(location, 'settings')
                    }
                >
                    Settings
                </NavLink>
            </li>
        </ul>
    );
};

export default ApiNavigationPanel;
