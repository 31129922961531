import React from 'react';
import CustomSelect from 'ui/CustomSelect';
import { Input } from 'ui/Inputs';

const selectOptions = [
    { label: 'float', value: 'float' },
    { label: 'double', value: 'double' },
];

export default function NumberExtraProps({
    extraProps,
    onChangeExtraProperties,
    onFormatSelectChange,
}) {
    return (
        <div>
            <div className="JsonSchemaEditor__schema-types-modal-extra-props">
                <div className="d-flex align-items-center mb-3 ps-1 me-3">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        defaultChecked={extraProps?.exlusiveMin || false}
                        onChange={onChangeExtraProperties}
                        id="exlusiveMin"
                    />
                    <label className="ms-2" htmlFor="exlusiveMin">
                        ExlusiveMin
                    </label>
                </div>
                <div className="d-flex align-items-center mb-3 ps-1">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        defaultChecked={extraProps?.exlusiveMax || false}
                        onChange={onChangeExtraProperties}
                        id="exlusiveMax"
                    />
                    <label className="ms-2" htmlFor="exlusiveMax">
                        ExlusiveMax
                    </label>
                </div>
            </div>
            <div className="mb-3 ps-1">
                <label className="JsonSchemaEditor__schema-types-modal-title">
                    FORMAT
                </label>
                <CustomSelect
                    name="format"
                    options={selectOptions}
                    onChange={onFormatSelectChange}
                    value={extraProps?.format}
                    isClearable={true}
                />
            </div>
            <div className="mb-3 ps-1">
                <Input
                    type="text"
                    id="default"
                    className="form-control"
                    value={extraProps?.default}
                    onChange={onChangeExtraProperties}
                    labelClassName="JsonSchemaEditor__schema-types-modal-title"
                    labelText="DEFAULT"
                    placeHolder="Default"
                />
            </div>
            <div className="mb-3 ps-1">
                <Input
                    type="text"
                    id="example"
                    className="form-control"
                    value={extraProps?.example}
                    onChange={onChangeExtraProperties}
                    labelClassName="JsonSchemaEditor__schema-types-modal-title"
                    labelText="EXAMPLE"
                    placeHolder="Example"
                />
            </div>
            <div
                className="mb-3 ps-1 d-flex justify-content-between"
                style={{ gridColumnGap: '20px' }}
            >
                <div>
                    <Input
                        type="number"
                        id="minimum"
                        className="form-control"
                        value={extraProps?.minimum}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MINIMUM"
                        placeHolder=">=0"
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        id="maximum"
                        className="form-control"
                        value={extraProps?.maximum}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MAXIMUM"
                        placeHolder=">=0"
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        id="multipleOf"
                        className="form-control"
                        value={extraProps?.multipleOf}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MULTIPLE_OF"
                        placeHolder=">=0"
                    />
                </div>
            </div>
        </div>
    );
}
