export const PaymentFooter = ({
    isDisabledButton,
    totalPrice,
    isCancelSubscribe,
    handleCancelSubscription,
    handleSubmit,
    isLoading,
}) => {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center pb-4">
                <p className="title-h5">Total</p>
                <h3 className="h3-title fw-semibold">€{totalPrice}</h3>
            </div>
            <button
                disabled={isDisabledButton}
                className="btn btn-primary w-100"
                onClick={
                    isCancelSubscribe ? handleCancelSubscription : handleSubmit
                }
            >
                <span id="button-text">
                    {isLoading ? (
                        <>
                            Processing...
                            <span className="spinner-border spinner-border-sm mx-2" />
                        </>
                    ) : (
                        <>{isCancelSubscribe ? 'Unsubscribe' : 'Subscribe'}</>
                    )}
                </span>
            </button>
        </>
    );
};
