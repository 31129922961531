import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import LocalLoader from 'ui/LocalLoader';
import { resourceSelectors } from 'store/slices/resourcesSlice';
import { Link, useLocation } from 'react-router-dom';
import { Plus } from 'react-feather';
import ModelsListItem from './ModelsListItem';

export default function ModelsListConfigurator({
    resources,
    selectedDropdown,
    toggleDropdown,
    handleClickAction,
    isShowModels,
    hasRights,
    inputSearch,
}) {
    const { pathname } = useLocation();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const isResourcesFetching = useSelector(
        resourceSelectors.getIsResourcesFetching,
    );

    if (!resources.length && inputSearch)
        return (
            <LocalLoader loading={isResourcesFetching} className="mt-5">
                <div
                    className={`nav flex-column nav-children ${
                        !isShowModels && 'd-none'
                    }`}
                >
                    <div className="create-route-panel not-found">
                        <p className="create-route-panel__desc">
                            No models found
                        </p>
                    </div>
                </div>
            </LocalLoader>
        );

    if (!resources.length && !hasRights)
        return (
            <LocalLoader loading={isResourcesFetching} className="mt-5">
                <div
                    className={`nav flex-column nav-children ${
                        !isShowModels && 'd-none'
                    }`}
                >
                    <div className="create-route-panel not-found">
                        <p className="create-route-panel__desc">
                            There are no models at the moment.
                        </p>
                    </div>
                </div>
            </LocalLoader>
        );

    if (!resources.length && hasRights)
        return (
            <LocalLoader loading={isResourcesFetching} className="mt-5">
                <div
                    className={`nav flex-column nav-children ${
                        !isShowModels && 'd-none'
                    }`}
                >
                    <Link
                        to={`/project/api/components/models/create?project=${project.uid}&api=${api.uid}`}
                        className={`create-route-panel ${
                            pathname.includes(
                                '/project/api/components/models/create',
                            ) && 'disabled'
                        }`}
                    >
                        <h6 className="create-route-panel__title">
                            <Plus size={16} />
                            <span>Create your first model</span>
                        </h6>
                        <p className="create-route-panel__desc">
                            You can edit and share with your team
                        </p>
                    </Link>
                </div>
            </LocalLoader>
        );

    return (
        <>
            {!!resources.length && (
                <ul
                    className={`nav flex-column nav-children ${
                        !isShowModels && 'd-none'
                    }`}
                >
                    {resources.map((resource) => {
                        return (
                            <ModelsListItem
                                key={resource.id}
                                resource={resource}
                                selectedDropdown={selectedDropdown}
                                toggleDropdown={toggleDropdown}
                                handleClickAction={handleClickAction}
                                project={project}
                                api={api}
                                myAccount={myAccount}
                            />
                        );
                    })}
                </ul>
            )}
        </>
    );
}
