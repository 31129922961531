import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const getEnvironments = createAsyncThunk(
    'environments/getEnvironments',
    async ({ projectUid, apiId }) => {
        try {
            const response = await http.get(
                `/api/v1/project/${projectUid}/api/${apiId}/environments`,
            );

            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const getEnvironment = createAsyncThunk(
    'environments/getEnvironment',
    async ({ envId }, { dispatch }) => {
        try {
            dispatch(isEnvironmentFetching(true));
            const response = await http.get(`/api/v1/api/environment/${envId}`);
            dispatch(isEnvironmentFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isEnvironmentFetching(false));
            console.log(error);
        } finally {
            dispatch(isEnvironmentFetching(false));
        }
    },
);

export const createEnvironment = createAsyncThunk(
    'environments/createEnvironment',
    async ({ projectUid, apiId, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${projectUid}/api/${apiId}/environment`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteEnvironment = createAsyncThunk(
    'environments/deleteEnvironment',
    async ({ envId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(`/api/v1/api/environment/${envId}`);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const updateEnvironment = createAsyncThunk(
    'environments/updateEnvironment',
    async ({ data, envId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `/api/v1/api/environment/${envId}`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

const initialState = {
    environments: [],
    environment: {},
    selectedEnvironments: [],
    isEnvironmentFetching: false,
};

export const environmentsSlice = createSlice({
    name: 'environments',
    initialState,
    reducers: {
        setSelectedEnvironments(state, action) {
            state.selectedEnvironments = action.payload;
        },
        isEnvironmentFetching(state, action) {
            state.isEnvironmentFetching = action.payload;
        },
    },
    extraReducers: {
        [getEnvironment.fulfilled]: (state, action) => {
            state.environment = action.payload;
        },
        [getEnvironments.fulfilled]: (state, action) => {
            state.environments = action.payload;
        },
    },
});

// Actions

export const { setSelectedEnvironments, isEnvironmentFetching } =
    environmentsSlice.actions;

// Selectors

export const environmentSelectors = {
    getEnvironments: (state) => state.environments.environments,
    getSelectedEnvironments: (state) => state.environments.selectedEnvironments,
};

export default environmentsSlice.reducer;
