import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import Table from './Table';
import LocalLoader from 'ui/LocalLoader';
import Multiselect from 'ui/Multiselect';
import Modal from 'ui/Modal';
import { InputSearch } from 'ui/Inputs';
import CreateModal from './CreateModal';
import MainContent from 'ui/MainContent';
import { authSelectors } from 'store/slices/authSlice';
import {
    cancelTransferProject,
    createProject,
    deleteProject,
    getProjects,
    isCreateProjectModalShown,
    projectSelectors,
    restoreProject,
    toggleFavoriteProject,
    trashProject,
} from 'store/slices/projectsSlice';

const multiselectOptions = [
    { name: 'My Projects', id: 'my-projects' },
    { name: 'Trashed Projects', id: 'trashed' },
];

const ProjectsPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const myAccount = useSelector(authSelectors.getMyAccount);
    const projects = useSelector(projectSelectors.getProjects);
    const isProjectsFetching = useSelector(
        projectSelectors.getIsProjectsFetching,
    );
    const isCreateProjectModal = useSelector(
        projectSelectors.getIsCreateProjectModalShown,
    );
    const [inputSearch, setInputSearch] = useState('');
    // const [selectedProject, setSelectedProject] = useState({})
    const [projectUid, setProjectUid] = useState(null);
    const [modalShown, setModalShown] = useState({
        isTrashModalShown: false,
        isDeleteModalShown: false,
        isCancelTransferModalShown: false,
    });

    let projectsList = [];
    if (projects.length > 0) {
        projectsList = projects.filter((project) => {
            return (
                project.name
                    .toLowerCase()
                    .indexOf(inputSearch.toLowerCase()) !== -1
            );
        });
    }

    const isTrashed = location.search.includes('trashed');
    const isAccessToCreateProject =
        myAccount.plan.max_projects_count > myAccount.projects_count ||
        myAccount.plan.max_projects_count === null;

    useEffect(() => {
        const p = new URLSearchParams(location.search).getAll('filter[]');

        dispatch(getProjects({ filters: p }));
    }, [dispatch, location.search]);

    const toggleStarred = (project) => {
        dispatch(toggleFavoriteProject({ projectUid: project.uid }));
    };

    const handleChange = (e) => {
        setInputSearch(e.target.value);
    };

    const updateWithFilters = (filters) => {
        let p = [];
        filters.forEach((el) => p.push(el.id));
        dispatch(getProjects({ filters: p }));
    };

    const isTrashModalShown = (flag, projectUid = null) => {
        setModalShown({ ...modalShown, isTrashModalShown: flag });
        setProjectUid(projectUid);
    };

    const handleTrashModal = (e, project) => {
        e.preventDefault();
        isTrashModalShown(true, project.uid);
    };

    const isDeleteModalShown = (flag, projectUid = null) => {
        setModalShown({ ...modalShown, isDeleteModalShown: flag });
        setProjectUid(projectUid);
    };

    const handleDeleteModal = (e, project) => {
        e.preventDefault();
        isDeleteModalShown(true, project.uid);
    };

    const isCancelTransferModalShown = (flag, projectUid = null) => {
        setModalShown({ ...modalShown, isCancelTransferModalShown: flag });
        setProjectUid(projectUid);
    };

    const handleCancelTransferModal = (e, project) => {
        e.preventDefault();
        isCancelTransferModalShown(true, project.uid);
    };

    const onCreated = (response) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        toast.success('The project has been created');

        dispatch(getProjects({ filters: p }));
        dispatch(isCreateProjectModalShown(false));
    };

    const onTrash = () => {
        dispatch(
            trashProject({
                uid: projectUid,
                onSuccess: onSuccessTrash,
                onError,
            }),
        );
        isTrashModalShown(false);
    };

    const onSuccessTrash = (response) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        toast.success('The project has been moved to trash');
        dispatch(getProjects({ filters: p }));
    };

    const onRestore = (e, project) => {
        e.preventDefault();

        dispatch(
            restoreProject({
                uid: project.uid,
                onSuccess: onSuccessRestore,
                onError,
            }),
        );
    };

    const onSuccessRestore = (response) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        toast.success('The project has been restored');
        dispatch(getProjects({ filters: p }));
    };

    const onDelete = () => {
        dispatch(
            deleteProject({
                uid: projectUid,
                onSuccess: onSuccessDelete,
                onError,
            }),
        );
        isDeleteModalShown(false);
    };

    const onSuccessDelete = (response) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        toast.success('The project has been permanently deleted');
        dispatch(getProjects({ filters: p }));
    };

    const onError = (error) => {
        toast.error('Something went wrong, please try again later.');
    };

    const onCancelTransfer = () => {
        dispatch(
            cancelTransferProject({
                uid: projectUid,
                onSuccess: onSuccessCancelTransfer,
                onError,
            }),
        );
        isCancelTransferModalShown(false);
    };

    const onSuccessCancelTransfer = () => {
        toast.success('The project’s transfer has been canceled');
        setTimeout(() => {
            window.location.href = '/projects';
        }, 700);
    };

    const onSubmit = (newData, onCreated, onResponse, onError, headers) => {
        dispatch(
            createProject({
                data: newData,
                onSuccess: onCreated,
                onResponse,
                onError,
                headers,
            }),
        );
    };

    return (
        <MainContent>
            <div className="main-container">
                <Helmet>
                    <title>
                        Projects - CDProjects - Create beautiful REST API
                        Documentations
                    </title>
                </Helmet>

                <div className="page-header d-flex flex-row justify-content-between mx-sm-5 mx-3">
                    <h1 className="title-h3">Projects</h1>
                </div>

                <div className="projects-menu-container main-container mx-5 mb-3">
                    <div className="d-flex">
                        <div className="input-group input-search-container mb-4">
                            <InputSearch
                                id="input-search"
                                name="inputSearch"
                                type="text"
                                inputStyle={{
                                    width: '300px',
                                    padding: '0.5rem 0.8rem 0.5rem 2rem',
                                }}
                                className="form-control"
                                value={inputSearch}
                                onChange={handleChange}
                            />
                        </div>
                        <Multiselect
                            options={multiselectOptions}
                            onChange={updateWithFilters}
                        />
                    </div>
                </div>

                <div className="project-list">
                    <LocalLoader loading={isProjectsFetching}>
                        <Table
                            projects={projectsList}
                            toggleFavorite={toggleStarred}
                            handleTrashModal={handleTrashModal}
                            handleDeleteModal={handleDeleteModal}
                            handleCancelTransferModal={
                                handleCancelTransferModal
                            }
                            handleRestoreProject={onRestore}
                            isTrashed={isTrashed}
                            myAccount={myAccount}
                        />
                    </LocalLoader>
                </div>

                <Modal
                    show={isCreateProjectModal}
                    title="Create a new Project"
                    body={
                        <CreateModal
                            onClose={() =>
                                dispatch(isCreateProjectModalShown(false))
                            }
                            onSubmit={onSubmit}
                            onCreated={onCreated}
                            isAccessToCreateProject={isAccessToCreateProject}
                        />
                    }
                />

                <Modal
                    show={modalShown.isTrashModalShown}
                    title="Move to trash"
                    body="This Project will be moved to trash, are you sure ?"
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onTrash}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={() => isTrashModalShown(false)}
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />

                <Modal
                    show={modalShown.isDeleteModalShown}
                    title="Delete project"
                    body="This Project will be deleted permanently, are you sure ?"
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onDelete}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={() => isDeleteModalShown(false)}
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
                <Modal
                    show={modalShown.isCancelTransferModalShown}
                    title="Cancel transfer"
                    body="This Project transfer will be canceled, are you sure ?"
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onCancelTransfer}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    isCancelTransferModalShown(false)
                                }
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
            </div>
        </MainContent>
    );
};

export default ProjectsPage;
