import axios from 'axios';
import { deleteTokens, isLoggedIn } from './auth';
import { toast } from 'react-toastify';
import { getCookie } from './cookie';

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = "http://api.cdprojects.local";

export const CancelToken = axios.CancelToken;

const http = axios.create({
    baseURL: baseURL,
});

http.defaults.headers = {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
};

if (isLoggedIn()) {
    const access_token = getCookie('access_token');
    http.defaults.headers['Authorization'] = `Bearer ${access_token}`;
}

const responseHandler = (response) => {
    // Handle responses
    return response;
};

const errorHandler = (error) => {
    switch (error.response.status) {
        case 401:
            deleteTokens();
            window.location.pathname = '/login';
            break;
        case 404:
            toast.error(
                'The data you are trying to reach is temporary unavailable',
            );
            break;
        case 500:
            toast.error(error.response.data.errors);
            break;
        default:
            break;
    }
    return Promise.reject({ ...error });
};

http.interceptors.request.use((request) => {
    // Add your logic here
    return request;
});

http.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
);

export default http;
