import React, { memo } from 'react';
import ListItem from './ListItem';

const memoList = memo(function List({
    list,
    deleteElement,
    addElement,
    duplicateElement,
    moveTop,
    moveBottom,
    onKeyNameChange,
    onChangeType,
    changeProperties,
    onSetRefType,
    isCollapsed,
    setCollapsed,
    schemaName,
    models,
    onChangeNullable,
    onChangeEnum,
    onChangeEnumValues,
    changeExtraProperties,
}) {
    return (
        <>
            {list.map((listItem, i) => (
                <ListItem
                    key={listItem.id}
                    listItem={listItem}
                    schemaName={schemaName}
                    models={models}
                    isCollapsed={isCollapsed}
                    setCollapsed={setCollapsed}
                    addElement={addElement}
                    deleteElement={deleteElement}
                    duplicateElement={duplicateElement}
                    idx={i}
                    listLength={list.length}
                    moveBottom={moveBottom}
                    moveTop={moveTop}
                    onKeyNameChange={onKeyNameChange}
                    onChangeType={onChangeType}
                    changeProperties={changeProperties}
                    onSetRefType={onSetRefType}
                    onChangeNullable={onChangeNullable}
                    onChangeEnum={onChangeEnum}
                    onChangeEnumValues={onChangeEnumValues}
                    changeExtraProperties={changeExtraProperties}
                />
            ))}
        </>
    );
});

export default memoList;
