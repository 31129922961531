import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const updateDocumentationAccess = createAsyncThunk(
    'documentation/updateDocumentationAccess',
    async ({ projectUid, apiUid, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `/api/v1/project/${projectUid}/api/${apiUid}/documentation`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getDocumentationUsers = createAsyncThunk(
    'documentation/getDocumentationUsers',
    async ({ id, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(isDocumentationUsersFetching(true));
            const response = await http.get(
                `/api/v1/api/${id}/documentation/users`,
            );
            onSuccess(response);
            dispatch(isDocumentationUsersFetching(false));
        } catch (error) {
            onError(error);
            dispatch(isDocumentationUsersFetching(false));
        } finally {
            dispatch(isDocumentationUsersFetching(false));
        }
    },
);

const initialState = {
    isDocumentationUsersFetching: false,
};

export const documentationSlice = createSlice({
    name: 'documentation',
    initialState,
    reducers: {
        isDocumentationUsersFetching(state, action) {
            state.isDocumentationUsersFetching = action.payload;
        },
    },
    extraReducers: {},
});

// Actions

export const { isDocumentationUsersFetching } = documentationSlice.actions;

// Selectors

export const documentationSelectors = {
    getIsDocumentationUsersFetching: (state) =>
        state.documentation.isDocumentationUsersFetching,
};

export default documentationSlice.reducer;
