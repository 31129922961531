import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const fetchApi = createAsyncThunk(
    'api/fetchApi',
    async ({ projectUid, apiUid, apiCode }) => {
        const url = apiCode
            ? `api/${apiUid}`
            : `project/${projectUid}/api/${apiUid}`;
        try {
            const { data } = await http.get(`api/v1/${url}`);
            return data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const getApiList = createAsyncThunk(
    'api/getApiList',
    async ({ projectUid, filters = [] }, { dispatch }) => {
        try {
            dispatch(isApisFetching(true));
            let searchParams = new URLSearchParams();
            if (filters.find((el) => el === 'trashed')) {
                searchParams.append('filter[]', 'trashed');
            }
            const query = searchParams.toString()
                ? `?${searchParams.toString()}`
                : '';
            const response = await http.get(
                `/api/v1/project/${projectUid}/api${query}`,
            );
            dispatch(isApisFetching(false));

            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const createApi = createAsyncThunk(
    'api/createApi',

    async ({ projectUid, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${projectUid}/api`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const trashApi = createAsyncThunk(
    'api/trashApi',
    async ({ projectUid, apiUid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(
                `/api/v1/project/${projectUid}/api/${apiUid}/trash`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteApi = createAsyncThunk(
    'api/deleteApi',
    async ({ projectUid, apiUid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(
                `/api/v1/project/${projectUid}/api/${apiUid}/delete`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const restoreApi = createAsyncThunk(
    'api/restoreApi',
    async ({ projectUid, apiUid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${projectUid}/api/${apiUid}/restore`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const updateApi = createAsyncThunk(
    'api/updateApi',
    async ({ projectUid, apiUid, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `api/v1/project/${projectUid}/api/${apiUid}`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const patchApi = createAsyncThunk(
    'api/patchApi',
    async ({ projectUid, apiUid, data, onError }, { dispatch }) => {
        dispatch(showGlobalLoader());
        try {
            await http.patch(
                `/api/v1/project/${projectUid}/api/${apiUid}`,
                data,
            );
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        }
    },
);

export const moveApi = createAsyncThunk(
    'api/moveApi',
    async ({ projectUid, api, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${projectUid}/api/${api.uid}/transfer`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response, api.name, data);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const importCollection = createAsyncThunk(
    'api/importCollection',
    async (
        { projectUid, apiUid, data, onSuccess, onError, headers },
        { dispatch },
    ) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `api/v1/project/${projectUid}/api/${apiUid}/import`,
                data,
                {
                    headers,
                },
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const exportPostmanCollection = createAsyncThunk(
    'api/exportPostmanCollection',
    async ({ projectUid, apiUid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `api/v1/project/${projectUid}/api/${apiUid}/collection/postman/export`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const exportSwaggerCollection = createAsyncThunk(
    'api/exportSwaggerCollection',
    async ({ projectUid, apiUid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `api/v1/project/${projectUid}/api/${apiUid}/collection/swagger/export`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const copyApi = createAsyncThunk(
    'api/copyApi',
    async (
        { projectUid, apiUid, data, onSuccess, onError, isCopyModalShown },
        { dispatch },
    ) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(
                `/api/v1/project/${projectUid}/api/${apiUid}/copy`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(data);
            isCopyModalShown(false);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

const initialState = {
    api: {},
    isApiFetching: false,
    apis: [],
    isApisFetching: false,
    isCreateApiModalShown: false,
};

export const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        isApisFetching(state, action) {
            state.isApisFetching = action.payload;
        },
        isCreateApiModalShown(state, action) {
            state.isCreateApiModalShown = action.payload;
        },
        isApiFetching(state, action) {
            state.isApiFetching = action.payload;
        },
    },
    extraReducers: {
        [fetchApi.fulfilled]: (state, action) => {
            state.api = action.payload;
        },
        [getApiList.fulfilled]: (state, action) => {
            state.apis = action.payload;
        },
    },
});

// Actions

export const { isCreateApiModalShown, isApisFetching, isApiFetching } =
    apiSlice.actions;

// Selectors

export const apiSelectors = {
    getCurrentApi: (state) => state.api.api,
    getApis: (state) => state.api.apis,
    getIsApisFetching: (state) => state.api.isApisFetching,
    getIsCreateApiModalShown: (state) => state.api.isCreateApiModalShown,
};

export default apiSlice.reducer;
