import { FREE_PLAN_PRICE } from 'pages/Billing/ManageBilling/utils/consts';
import { Input } from 'ui/Inputs';

export const PlanList = ({ plans, selectedPlanId, handleChangePlan }) => {
    return (
        <div>
            <h6 className="title-h5 pb-2">Choose your plan</h6>
            {plans?.map((plan) => {
                const { id, name, cost, price_id, code } = plan;
                const price = cost ? `€${cost}` : FREE_PLAN_PRICE;

                return (
                    <label
                        className="billing--modal-card d-flex align-items-center cursor-pointer"
                        key={id}
                        htmlFor={code}
                    >
                        <Input
                            className="form-check-input m-0"
                            name={price_id}
                            type="radio"
                            checked={selectedPlanId === id}
                            onChange={() => handleChangePlan(plan)}
                            id={code}
                        />
                        <div className="ms-3 w-100">
                            <div className="d-flex justify-content-between align-items-center mt-1">
                                <p className="billing--paragraph fw-semibold opacity-100">
                                    {name}
                                </p>
                                <p className="billing--paragraph fw-semibold opacity-100">
                                    {price}
                                </p>
                            </div>
                        </div>
                    </label>
                );
            })}
        </div>
    );
};
