import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
// import qs from 'qs';

export const createSubscription = createAsyncThunk(
    'billing/createSubscription',

    async ({ data, onSuccess, onError }) => {
        try {
            const response = await http.post(
                `/api/v1/billing/subscriptions`,
                data,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

export const getPlans = createAsyncThunk('billing/getPlans', async () => {
    try {
        const response = await http.get(`/api/v1/billing/plans`);

        return response.data.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const getInvoices = createAsyncThunk(
    'billing/getInvoices',
    async ({ params }) => {
        try {
            // const query = `?${qs.stringify(params)}`;
            // const response = await http.get(`/api/v1/billing/invoices${query}`);
            const invoices = [
                {
                    id: 1,
                    title: 'Invoice #002 - Mar 2024',
                    billingDate: '2024-03-18T16:35:22.000000Z',
                    isPaid: true,
                    amount: 49,
                    plan: 'Medium',
                },
                {
                    id: 2,
                    title: 'Invoice #001 - Jan 2024',
                    billingDate: '2024-01-01T07:35:22.000000Z',
                    isPaid: false,
                    amount: 49,
                    plan: 'Medium',
                },
            ];
            return invoices;
        } catch (error) {
            throw new Error(error);
        }
    },
);

export const downloadInvoice = createAsyncThunk(
    'billing/downloadInvoice',
    async (invoiceId) => {
        try {
            const response = await http.get(
                `/api/v1/billing/invoice/${invoiceId}`,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    },
);

export const getCards = createAsyncThunk('billing/getCards', async () => {
    try {
        const response = await http.get(`/api/v1/billing/cards`);

        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const cancelSubscription = createAsyncThunk(
    'billing/cancelSubscription',
    async (onSuccess, onError) => {
        try {
            await http.post('/api/v1/billing/cancel-subscription', {
                customerId: 'cus_PAnLnjLC15w0w5',
            });
            onSuccess();
        } catch (error) {
            onError(error);
        }
    },
);

export const createCard = createAsyncThunk(
    'billing/createCard',

    async ({ data, onSuccess, onError }) => {
        try {
            const response = await http.post('/api/v1/billing/cards', data);
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

export const updateDefaultCard = createAsyncThunk(
    'billing/updateDefaultCard',

    async ({ id, data, onSuccess, onError }) => {
        try {
            const response = await http.patch(
                `/api/v1/billing/cards/${id}`,
                data,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

export const deleteCard = createAsyncThunk(
    'billing/deleteCard',

    async ({ id, onSuccess, onError }) => {
        try {
            const response = await http.delete(`/api/v1/billing/cards/${id}`);
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

const initialState = {
    plans: [],
    invoices: [],
    cards: [],
    isPlansFetching: false,
    isInvoicesFetching: false,
    isCardsFetching: false,
    selectedPlan: null,
};

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        setSelectedPlan(state, action) {
            state.selectedPlan = action.payload;
        },
        resetPlans(state) {
            state.plans = [];
        },
    },
    extraReducers: {
        [getPlans.fulfilled]: (state, action) => {
            state.plans = action.payload;
            state.isPlansFetching = false;
        },
        [getPlans.pending]: (state) => {
            state.isPlansFetching = true;
        },
        [getPlans.rejected]: (state) => {
            state.isPlansFetching = false;
        },
        [getInvoices.fulfilled]: (state, action) => {
            state.invoices = action.payload;
            state.isInvoicesFetching = false;
        },
        [getInvoices.pending]: (state) => {
            state.isInvoicesFetching = true;
        },
        [getInvoices.rejected]: (state) => {
            state.isInvoicesFetching = false;
        },
        [getCards.fulfilled]: (state, action) => {
            state.cards = action.payload;
            state.isCardsFetching = false;
        },
        [getCards.pending]: (state) => {
            state.isCardsFetching = true;
        },
        [getCards.rejected]: (state) => {
            state.isCardsFetching = false;
        },
    },
});

// Actions

export const { setSelectedPlan } = billingSlice.actions;

// Selectors

export const billingSelectors = {
    getAllPlans: (state) => state.billing.plans,
    getIsPlansFetching: (state) => state.billing.isPlansFetching,
    getSelectedPlan: (state) => state.billing.selectedPlan,
    getSelectedPlanId: (state) => state.billing.selectedPlan?.id,
    getAllInvoices: (state) => state.billing.invoices,
    getIsInvoicesFetching: (state) => state.billing.isInvoicesFetching,
    getAllCards: (state) => state.billing.cards,
    getIsCardsFetching: (state) => state.billing.isCardsFetching,
};

export default billingSlice.reducer;
