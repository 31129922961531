import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { PasswordField } from 'ui/Inputs';
import { changeMyPassword } from 'store/slices/authSlice';

const initialStateData = {
    password_current: '',
    password: '',
    password_confirmation: '',
};

const UserPasswordForm = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState(initialStateData);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changeMyPassword({ data, onSuccess, onError }));
    };

    const onSuccess = (response) => {
        toast.success('Your password has been changed.');
        setData(initialStateData);
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <div>
            <form style={{ maxWidth: '500px' }}>
                <PasswordField
                    label="Current password"
                    className="form-control user-password-input"
                    placeHolder="Current password"
                    value={data.password_current}
                    onChange={handleChange}
                    name="password_current"
                    errorText={errors.password_current}
                    isRequired={true}
                />
                <PasswordField
                    label="New password"
                    className="form-control user-password-input"
                    placeHolder="New password"
                    value={data.password}
                    onChange={handleChange}
                    name="password"
                    errorText={errors.password}
                    isRequired={true}
                />
                <PasswordField
                    label="Confirm new password"
                    className="form-control user-password-input"
                    placeHolder="Confirm new password"
                    value={data.password_confirmation}
                    onChange={handleChange}
                    name="password_confirmation"
                    errorText={errors.password_confirmation}
                    isRequired={true}
                />
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary btn-right"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default UserPasswordForm;
