import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'react-collapse';
import { toast } from 'react-toastify';

import Users from './Users/Users';
import Environments from './Environments';
import CustomSelect from 'ui/CustomSelect';
import LocalLoader from 'ui/LocalLoader';
import RequiredMark from 'ui/RequiredMark/RequiredMark';
import { projectSelectors } from 'store/slices/projectsSlice';
import { authSelectors } from 'store/slices/authSlice';
import { apiSelectors, fetchApi } from 'store/slices/apiSlice';
import { environmentSelectors } from 'store/slices/environmentsSlice';
import {
    documentationSelectors,
    getDocumentationUsers,
    updateDocumentationAccess,
} from 'store/slices/documentationSlice';

const options = [
    { value: 'PRIVATE', label: 'Private' },
    { value: 'PROTECTED', label: 'Protected' },
    { value: 'PUBLIC', label: 'Public' },
];

const AccessForm = () => {
    const dispatch = useDispatch();

    const project = useSelector(projectSelectors.getCurrentProject);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const api = useSelector(apiSelectors.getCurrentApi);
    const currentEnvironments = useSelector(
        environmentSelectors.getEnvironments,
    );
    const isDocumentationUsersFetching = useSelector(
        documentationSelectors.getIsDocumentationUsersFetching,
    );

    const [data, setData] = useState({ access: 'Private', url: '' });
    const [users, setUsers] = useState([]);
    const [environments, setEnvironments] = useState([]);
    const [errors, setErrors] = useState({});
    const hasRights =
        project.user_role === 'MAINTAINER' ||
        myAccount.id === project.owner?.id;

    useEffect(() => {
        setData({
            // ...data,
            access: api.access || 'PRIVATE',
            url: api.public_url || 'https://877ertpjkdfg.cdprojects.com',
        });
        if (hasRights) {
            dispatch(
                getDocumentationUsers({
                    id: api.id,
                    onSuccess: onSuccessUsersFetch,
                    onError: onErrorUsersFetch,
                }),
            );
        }
    }, [api.access, api.public_url, api.id, dispatch, hasRights]);

    const onSuccessUsersFetch = (response) => {
        setUsers(response.data.data);
    };

    const onErrorUsersFetch = (error) => {
        toast.error('Something went wrong, please try again later.');
    };

    // const handleChange = e => {
    //     const { name, value } = e.target
    //     setData({ ...data, [name]: value })
    // }

    const handleChangeAccess = (option) => {
        setData({ ...data, access: option.value });
        setErrors({});

        if (users.length === 0) {
            addParameter();
        }
    };

    const addParameter = (e) => {
        setUsers([...users, { username: '', password: '', description: '' }]);
    };

    const deleteParameterRow = (e, index) => {
        e.preventDefault();

        const filteredUsersArray = users.filter((el, i) => i !== index);

        setUsers(filteredUsersArray);
    };

    const changeParameter = useCallback(
        (e, index) => {
            const { name, value } = e.target;
            let usersDataItem = users[index];

            usersDataItem[name] = value;
            users[index] = { ...usersDataItem };

            setUsers([...users]);
        },
        [users],
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        let formatedData = {
            users: users,
            access: data.access,
            environments,
        };

        if (data.access !== 'PROTECTED') {
            formatedData = { access: data.access, environments };
        }

        dispatch(
            updateDocumentationAccess({
                projectUid: project.uid,
                apiUid: api.uid,
                data: formatedData,
                onSuccess,
                onError,
            }),
        );
    };

    const onSuccess = (response) => {
        toast.success('Data has been saved.');
        dispatch(fetchApi({ projectUid: project.uid, apiUid: api.uid }));
        setData({
            ...data,
            access: response.data.data.access,
            url: response.data.data.public_url,
        });
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const onSetEnvironments = useCallback((environments) => {
        const envs = !environments ? [] : environments;
        setEnvironments(envs);
    }, []);

    return (
        <form className="col-lg-12 col-xl-10">
            <div className="form-group mb-4">
                <label className="invite-label mb-2 fw-bold">
                    Access
                    <RequiredMark />
                </label>
                <CustomSelect
                    name="role"
                    options={options}
                    onChange={handleChangeAccess}
                    value={data.access}
                    isDisabled={!hasRights}
                />
            </div>
            {data.access === 'PROTECTED' && hasRights && (
                <div className="form-group mb-4">
                    <div
                        className="d-flex justify-content-between align-items-center ps-3 pe-2 mb-2"
                        style={{
                            borderBottom: '2px solid #edeff3',
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <h6>Users</h6>
                        </div>
                        <button
                            type="button"
                            className="btn btn-light mb-1 mt-1 text-start"
                            onClick={addParameter}
                        >
                            +
                        </button>
                    </div>
                    <LocalLoader loading={isDocumentationUsersFetching}>
                        <Collapse isOpened={true}>
                            <Users
                                items={users}
                                changeParam={(e, index) =>
                                    changeParameter(e, index)
                                }
                                deleteRow={(e, index) =>
                                    deleteParameterRow(e, index)
                                }
                                errors={errors}
                            />
                        </Collapse>
                    </LocalLoader>
                </div>
            )}
            {currentEnvironments?.length > 0 && hasRights && (
                <Environments
                    environments={currentEnvironments}
                    environmentsIds={environments}
                    publicEnvIds={api.public_environments_ids}
                    setEnvironments={onSetEnvironments}
                />
            )}
            <div className="form-group mb-4">
                <p className="mb-2 fw-bold">Public url</p>
                <div className="p-3 heading-tab">
                    <a
                        className="text-break d-inline-block link-underline"
                        href={`${data.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >{`${data.url}`}</a>
                </div>
            </div>
            <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary"
                disabled={!hasRights}
            >
                Save
            </button>
        </form>
    );
};

export default AccessForm;
