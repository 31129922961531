import React from 'react';
import { AlertCircle } from 'react-feather';
import { toast } from 'react-toastify';

const wrapper = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    padding: '12px 0',
    zIndex: 2,
    background: '#fef6eb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#F9A620',
};

const text = {
    fontWeight: 600,
    marginLeft: '12px',
};

const link = {
    textDecoration: 'underline',
    cursor: 'pointer',
};

export default function ConfirmEmail({ myAccount, onClickConfirm }) {
    const rootStyle = ':root{--topMessageHeight:48px;}';

    const onConfirm = () => {
        const data = {
            email: myAccount.email,
        };
        onClickConfirm(data, () =>
            toast.success(
                'The confirmation has been sent to your email address',
            ),
        );
    };

    return !myAccount.email_verified_at ? (
        <div style={wrapper}>
            <style>{rootStyle}</style>
            <AlertCircle size={24} color="#F9A620" />
            <span style={text}>
                Your email address is not confirmed. If you haven’t received the
                confirmation email, please resend it{' '}
                <span onClick={onConfirm} style={link}>
                    here
                </span>
                !
            </span>
        </div>
    ) : null;
}
