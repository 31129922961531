import React from 'react';
import { Input } from 'ui/Inputs';

export default function ObjectExtraProps({
    extraProps,
    onChangeExtraProperties,
}) {
    return (
        <div>
            <div className="mb-3 ps-1 d-flex justify-content-between">
                <div>
                    <Input
                        type="number"
                        id="minProperties"
                        className="form-control"
                        value={extraProps?.minProperties}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MIN_PROPERTIES"
                        placeHolder=">=0"
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        id="maxProperties"
                        className="form-control"
                        value={extraProps?.maxProperties}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MAX_PROPERTIES"
                        placeHolder=">=0"
                    />
                </div>
            </div>
        </div>
    );
}
