import React from 'react';
import PageHeader from 'ui/PageTitle';
import AccessForm from './AccessForm';

export const DocumentationContainer = () => {
    return (
        <div className="row">
            <PageHeader>Documentation access</PageHeader>
            <div className="col-lg-12 col-xl-6">
                <AccessForm />
            </div>
            <div className="w-100 d-lg-none"></div>
        </div>
    );
};

export default DocumentationContainer;
