export const appearance = {
    theme: 'stripe',
};

export const options = {
    appearance,
    locale: 'en',
};

export const FREE_PLAN_PRICE = 'Free';

export const cardOptions = {
    style: {
        base: {
            color: '#32325D',
            fontWeight: 400,
            fontFamily: 'Gilroy, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
                color: '#CFD7DF',
            },
        },
        invalid: {
            color: '#dc3545',
        },
    },
    hidePostalCode: true,
};
