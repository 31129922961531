import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from 'utils/auth';
import WelcomeLayout from 'layouts/welcome/WelcomeLayout';

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn() ? (
                    <Redirect to={{ pathname: '/projects' }} />
                ) : (
                    <WelcomeLayout>
                        <Component {...props} />
                    </WelcomeLayout>
                )
            }
        />
    );
};

export default PublicRoute;
