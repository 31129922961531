import React from 'react';
import PageHeader from 'ui/PageTitle';
import { Helmet } from 'react-helmet';
import UserPasswordForm from './UserPasswordForm';
import UserEmailForm from './UserEmailForm';

const SecurityPage = () => {
    return (
        <div className="container-fluid p-5">
            <Helmet>
                <title>
                    Security - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>

            <div className="row">
                <div className="col">
                    <PageHeader>Security</PageHeader>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <ul
                        className="nav security-nav mb-5"
                        id="securityViewTab"
                        role="tablist"
                    >
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link active nav-tab-link"
                                id="user-password-tab"
                                data-bs-toggle="tab"
                                href="#home"
                                role="tab"
                                aria-controls="home"
                                aria-selected="true"
                            >
                                Change password
                            </a>
                        </li>
                        {/*
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link nav-tab-link"
                                    id="user-email-tab"
                                    data-bs-toggle="tab"
                                    href="#profile"
                                    role="tab"
                                    aria-controls="profile"
                                    aria-selected="false"
                                >
                                    Change email
                                </a>
                            </li>
                            */}
                    </ul>

                    <div className="tab-content" id="membersTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="user-password-tab"
                        >
                            <UserPasswordForm />
                        </div>
                        <div
                            className="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="user-email-tab"
                        >
                            <UserEmailForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecurityPage;
