import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import WelcomeFooter from './WelcomeFooter';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from 'ui/GlobalLoader';
import { appSelectors } from 'store/slices/appSlice.js';

const WelcomeLayout = ({ children }) => {
    const showGlobalLoader = useSelector(appSelectors.getShowGlobalLoader);
    return (
        <>
            {children}
            <WelcomeFooter />

            <GlobalLoader isLoading={showGlobalLoader} />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                icon={false}
            />
        </>
    );
};

export default WelcomeLayout;
