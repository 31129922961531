import React, { Fragment, useState } from 'react';
import { MoreHorizontal } from 'react-feather';
import { formatDate } from 'utils/helpers';
import Modal from 'ui/Modal';
import { ProjectAvatar } from 'components/Avatar';
import CustomSelect from 'ui/CustomSelect';
import LocalLoader from 'ui/LocalLoader';
import { Input } from 'ui/Inputs';
import RequiredMark from 'ui/RequiredMark';

const ApiTable = ({
    items,
    project,
    projects,
    myAccount,
    isProjectsFetching,
    errors,
    onTrash,
    onRestore,
    onDelete,
    onCopy,
    onMoveApi,
    resetErrors,
}) => {
    const [data, setData] = useState({ project_uid_transfer_to: '', name: '' });
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalShown, setModalShown] = useState({
        isTrashModalShown: false,
        isDeleteModalShown: false,
        isCopyModalShown: false,
        isMoveModalShown: false,
    });

    const isTrashModalShown = (flag, item) => {
        setModalShown({ ...modalShown, isTrashModalShown: flag });
        setSelectedItem(item);
    };

    const isDeleteModalShown = (flag, item) => {
        setModalShown({ ...modalShown, isDeleteModalShown: flag });
        setSelectedItem(item);
    };

    const isCopyModalShown = (flag, item) => {
        setData({
            ...data,
            name: item?.name || '',
            project_uid_transfer_to: '',
        });
        setModalShown({ ...modalShown, isCopyModalShown: flag });
        setSelectedItem(item);

        resetErrors();
    };

    const isMoveModalShown = (flag, item) => {
        setData({ ...data, project_uid_transfer_to: '' });
        setModalShown({ ...modalShown, isMoveModalShown: flag });
        setSelectedItem(item);

        resetErrors();
    };

    const onTrashApi = () => {
        onTrash(selectedItem);
        setModalShown({ ...modalShown, isTrashModalShown: false });
    };

    const onDeleteApi = () => {
        onDelete(selectedItem);
        setModalShown({ ...modalShown, isDeleteModalShown: false });
    };

    const onCopyApi = () => {
        const newData = {
            name: data.name,
            project_uid_copy_to: data.project_uid_transfer_to,
        };
        onCopy(selectedItem, newData, isCopyModalShown);
    };

    const onMoveToApi = () => {
        onMoveApi(selectedItem, { ...data });
    };

    const handleChangeSelect = (option) => {
        setData({ ...data, project_uid_transfer_to: option.value });
    };

    const handleChange = (e) => {
        setData({ ...data, name: e.target.value });
    };

    const selectProjectsOptions = projects
        ?.filter(
            (item) =>
                item.owner.id === myAccount.id && item.uid !== project.uid,
        )
        .reduce((acc, item) => {
            acc.push({ value: item.uid, label: item.name });
            return acc;
        }, []);

    const selectProjectsOptionsToCopyApi = projects
        ?.filter(
            (item) =>
                item.owner.id === myAccount.id ||
                item.user_role === 'MAINTAINER',
        )
        .reduce((acc, item) => {
            acc.push({ value: item.uid, label: item.name });
            return acc;
        }, []);

    return (
        <div className="project-list mx-5">
            <table className="table table-hover table-main projects-table">
                <thead className="table-main-head">
                    <tr className="projects-table-row">
                        <th className="projects-table-title">Name</th>
                        <th className="projects-table-title">Created</th>
                        <th className="projects-table-title"></th>
                    </tr>
                </thead>
                <tbody className="table-main-body">
                    {items.length > 0 &&
                        items.map((api) => (
                            <ApiTableItem
                                key={api.id}
                                project={project}
                                item={api}
                                isTrashModalShown={isTrashModalShown}
                                isDeleteModalShown={isDeleteModalShown}
                                isCopyModalShown={isCopyModalShown}
                                isMoveModalShown={isMoveModalShown}
                                onRestore={onRestore}
                                myAccount={myAccount}
                                projectsOptions={selectProjectsOptions}
                            />
                        ))}
                    {items.length === 0 && (
                        <tr>
                            <td colSpan="3" className="text-center">
                                No API found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Modal
                show={modalShown.isTrashModalShown}
                title="Move to trash"
                body="This API will be moved to trash, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onTrashApi}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() => isTrashModalShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />

            <Modal
                show={modalShown.isDeleteModalShown}
                title="Delete permanently?"
                body="This API will be deleted permanently and you will not be able to restore it."
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDeleteApi}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() => isDeleteModalShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={modalShown.isCopyModalShown}
                title="Copy API"
                body={
                    <div>
                        <div className="form-group mb-4">
                            <Input
                                type="text"
                                name="name"
                                className="form-control"
                                value={data.name}
                                onChange={handleChange}
                                labelText="Api name"
                                labelClassName="form-label"
                                placeHolder="Api name"
                                errorText={errors?.name}
                                isRequired={true}
                            />
                        </div>
                        <LocalLoader loading={isProjectsFetching}>
                            <label className="mb-2">
                                Select project
                                <RequiredMark />
                            </label>
                            <CustomSelect
                                name="apiCopy"
                                options={selectProjectsOptionsToCopyApi}
                                onChange={handleChangeSelect}
                                value={data.project_uid_transfer_to}
                                fieldError={errors.project_uid_copy_to}
                            />
                        </LocalLoader>
                    </div>
                }
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onCopyApi}
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            onClick={() => isCopyModalShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={modalShown.isMoveModalShown}
                title="Move API to another project"
                body={
                    <div>
                        <p className="fw-bold text-black-50 mb-2">
                            Select project
                        </p>
                        <LocalLoader loading={isProjectsFetching}>
                            <CustomSelect
                                name="api"
                                options={selectProjectsOptions}
                                onChange={handleChangeSelect}
                                value={data.project_uid_transfer_to}
                                fieldError={errors.project_uid_transfer_to}
                            />
                        </LocalLoader>
                    </div>
                }
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onMoveToApi}
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            onClick={() => isMoveModalShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </div>
    );
};

const ApiTableItem = ({
    item,
    project,
    myAccount,
    projectsOptions,
    isMoveModalShown,
    isTrashModalShown,
    isCopyModalShown,
    onRestore,
    isDeleteModalShown,
}) => {
    const hasRights =
        project.user_role === 'MAINTAINER' || project.owner.id === myAccount.id;
    const isOwner = project.owner.id === myAccount.id;

    return (
        <tr className="table-main-row projects-table-item">
            <td>
                <ProjectAvatar project={item} />
                {item.deleted_at ? (
                    <>
                        {item.name}{' '}
                        <span className="badge bg-danger mx-2">deleted</span>
                    </>
                ) : (
                    <a
                        href={`/project/api/routes?project=${project.uid}&api=${item.uid}`}
                    >
                        {item.name}
                    </a>
                )}
            </td>
            <td>{formatDate(item.created_at)}</td>
            <td className="text-end">
                {hasRights && (
                    <div className="btn-group dropstart">
                        <button
                            type="button"
                            className="projects-table-dots"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <MoreHorizontal className="project-more-img" />
                        </button>
                        <ul className="dropdown-menu header-dropdown-list">
                            {!item.deleted_at && (
                                <li>
                                    <a
                                        href={`/project/api/settings/summary?project=${project.uid}&api=${item.uid}`}
                                        className="dropdown-item link-dropdown project-dropdown-link"
                                    >
                                        API settings
                                    </a>
                                </li>
                            )}
                            {item.deleted_at ? (
                                <Fragment>
                                    <li>
                                        <button
                                            className="dropdown-item btn-dropdown project-dropdown-link"
                                            onClick={() => onRestore(item)}
                                        >
                                            Restore
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="dropdown-item btn-dropdown project-dropdown-link"
                                            onClick={() =>
                                                isDeleteModalShown(true, item)
                                            }
                                        >
                                            Delete
                                        </button>
                                    </li>
                                </Fragment>
                            ) : (
                                <li>
                                    <button
                                        className="dropdown-item btn-dropdown project-dropdown-link"
                                        name={
                                            item.deleted_at ? 'delete' : 'trash'
                                        }
                                        onClick={() =>
                                            isTrashModalShown(true, item)
                                        }
                                    >
                                        Move to trash
                                    </button>
                                </li>
                            )}
                            {hasRights &&
                                !item.deleted_at &&
                                !project.is_transfer_pending && (
                                    <li>
                                        <button
                                            className="dropdown-item btn-dropdown project-dropdown-link"
                                            name="copy"
                                            onClick={() =>
                                                isCopyModalShown(true, item)
                                            }
                                        >
                                            Copy API
                                        </button>
                                    </li>
                                )}
                            {isOwner &&
                                !item.deleted_at &&
                                !project.is_transfer_pending &&
                                projectsOptions.length > 0 && (
                                    <li>
                                        <button
                                            className="dropdown-item btn-dropdown project-dropdown-link text-wrap"
                                            name="copy"
                                            onClick={() =>
                                                isMoveModalShown(true, item)
                                            }
                                        >
                                            Move API to another project
                                        </button>
                                    </li>
                                )}
                        </ul>
                    </div>
                )}
            </td>
        </tr>
    );
};

export default ApiTable;
