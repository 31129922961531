import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LocalLoader from 'ui/LocalLoader';
import useOutsideClick from 'hooks/useOutsideClick';
import { apiSelectors, getApiList } from 'store/slices/apiSlice';
import ApisList from './ApisList';

const ApisSelector = ({ project, api }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isApisFetching = useSelector(apiSelectors.getIsApisFetching);
    const apis = useSelector(apiSelectors.getApis);

    const [showList, setShowList] = useState(false);
    const selectApiDropdown = useRef();

    useEffect(() => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        dispatch(getApiList({ projectUid: project.uid, filters: p }));
    }, [dispatch, location.search, project.uid]);

    const handleOutsideClick = () => {
        setShowList(false);
    };

    useOutsideClick(selectApiDropdown, handleOutsideClick);

    const toggleList = (e) => {
        setShowList(!showList);
    };

    return (
        <div className="btn-group" ref={selectApiDropdown}>
            <button
                type="button"
                className="custom-dropdown-btn link-dropdown-btn"
                onClick={toggleList}
            >
                {api.name}
                <ChevronDown
                    className="custom-dropdown-btn-arrow"
                    size={12}
                    strokeWidth={2}
                    aria-expanded={showList ? 'true' : 'false'}
                />
            </button>
            {showList && (
                <div
                    className={`dropdown-menu ${showList ? 'show' : ''}`}
                    style={{ minWidth: '16.5rem' }}
                >
                    <div>
                        <LocalLoader loading={isApisFetching}>
                            <ApisList apis={apis} project={project} />
                        </LocalLoader>
                        <div>
                            <hr className="dropdown-divider header-divider" />
                        </div>
                        <div className="text-center">
                            <a
                                className="dropdown-item link-dropdown"
                                href={`/project?project=${project?.uid}`}
                            >
                                View all API's
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApisSelector;
