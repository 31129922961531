import useCheckoutForm from '../hooks/useCheckoutForm';
import { AddPaymentCard } from 'pages/Billing/ManageBilling/AddPaymentCard';
import { PaymentFooter } from './PaymentFooter';
import { CardInfo } from './CardInfo';

const CheckoutForm = ({ setResultPayment, setIsShowModal }) => {
    const {
        cardName,
        handleCancelSubscription,
        handleCardElementChange,
        handleChangeCardName,
        handleSubmit,
        error,
        isDisabledButton,
        isLoading,
        isCancelSubscribe,
        totalPrice,
        currentPaymentCard,
    } = useCheckoutForm({ setResultPayment, setIsShowModal });

    return (
        <form>
            <h6 className="title-h5 pt-4">Payment method</h6>
            {currentPaymentCard ? (
                <CardInfo card={currentPaymentCard} />
            ) : (
                <AddPaymentCard
                    cardName={cardName}
                    handleCardElementChange={handleCardElementChange}
                    handleChangeCardName={handleChangeCardName}
                    error={error}
                />
            )}

            <div className="billing-divider" />

            <PaymentFooter
                handleCancelSubscription={handleCancelSubscription}
                handleSubmit={handleSubmit}
                isDisabledButton={isDisabledButton}
                totalPrice={totalPrice}
                isLoading={isLoading}
                isCancelSubscribe={isCancelSubscribe}
            />
        </form>
    );
};

export default CheckoutForm;
