import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const login = createAsyncThunk(
    'auth/login',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/login', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const socialLogin = createAsyncThunk(
    'auth/socialLogin',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/social-login', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getProviderUrl = createAsyncThunk(
    'auth/getProviderUrl',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/provider/redirect', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const signUp = createAsyncThunk(
    'auth/signUp',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/signup', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const sendResetPasswordEmail = createAsyncThunk(
    'auth/sendResetPasswordEmail',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/forgot-password', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/reset-password', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const fetchMe = createAsyncThunk('auth/fetchMe', async () => {
    try {
        const response = await http.get('/api/v1/user/me');

        return response.data.data;
    } catch (error) {
        console.log(error);
    }
});

export const fetchCountries = createAsyncThunk(
    'auth/fetchCountries',
    async ({ onSuccess, onError }) => {
        try {
            const response = await http.get('/api/v1/countries');
            onSuccess(response.data.data);
        } catch (error) {
            onError(error);
        }
    },
);

export const updateMyAccount = createAsyncThunk(
    'auth/updateMyAccount',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put('/api/v1/user/profile', data);
            dispatch(fetchMe());
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const changeMyPassword = createAsyncThunk(
    'auth/changedMyPassword',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                '/api/v1/user/change-password',
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const checkCode = createAsyncThunk(
    'auth/checkCode',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/check-code', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const acceptInvitation = createAsyncThunk(
    'auth/acceptInvitation',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/accept-invite', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const verifyEmail = createAsyncThunk(
    'auth/verifyEmail',
    async ({ data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/email-verify', data);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const confirmEmail = createAsyncThunk(
    'auth/confirmEmail',
    async ({ data, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.post('/api/v1/email-resend', data);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const sendMessageWithContactForm = createAsyncThunk(
    'auth/sendMessageWithContactForm',
    async ({ data, onSuccess, onError }) => {
        try {
            const response = await http.post('/api/v1/contact/request', data);
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

const initialState = {
    myAccount: {},
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchMe.fulfilled]: (state, action) => {
            state.myAccount = action.payload;
        },
    },
});

// Selectors

export const authSelectors = {
    getMyAccount: (state) => state.auth.myAccount,
};

export default authSlice.reducer;
