import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown } from 'react-feather';

import { StarredProjects } from './StarredProjects';
import RecentProjects from './RecentProjects';
import LocalLoader from 'ui/LocalLoader';
import useOutsideClick from 'hooks/useOutsideClick';
import {
    fetchRecentProjects,
    getFavorites,
    projectSelectors,
} from 'store/slices/projectsSlice';

const ProjectsSelector = () => {
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const [showList, setShowList] = useState(false);
    const selectProjectDropdown = useRef();

    const project = useSelector(projectSelectors.getCurrentProject);
    const isProjectFetching = useSelector(
        projectSelectors.getIsProjectFetching,
    );
    const isStarredProjectsFetching = useSelector(
        projectSelectors.getIsStarredProjectsFetching,
    );
    const isRecentProjectsFetching = useSelector(
        projectSelectors.getIsRecentProjectsFetching,
    );
    const recentProjects = useSelector(projectSelectors.getRecentProjects);
    const starredProjects = useSelector(projectSelectors.getStarredProjects);

    const selectedProjectName = project?.name ? project.name : null;

    useEffect(() => {
        dispatch(getFavorites());
        dispatch(fetchRecentProjects());
    }, [dispatch]);

    const handleClickOutside = (e) => {
        setShowList(false);
    };
    useOutsideClick(selectProjectDropdown, handleClickOutside);

    const toggleList = () => {
        setShowList(!showList);
    };

    return (
        <>
            <div className="dropdown btn-group" ref={selectProjectDropdown}>
                <button
                    className={`custom-dropdown-btn link-dropdown-btn 
                            ${
                                (selectedProjectName ||
                                    pathname === '/projects' ||
                                    search.includes('project')) &&
                                !search.includes('api')
                                    ? 'custom-dropdown-btn-active'
                                    : null
                            }`}
                    type="button"
                    id="dropdownMenuButton1"
                    aria-expanded="false"
                    onClick={toggleList}
                >
                    {isProjectFetching ? (
                        <>
                            <span className="spinner-border spinner-border-sm mx-2" />
                            Loading...
                        </>
                    ) : (
                        selectedProjectName ?? 'Select Project'
                    )}
                    <ChevronDown
                        className="custom-dropdown-btn-arrow"
                        size={12}
                        strokeWidth={2}
                    />
                </button>
                {showList && (
                    <div
                        className={`dropdown-menu ${showList ? 'show' : ''}`}
                        style={{ minWidth: '16.5rem' }}
                        aria-labelledby="dropdownMenuButton1"
                    >
                        <LocalLoader loading={isStarredProjectsFetching}>
                            {!isStarredProjectsFetching && (
                                <StarredProjects projects={starredProjects} />
                            )}
                        </LocalLoader>

                        <div>
                            <hr className="dropdown-divider header-divider" />
                        </div>

                        <LocalLoader loading={isRecentProjectsFetching}>
                            {!isRecentProjectsFetching && (
                                <RecentProjects projects={recentProjects} />
                            )}
                        </LocalLoader>

                        <div>
                            <hr className="dropdown-divider header-divider" />
                        </div>
                        <div className="text-center">
                            <a
                                className="dropdown-item link-dropdown"
                                href="/projects"
                            >
                                View all Projects
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProjectsSelector;
