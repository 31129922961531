import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { Trash2, ChevronLeft } from 'react-feather';
import { toast } from 'react-toastify';

import ResourcesList from './ResourcesList';
import ApiContent from 'components/ApiContent/ApiContent';
import MainContent from 'ui/MainContent';
import { LeftSideBar, LeftSideBarHeader } from 'components/LeftSideBar';
import { InputSearch } from 'ui/Inputs';
import PageHeader from 'ui/PageTitle';
import Modal from 'ui/Modal';
import {
    emptyResourcesTrash,
    getTrashedModels,
    getTrashedResponses,
    resourceSelectors,
} from 'store/slices/resourcesSlice';
import ModelsRoutes from './Models/ModelsRoutes';
import ResponsesRoutes from './Responses/ResponsesRoutes';
import ResourcesTrashList from './ResourcesTrashList';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import resourceIcon from 'assets/img/resource.svg';
import responsesIcon from 'assets/img/share.svg';
import RecentComponents from './RecentComponents';

const ResourcesContainer = () => {
    const dispatch = useDispatch();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const resourcesTrash = useSelector(resourceSelectors.getResourcesTrash);
    const responsesTrash = useSelector(resourceSelectors.getResponsesTrash);
    const resources = useSelector(resourceSelectors.getResources);
    const responses = useSelector(resourceSelectors.getResponses);

    const [inputSearch, setInputSearch] = useState('');

    const [sidebarView, setSidebarView] = useState('all');
    const [isEmptyTrashModalShown, setIsEmptyTrashModalShown] = useState(false);

    const hasRights =
        project.owner.id === myAccount.id ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';

    useEffect(() => {
        dispatch(getTrashedModels({ apiId: api.id }));
        dispatch(getTrashedResponses({ apiId: api.id }));
    }, [dispatch, project.uid, api.id]);

    const handleChange = (e) => {
        setInputSearch(e.target.value);
    };

    const toggleEmptyTrashModal = () => {
        setIsEmptyTrashModalShown(!isEmptyTrashModalShown);
    };

    const emptyTrash = () => {
        dispatch(
            emptyResourcesTrash({
                apiId: api.id,
                onSuccess: onSuccessEmptyTrash,
            }),
        );
    };

    const onSuccessEmptyTrash = () => {
        dispatch(getTrashedModels({ apiId: api.id }));
        dispatch(getTrashedResponses({ apiId: api.id }));
        toast.success('This trash has been emptied');

        toggleEmptyTrashModal();
    };

    const createDataList = (data, searchData) => {
        return data.filter((el) => {
            const name = el.name || '';

            return name.toLowerCase().includes(searchData.toLowerCase());
        });
    };

    const resourcesList = !!resources && createDataList(resources, inputSearch);
    const responsesList = !!responses && createDataList(responses, inputSearch);
    const resourcesTrashList =
        !!resourcesTrash && createDataList(resourcesTrash, inputSearch);
    const responsesTrashList =
        !!responsesTrash && createDataList(responsesTrash, inputSearch);
    const trashedResourcesAmount =
        resourcesTrash?.length + responsesTrash?.length;

    return (
        <ApiContent>
            <LeftSideBar initialWidth={380} minWidth={240}>
                <nav className="h-100 d-flex flex-column">
                    <LeftSideBarHeader className="flex-column">
                        <>
                            {hasRights && (
                                <>
                                    {sidebarView === 'trash' && (
                                        <button
                                            style={{ marginLeft: 'auto' }}
                                            className="btn-action hover-default mb-3"
                                            onClick={toggleEmptyTrashModal}
                                        >
                                            <Trash2 size={13} color="#007BFF" />
                                        </button>
                                    )}
                                </>
                            )}
                            <InputSearch
                                id="input-search"
                                type="text"
                                placeHolder="Search components..."
                                inputStyle={{
                                    width: '100%',
                                    padding: '0.5rem 0.8rem 0.5rem 2rem',
                                    borderRadius: '4px',
                                    maxHeight: '40px',
                                }}
                                labelStyle={{ width: '100%' }}
                                className="form-control"
                                value={inputSearch}
                                onChange={handleChange}
                            />
                        </>
                    </LeftSideBarHeader>
                    <div className="routes-nav-panel">
                        {sidebarView === 'all' ? (
                            <>
                                <span className="routes-nav-title">
                                    all components
                                </span>
                                <span
                                    className="routes-nav-trash"
                                    onClick={() => setSidebarView('trash')}
                                >
                                    <Trash2 size={12} color="#001A37" />
                                    Trash ({trashedResourcesAmount})
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="routes-nav-title">trash</span>
                                <span
                                    className="routes-nav-trash"
                                    onClick={() => setSidebarView('all')}
                                >
                                    <ChevronLeft size={12} color="#001A37" />
                                    Back to all components
                                </span>
                            </>
                        )}
                    </div>
                    {sidebarView === 'all' ? (
                        <ResourcesList
                            resources={resourcesList}
                            responses={responsesList}
                            inputSearch={inputSearch}
                        />
                    ) : (
                        <ResourcesTrashList
                            inputSearch={inputSearch}
                            resourcesTrashList={resourcesTrashList}
                            responsesTrashList={responsesTrashList}
                        />
                    )}
                </nav>
            </LeftSideBar>
            <MainContent>
                <div className="main-container m-5">
                    <Route exact path="/project/api/components">
                        <PageHeader>Components</PageHeader>
                        <div className="mt-4">
                            <h6>Create</h6>
                            <div className="d-flex mt-3">
                                <Link
                                    to={`/project/api/components/models/create?project=${project.uid}&api=${api.uid}`}
                                    className="d-flex align-items-center btn btn-light"
                                >
                                    <img
                                        width={18}
                                        height={18}
                                        src={resourceIcon}
                                        alt="resources icon"
                                    />
                                    <span className="d-block ms-2">Model</span>
                                </Link>
                                <Link
                                    to={`/project/api/components/responses/create?project=${project.uid}&api=${api.uid}`}
                                    className="d-flex align-items-center btn btn-light ms-3"
                                >
                                    <img
                                        width={18}
                                        height={18}
                                        src={responsesIcon}
                                        alt="responses icon"
                                    />
                                    <span className="d-block ms-2">
                                        Response
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <RecentComponents
                            resources={resources}
                            responses={responses}
                            resourcesTrash={resourcesTrash}
                            responsesTrash={responsesTrash}
                            project={project}
                            api={api}
                        />
                    </Route>
                    <ModelsRoutes />
                    <ResponsesRoutes />
                </div>
            </MainContent>
            <Modal
                show={isEmptyTrashModalShown}
                title="Delete"
                body="This trash will be emptied, are you sure?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={emptyTrash}
                        >
                            Empty trash
                        </button>
                        <button
                            type="button"
                            onClick={toggleEmptyTrashModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </ApiContent>
    );
};

export default ResourcesContainer;
