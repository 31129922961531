import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { deleteTokens } from 'utils/auth';
import { loaded } from 'store/slices/appSlice';

const Logout = () => {
    const dispatch = useDispatch();
    dispatch(loaded(false));
    window.localStorage.setItem('remember_me', false);
    deleteTokens();

    return <Redirect to="/" />;
};

export default Logout;
