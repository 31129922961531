import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import PageHeader from 'ui/PageTitle';
import { Upload, Trash2, Edit3 } from 'react-feather';
import CustomSelect from 'ui/CustomSelect';
import jsonLogo from 'assets/img/json.png';
import yamlLogo from 'assets/img/yaml.png';
import { projectSelectors } from 'store/slices/projectsSlice';
import { authSelectors } from 'store/slices/authSlice';
import { apiSelectors, importCollection } from 'store/slices/apiSlice';
import { useHistory } from 'react-router-dom';
import Modal from 'ui/Modal';

const REGEXP_ACCEPT_POSTMAN_FILE = /(application\/json)/i;
const REGEXP_ACCEPT_SWAGGER_FILE = /(json|yaml)/i;

// const versions = [
//     { value: '2.1', label: '2.1' },
//     { value: '3.0.0', label: '3.0.0' },
// ];

const collectionTypes = [
    { value: 'postman', label: 'Postman 2.1' },
    { value: 'swagger', label: 'OpenAPI 3.0+' },
];

const importStrategy = [
    { value: 'clean', label: 'Clean all existing routes and create new ones' },
    {
        value: 'update',
        label: 'Update all existing routes and create new ones',
    },
    { value: 'skip', label: 'Skip existing routes and just create new ones' },
];

const ImportPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const project = useSelector(projectSelectors.getCurrentProject);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const api = useSelector(apiSelectors.getCurrentApi);

    const [data, setData] = useState({
        type: 'postman',
        strategy: 'clean',
    });
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileFormat, setFileFormat] = useState('');
    const [isWrongFileType, setIsWrongFileType] = useState(false);
    const [isFileOversized, setIsFileOversized] = useState(false);
    const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
        useState(false);
    const [errors, setErrors] = useState({});

    const hasRights =
        project.user_role === 'MAINTAINER' ||
        myAccount.id === project.owner?.id;

    const toggleConfirmationModal = () => {
        setIsConfirmationModalOpened((prev) => !prev);
    };

    const handleChangeSelect = (option, metaInfo) => {
        setData({ ...data, [metaInfo.name]: option.value });
        setIsWrongFileType(false);
    };

    const handleSubmit = () => {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const newData = formData();
        setIsConfirmationModalOpened(false);

        dispatch(
            importCollection({
                projectUid: project.uid,
                apiUid: api.uid,
                data: newData,
                onSuccess,
                onError,
                headers,
            }),
        );
    };

    const onSuccess = () => {
        toast.success('Your file has been uploaded.');

        deleteFile();

        setTimeout(() => {
            history.push(
                `/project/api/routes?project=${project.uid}&api=${api.uid}`,
            );
        }, 500);
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);

        setIsConfirmationModalOpened(false);
    };

    const onFileSelect = (e) => {
        if (e.target.files && e.target.files[0]) {
            if (
                data.type === 'postman' &&
                !REGEXP_ACCEPT_POSTMAN_FILE.test(e.target.files[0].type)
            ) {
                setIsWrongFileType(true);
                setErrors({});

                return;
            }

            if (
                data.type === 'swagger' &&
                !REGEXP_ACCEPT_SWAGGER_FILE.test(
                    e.target.files[0].name.slice(-4),
                )
            ) {
                setIsWrongFileType(true);
                setErrors({});

                return;
            }

            if (e.target.files[0].size > 2097152) {
                setIsFileOversized(true);
                setErrors({});

                return;
            }
            setFileName(e.target.files[0].name);
            setFile(e.target.files[0]);
            setFileFormat(e.target.files[0].name.slice(-4));
        }
        setErrors({});
        setIsWrongFileType(false);
        setIsFileOversized(false);
    };

    const formData = () => {
        const fd = new FormData();

        if (data.type === 'postman') {
            fd.append('import_strategy', data.strategy);
        }

        fd.append('type', data.type);
        fd.append('file', file);

        return fd;
    };

    const deleteFile = () => {
        setFile('');
        setFileName('');
        setErrors({});
        setIsWrongFileType(false);
        setIsFileOversized(false);
    };

    const uploadCollection = () => {
        if (data.type === 'swagger' || data.strategy === 'clean') {
            toggleConfirmationModal();

            return;
        }

        handleSubmit();
    };

    return (
        <div className="container-fluid p-5">
            <Helmet>
                <title>
                    Import - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between mb-4">
                        <PageHeader margin="mb-0">Import</PageHeader>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <p className="mb-4">
                                Here you can upload your routes collection.
                                Postman and swagger collections are supported
                            </p>
                            <div
                                className="form-group mb-4"
                                style={{ maxWidth: '500px' }}
                            >
                                <label
                                    htmlFor="version"
                                    className="invite-label mb-2"
                                >
                                    Collection type
                                </label>
                                <CustomSelect
                                    name="type"
                                    options={collectionTypes}
                                    onChange={handleChangeSelect}
                                    defaultOption={data.type}
                                    value={data.type}
                                />
                            </div>
                            {data.type === 'postman' && (
                                <div
                                    className="form-group mb-4"
                                    style={{ maxWidth: '500px' }}
                                >
                                    <label
                                        htmlFor="version"
                                        className="invite-label mb-2"
                                    >
                                        Import Strategy
                                    </label>
                                    <CustomSelect
                                        name="strategy"
                                        options={importStrategy}
                                        onChange={handleChangeSelect}
                                        defaultOption={data.strategy}
                                        value={data.strategy}
                                    />
                                </div>
                            )}
                            <form style={{ maxWidth: '500px' }}>
                                {hasRights && (
                                    <>
                                        {/* <div className="form-group mb-4">
                                                <label
                                                    htmlFor="version"
                                                    className="invite-label mb-2"
                                                >
                                                    Version
                                                </label>
                                                <CustomSelect
                                                    name="version"
                                                    options={currentVersion}
                                                    onChange={handleChangeSelect}
                                                    defaultOption={data.version}
                                                    value={data.version}
                                                />
                                            </div>      */}
                                        <div className="form-group mb-4">
                                            <input
                                                type="file"
                                                name="file"
                                                id="file"
                                                className="input-file"
                                                accept="aplication/json"
                                                onChange={onFileSelect}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                            <div className="d-flex">
                                                {fileName && (
                                                    <div>
                                                        <img
                                                            className="mb-2"
                                                            src={
                                                                fileFormat ===
                                                                'json'
                                                                    ? jsonLogo
                                                                    : yamlLogo
                                                            }
                                                            alt="logo"
                                                            width="48"
                                                        />
                                                        <span>{`${fileName.slice(
                                                            0,
                                                            11,
                                                        )}...`}</span>
                                                    </div>
                                                )}
                                                {!file ? (
                                                    <label
                                                        className="d-flex align-items-center cursor-pointer"
                                                        htmlFor="file"
                                                    >
                                                        <Upload
                                                            color="#0d6efd"
                                                            size={18}
                                                        />
                                                        <span className="fw-bold ms-2 text-primary">
                                                            Upload file
                                                        </span>
                                                    </label>
                                                ) : (
                                                    <div className="d-flex flex-column justify-content-around ms-3">
                                                        <label
                                                            className="d-flex align-items-center cursor-pointer"
                                                            htmlFor="file"
                                                        >
                                                            <Edit3
                                                                color="#0d6efd"
                                                                size={18}
                                                            />
                                                            <span className="fw-bold ms-2 text-primary">
                                                                Change file
                                                            </span>
                                                        </label>
                                                        <button
                                                            type="button"
                                                            className="text-danger fw-bold d-flex align-items-center bg-white"
                                                            onClick={deleteFile}
                                                        >
                                                            <Trash2
                                                                color="#dc3545"
                                                                size={18}
                                                            />
                                                            <span className="d-inline-block ms-2">
                                                                Delete
                                                            </span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                {isWrongFileType &&
                                                    data.type === 'postman' && (
                                                        <span className="text-danger d-block mt-3">
                                                            Wrong file format.
                                                            Supported formats
                                                            for Postman
                                                            collection: json.
                                                        </span>
                                                    )}
                                                {isWrongFileType &&
                                                    data.type === 'swagger' && (
                                                        <span className="text-danger d-block mt-3">
                                                            Wrong file format.
                                                            Supported formats
                                                            for Swagger
                                                            collection: json,
                                                            yaml.
                                                        </span>
                                                    )}
                                                {isFileOversized && (
                                                    <span className="text-danger d-block mt-3">
                                                        The file size must not
                                                        exceed 2MB.
                                                    </span>
                                                )}
                                                {errors.file && (
                                                    <span className="text-danger d-block mt-3">
                                                        {errors.file}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                                <button
                                    type="button"
                                    onClick={uploadCollection}
                                    className="btn btn-primary"
                                    disabled={!file}
                                >
                                    Upload
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isConfirmationModalOpened}
                title="Import collection"
                body="The import will clean all existing routes and create new ones."
                footer={
                    <>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={toggleConfirmationModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </>
                }
            />
        </div>
    );
};

export default ImportPage;
