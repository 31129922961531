import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    billingSelectors,
    cancelSubscription,
    createSubscription,
    getCards,
} from 'store/slices/billingSlice';
import { authSelectors, fetchMe } from 'store/slices/authSlice';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const useCheckoutForm = ({ setResultPayment, setIsShowModal }) => {
    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();
    const myAccount = useSelector(authSelectors.getMyAccount);
    const selectedPlan = useSelector(billingSelectors.getSelectedPlan);
    const cards = useSelector(billingSelectors.getAllCards);

    const fullName = myAccount.first_name + ' ' + myAccount.last_name;

    const [isLoading, setIsLoading] = useState(false);
    const [cardName, setCardName] = useState(fullName || null);
    const [error, setError] = useState(null);

    const currentPlan = myAccount.plan;
    const isCancelSubscribe = currentPlan?.price_id && !selectedPlan?.price_id;
    const isDisabledButton =
        isLoading ||
        !stripe ||
        !elements ||
        error ||
        currentPlan.id === selectedPlan.id;
    const totalPrice = selectedPlan?.cost || 0;

    const currentPaymentCard = cards && cards[0];
    const currentPaymentCardId = currentPaymentCard?.id;
    const email = myAccount?.email;
    const planId = selectedPlan?.id;

    const setUpPaymentMethod = async () => {
        if (!currentPlan.price_id) {
            const paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: cardName,
                    email,
                },
            });

            if (paymentMethod.error) {
                setIsLoading(false);
                return;
            }
            return paymentMethod;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const paymentMethod = await setUpPaymentMethod();

        setIsLoading(true);
        try {
            const data = {
                paymentMethod:
                    currentPaymentCardId || paymentMethod?.paymentMethod?.id,
                name: fullName,
                email,
                planId,
            };

            dispatch(
                createSubscription({
                    data,
                    onSuccess: onSuccessCreateSubscription,
                    onError: onErrorCreateSubscription,
                }),
            );
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };

    const handleChangeCardName = (e) => {
        e.preventDefault();
        setCardName(e.target.value);
    };

    const handleCardElementChange = (event) => {
        if (!event.error) {
            setError(null);
            return;
        }

        setError(event?.error?.message);
    };

    const handleCancelSubscription = (e) => {
        e.preventDefault();
        setIsLoading(true);
        dispatch(
            cancelSubscription(
                onSuccessCancelSubscription,
                onErrorCancelSubscription,
            ),
        );
        setIsLoading(false);
    };

    const onSuccessCancelSubscription = () => {
        setIsShowModal(false);
        dispatch(fetchMe());
        elements.getElement(CardElement).clear();
        toast.success('The subscription has been cancelled successfully');
    };

    const onErrorCancelSubscription = (error) => {
        setResultPayment({
            status: 'error',
            message: error.data?.message,
        });
        toast.error(error.message);
    };

    const onSuccessCreateSubscription = () => {
        dispatch(fetchMe());
        dispatch(getCards());
        setIsLoading(false);
        setResultPayment({
            status: 'success',
            message: 'The subscription has been created successfully',
        });
        toast.success('The subscription has been created successfully');
    };

    const onErrorCreateSubscription = (error) => {
        setIsLoading(false);
        setResultPayment({
            status: 'error',
            message: error?.response?.data?.message,
        });
        toast.error(error?.response?.data?.message);
    };
    return {
        cardName,
        isLoading,
        error,
        handleChangeCardName,
        handleCardElementChange,
        handleSubmit,
        handleCancelSubscription,
        isCancelSubscribe,
        isDisabledButton,
        totalPrice,
        currentPaymentCard,
    };
};

export default useCheckoutForm;
