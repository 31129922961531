import React, { Fragment, useEffect, useState } from 'react';
import MainContent from 'ui/MainContent';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';

import ApiTable from './ApiTable';
import LocalLoader from 'ui/LocalLoader';
import Multiselect from 'ui/Multiselect';
import Modal from 'ui/Modal';
import { InputSearch } from 'ui/Inputs';
import CreateApiModal from './CreateApiModal';
import ProjectAvatar from 'components/Avatar/ProjectAvatar';
import arrowBack from 'assets/img/arrows/arrow-back.svg';
import shortLink from 'assets/img/short-link.svg';
import { authSelectors } from 'store/slices/authSlice';
import {
    apiSelectors,
    copyApi,
    createApi,
    deleteApi,
    getApiList,
    isCreateApiModalShown,
    moveApi,
    restoreApi,
    trashApi,
} from 'store/slices/apiSlice';
import {
    cancelTransferProject,
    fetchProject,
    getProjects,
    projectSelectors,
    trashProject,
} from 'store/slices/projectsSlice';

const multiselectOptions = [{ name: 'Trashed API', id: 'trashed' }];

const ProjectPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const apis = useSelector(apiSelectors.getApis);
    const isApisFetching = useSelector(apiSelectors.getIsApisFetching);
    const isCreateApiModal = useSelector(apiSelectors.getIsCreateApiModalShown);
    const project = useSelector(projectSelectors.getCurrentProject);
    const projects = useSelector(projectSelectors.getProjects);
    const isProjectsFetching = useSelector(
        projectSelectors.getIsProjectsFetching,
    );

    const [search, setSearch] = useState('');
    const [errors, setErrors] = useState({});
    const [isProjectTrashModalShown, setIsProjectTrashModalShown] =
        useState(false);
    const [
        isCancelProjectTransferModalShown,
        setIsCancelProjectTransferModalShown,
    ] = useState(false);

    let items = [];
    if (apis?.length > 0) {
        items = apis.filter((api) => {
            return api.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
    }
    const hasOwnerRights = myAccount.id === project.owner?.id;
    const isAccessToCreateApi =
        myAccount.plan.max_apis_count > myAccount.api_count ||
        myAccount.plan.max_apis_count === null;

    useEffect(() => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        dispatch(getApiList({ projectUid: project.uid, filters: p }));
        dispatch(getProjects({ filters: p }));
    }, [dispatch, location.search, project.uid]);

    const updateWithFilters = (filters) => {
        let p = [];
        filters.forEach((el) => p.push(el.id));
        dispatch(getApiList({ projectUid: project.uid, filters: p }));
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    };

    const onCreated = (response) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        dispatch(isCreateApiModalShown(false));
        toast.success('The API has been created');
        dispatch(getApiList({ projectUid: project.uid, filters: p }));
    };

    const onDelete = (item) => {
        dispatch(
            deleteApi({
                projectUid: project.uid,
                apiUid: item.uid,
                onSuccess: onSuccessDelete,
                onError,
            }),
        );
    };

    const onSuccessDelete = (response) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        toast.success('The API has been permanently deleted');
        dispatch(getApiList({ projectUid: project.uid, filters: p }));
    };

    const onTrash = (item) => {
        dispatch(
            trashApi({
                projectUid: project.uid,
                apiUid: item.uid,
                onSuccess: onSuccessTrash,
                onError,
            }),
        );
    };

    const onSuccessTrash = (response) => {
        toast.success('The API has been moved to trash');
        dispatch(getApiList({ projectUid: project.uid }));
    };

    const onRestore = (item) => {
        dispatch(
            restoreApi({
                projectUid: project.uid,
                apiUid: item.uid,
                onSuccess: onSuccessRestore,
                onError,
            }),
        );
    };

    const onSuccessRestore = (response) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        toast.success('The API has been restored');
        dispatch(getApiList({ projectUid: project.uid, filters: p }));
    };

    const onError = (error) => {
        toast.error('Something went wrong, please try again later.');
    };

    const onCopyApi = (item, data, isCopyModalShown) => {
        dispatch(
            copyApi({
                projectUid: project.uid,
                apiUid: item.uid,
                data,
                onSuccess: onSuccessCopyApi,
                onError: onSetError,
                isCopyModalShown,
            }),
        );
    };

    const onSuccessCopyApi = (data) => {
        toast.success('The API has been copied');
        setTimeout(
            () =>
                window.location.replace(
                    `/project?project=${data.project_uid_copy_to}`,
                ),
            700,
        );
    };

    const onSetError = (error) => {
        setErrors(error.response.data.errors);
    };

    const onMoveApi = (item, data) => {
        dispatch(
            moveApi({
                projectUid: project.uid,
                api: item,
                data: data,
                onSuccess: onSuccessMoveApi,
                onError: onSetError,
            }),
        );
    };

    const onSuccessMoveApi = (response, apiUid, data) => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        toast.success(
            `The API ${apiUid} has been moved to the ${data.project_uid_transfer_to} successfully`,
        );
        dispatch(getApiList({ projectUid: project.uid, filters: p }));
    };

    const resetErrors = () => {
        setErrors({});
    };

    const toggleTrashProjectModal = () => {
        setIsProjectTrashModalShown(!isProjectTrashModalShown);
    };

    const onTrashProject = () => {
        dispatch(
            trashProject({
                uid: project.uid,
                onSuccess: onSuccessTrashProject,
                onError,
            }),
        );
        toggleTrashProjectModal();
    };

    const onSuccessTrashProject = () => {
        toast.success('The project has been moved to trash');
        setTimeout(() => window.location.replace('/projects'), 700);
    };

    const toggleCancelProjectTransferModal = () => {
        setIsCancelProjectTransferModalShown(
            !isCancelProjectTransferModalShown,
        );
    };

    const onCancelProjectTransfer = () => {
        dispatch(
            cancelTransferProject({
                uid: project.uid,
                onSuccess: onSuccessCancelProjectTransfer,
                onError,
            }),
        );
        toggleCancelProjectTransferModal();
    };

    const onSuccessCancelProjectTransfer = () => {
        toast.success('The project’s transfer has been canceled');
        dispatch(fetchProject({ pid: project.uid }));
    };

    const onSuccessCopyToClipboard = () => {
        toast.success('The short link of your project has been copied');
    };

    const onSubmit = (projectUid, data, onSuccess, onError) => {
        dispatch(createApi({ projectUid, data, onSuccess, onError }));
    };

    return (
        <MainContent>
            <div className="main-container">
                <Helmet>
                    <title>
                        Projects - CDProjects - Create beautiful REST API
                        Documentations
                    </title>
                </Helmet>

                <div className="page-header mx-sm-5 mx-3">
                    <div className="d-inline-block">
                        <a
                            href="/projects"
                            className="d-flex align-items-center mb-5"
                        >
                            <img src={arrowBack} alt="back" className="block" />
                            <span className="page-header__back">
                                Back to projects
                            </span>
                        </a>
                    </div>
                    <div className="mb-5 d-flex justify-content-between">
                        <div className="d-flex align-items-center justify-content-center">
                            <ProjectAvatar
                                project={project}
                                additionalClass="inline-logo--large"
                            />
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center pb-2">
                                    <strong
                                        className="fs-4"
                                        style={{ lineHeight: '28px' }}
                                    >
                                        {project ? project.name : ''}
                                    </strong>
                                    <CopyToClipboard
                                        text={`${window.location.origin}/project/${project.code}`}
                                        className="px-2 cursor-pointer"
                                        onCopy={onSuccessCopyToClipboard}
                                    >
                                        <img src={shortLink} alt="copy link" />
                                    </CopyToClipboard>
                                </div>
                                <p
                                    className="font-weight-light"
                                    style={{ opacity: '0.7' }}
                                >
                                    Your APIs
                                </p>
                            </div>
                        </div>
                        <div className="btn-group py-2">
                            <button
                                type="button"
                                className="btn btn-primary dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Project
                            </button>
                            <ul className="dropdown-menu header-dropdown-list">
                                <li>
                                    <a
                                        href={`/project/settings/summary?project=${project.uid}`}
                                        className="dropdown-item link-dropdown project-dropdown-link"
                                    >
                                        Project settings
                                    </a>
                                </li>
                                {hasOwnerRights &&
                                    !project.is_transfer_pending && (
                                        <li>
                                            <button
                                                className="dropdown-item btn-dropdown project-dropdown-link"
                                                name="trash"
                                                onClick={
                                                    toggleTrashProjectModal
                                                }
                                            >
                                                Move to trash
                                            </button>
                                        </li>
                                    )}
                                {hasOwnerRights && project.is_transfer_pending && (
                                    <li>
                                        <button
                                            className="dropdown-item btn-dropdown project-dropdown-link"
                                            name="cancel"
                                            onClick={
                                                toggleCancelProjectTransferModal
                                            }
                                        >
                                            Cancel transfer
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="projects-menu-container main-container mx-5 mb-3">
                    <div className="d-flex">
                        <div className="input-group input-search-container mb-4">
                            <InputSearch
                                id="input-search"
                                type="text"
                                inputStyle={{
                                    width: '300px',
                                    padding: '0.5rem 0.8rem 0.5rem 2rem',
                                }}
                                className="form-control"
                                value={search}
                                onChange={onSearch}
                            />
                        </div>
                        <Multiselect
                            options={multiselectOptions}
                            onChange={updateWithFilters}
                        />
                    </div>
                </div>

                <LocalLoader loading={isApisFetching}>
                    <ApiTable
                        project={project}
                        projects={projects}
                        items={items}
                        onTrash={onTrash}
                        onRestore={onRestore}
                        onDelete={onDelete}
                        onCopy={onCopyApi}
                        onMoveApi={onMoveApi}
                        resetErrors={resetErrors}
                        myAccount={myAccount}
                        isProjectsFetching={isProjectsFetching}
                        errors={errors}
                    />
                </LocalLoader>
            </div>

            <Modal
                show={isCreateApiModal}
                title="Create a new Api"
                body={
                    <CreateApiModal
                        onClose={() => dispatch(isCreateApiModalShown(false))}
                        onSubmit={onSubmit}
                        project={project}
                        onCreated={onCreated}
                        isAccessToCreateApi={isAccessToCreateApi}
                    />
                }
            />
            <Modal
                show={isProjectTrashModalShown}
                title="Move to trash"
                body="This Project will be moved to trash, are you sure?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onTrashProject}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={toggleTrashProjectModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={isCancelProjectTransferModalShown}
                title="Cancel transfer"
                body="This Project transfer will be canceled, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onCancelProjectTransfer}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={toggleCancelProjectTransferModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </MainContent>
    );
};

export default ProjectPage;
