import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Edit3, RotateCcw, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import LocalLoader from 'ui/LocalLoader';
import Modal from 'ui/Modal';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    deleteResponse,
    deleteResponsePermanent,
    getResponse,
    getResponses,
    getTrashedModels,
    getTrashedResponses,
    resourceSelectors,
    restoreResponse,
} from 'store/slices/resourcesSlice';
import Markdown from 'markdown-to-jsx';
import PreBlock from 'ui/PreBlock';
import JsonSchemaViewer from 'components/JsonSchemaViewer/JsonSchemaViewer';
import moment from 'moment';
// import RequestHeadersView from 'pages/Routes/RouteView/RequestHeadersView';

const ModelsView = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const responses = useSelector(resourceSelectors.getResponses);
    const response = useSelector(resourceSelectors.getCurrentResponse);

    const isResponsesFetching = useSelector(
        resourceSelectors.getIsResponsesFetching,
    );
    const isResponseFetching = useSelector(
        resourceSelectors.getIsResponseFetching,
    );

    const [isDeleteResponseModalShown, setIsDeleteResponseModalShown] =
        useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const hasRights =
        project.user_role === 'MAINTAINER' ||
        project.owner.id === myAccount.id ||
        project.user_role === 'WRITE';
    const responseDeleted = [
        response?.deleted_at ? 'restore-response' : 'edit-response',
        response?.deleted_at
            ? 'delete-response-permanently'
            : 'delete-response',
    ];

    useEffect(() => {
        dispatch(getResponse({ responseId: id, onSuccess: () => {} }));
    }, [dispatch, id]);

    const handleClickAction = (e, data, action) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        switch (action) {
            case 'edit-response':
                history.push(
                    `/project/api/components/responses/${data.id}/edit?project=${project.uid}&api=${api.uid}`,
                );
                break;

            case 'restore-response':
                dispatch(
                    restoreResponse({
                        responseId: data.id,
                        onSuccess: onRestoreResponseSuccess,
                    }),
                );
                break;

            case 'delete-response':
                setIsDeleteResponseModalShown(!isDeleteResponseModalShown);
                setSelectedData(data);

                break;

            case 'delete-response-permanently':
                setIsDeleteResponseModalShown(!isDeleteResponseModalShown);
                setSelectedData(data);
                break;

            default:
                break;
        }
    };

    const onDeleteResponse = () => {
        dispatch(
            deleteResponse({
                responseId: selectedData.id,
                onSuccess: onDeleteResponseSuccess,
            }),
        );
    };

    const onDeleteResponseSuccess = () => {
        toast.success('Response successfully deleted');
        handleClickAction(undefined, null, 'delete-response');
        history.push(
            `/project/api/components?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getResponses({ projectUid: project.uid, apiId: api.id }));
        dispatch(
            getTrashedResponses({ projectUid: project.uid, apiId: api.id }),
        );
    };

    const onPermanentDeleteResponse = () => {
        dispatch(
            deleteResponsePermanent({
                responseId: selectedData.id,
                onSuccess: onDeleteResponsePermanentSuccess,
            }),
        );
    };

    const onDeleteResponsePermanentSuccess = () => {
        handleClickAction(undefined, null, 'delete-response-permanently');
        toast.success('Response permanently deleted');
        history.push(
            `/project/api/components?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(
            getTrashedResponses({ projectUid: project.uid, apiId: api.id }),
        );
        dispatch(getResponses({ projectUid: project.uid, apiId: api.id }));
    };

    const onRestoreResponseSuccess = () => {
        toast.success('Response successfully restored');
        history.push(
            `/project/api/components?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getTrashedModels({ projectUid: project.uid, apiId: api.id }));
    };

    const models = responses?.map((el) => JSON.parse(el.schema));
    const isDataFetching = isResponseFetching || isResponsesFetching;

    return (
        <div className="row">
            <div className="col col-md-10 col-lg-10">
                <LocalLoader loading={isDataFetching}>
                    <div className="form-group mb-4">
                        <div className="d-flex mb-3">
                            <h3 className="title-h4 word-break fw-bold">
                                {response?.name}
                            </h3>
                            {hasRights && (
                                <>
                                    <button
                                        onClick={(e) =>
                                            handleClickAction(
                                                e,
                                                response,
                                                responseDeleted[0],
                                            )
                                        }
                                        className="btn-action me-2 edit ms-auto hover-default"
                                    >
                                        {response?.deleted_at ? (
                                            <RotateCcw
                                                size={13}
                                                color="#001A37"
                                            />
                                        ) : (
                                            <Edit3 size={13} color="#001A37" />
                                        )}
                                    </button>
                                    <button
                                        onClick={(e) =>
                                            handleClickAction(
                                                e,
                                                response,
                                                responseDeleted[1],
                                            )
                                        }
                                        className="btn-action remove hover-remove"
                                    >
                                        <Trash2 size={13} color="#FC4D70" />
                                    </button>
                                </>
                            )}
                        </div>
                        {response.deleted_at && (
                            <p className="text-semi-small text-danger mb-3">
                                {`This response was deleted on ${moment(
                                    response.deleted_at,
                                ).format('DD/MM/YYYY')} at ${moment(
                                    response.deleted_at,
                                ).format('HH:mm')}`}
                            </p>
                        )}
                        {response?.description && (
                            <div className="mb-3">
                                <div className="ck ck-line-height">
                                    <Markdown
                                        options={{
                                            overrides: {
                                                pre: PreBlock,
                                            },
                                        }}
                                        className="mb-4 ck word-break"
                                    >
                                        {response?.description}
                                    </Markdown>
                                </div>
                            </div>
                        )}
                        {/* <RequestHeadersView items={response?.headers} /> */}
                        <div className="mt-4">
                            <JsonSchemaViewer
                                modelName={response?.name}
                                models={models}
                                schema={response?.schema}
                                schemaType="response"
                            />
                        </div>
                    </div>
                </LocalLoader>
            </div>
            <Modal
                show={isDeleteResponseModalShown}
                title="Delete"
                body={
                    !response?.deleted_at
                        ? `This response will be deleted, are you sure?`
                        : 'This response will be permanently deleted, are you sure?'
                }
                footer={
                    <>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={
                                !response?.deleted_at
                                    ? onDeleteResponse
                                    : onPermanentDeleteResponse
                            }
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickAction(
                                    undefined,
                                    null,
                                    `${
                                        !response?.deleted_at
                                            ? 'delete-response'
                                            : 'delete-response-permanently'
                                    }`,
                                )
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </>
                }
            />
        </div>
    );
};

export default ModelsView;
