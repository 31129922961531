import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AlertCircle, Edit3, RotateCcw, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

import LocalLoader from 'ui/LocalLoader';
import Modal from 'ui/Modal';
import Markdown from 'markdown-to-jsx';
import PreBlock from 'ui/PreBlock';
import Tooltip from 'ui/Tooltip';
import ToggleButton from 'ui/ToggleButton';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    deletePage,
    deletePagePermanent,
    getPage,
    getPages,
    getTrashedPages,
    pageSelectors,
    restorePage,
} from 'store/slices/pagesSlice';

const PageView = () => {
    const dispatch = useDispatch();
    const { pageId } = useParams();
    const history = useHistory();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const page = useSelector(pageSelectors.getCurrentPage);
    const pageFetching = useSelector(pageSelectors.getIsPageFetching);

    const [isDeletePageModalShown, setIsDeletePageModalShown] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const hasRights =
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'OWNER' ||
        project.user_role === 'WRITE';

    const pageDeleted = [
        page?.deleted_at === null ? 'edit-page' : 'restore-page',
        page?.deleted_at === null ? 'delete-page' : 'delete-page-permanently',
    ];

    useEffect(() => {
        dispatch(getPage({ pageId }));
    }, [dispatch, pageId]);

    const handleClickAction = (e, data, action) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        switch (action) {
            case 'edit-page':
                history.push(
                    `/project/api/pages/${data.id}/edit?project=${project.uid}&api=${api.uid}`,
                );
                break;

            case 'restore-page':
                dispatch(
                    restorePage({
                        pageId: data.id,
                        onSuccess: onRestorePageSuccess,
                    }),
                );

                break;

            case 'delete-page':
                setIsDeletePageModalShown(!isDeletePageModalShown);
                setSelectedData(data);
                break;

            case 'delete-page-permanently':
                setIsDeletePageModalShown(!isDeletePageModalShown);
                setSelectedData(data);
                break;

            default:
                break;
        }
    };

    const onRestorePageSuccess = () => {
        toast.success('Page successfully restored');
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
        handleClickAction(undefined, null, 'restore-page');
    };

    const onDeletePage = () => {
        dispatch(
            deletePage({
                pageId: selectedData.id,
                onSuccess: onDeletePageSuccess,
            }),
        );
    };

    const onDeletePageSuccess = () => {
        setIsDeletePageModalShown(false);
        toast.success('Page successfully deleted');
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getPages({ projectUid: project.uid, apiId: api.id }));
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
        handleClickAction(undefined, null, 'delete-page');
    };

    const onDeletePagePermanentSuccess = () => {
        setIsDeletePageModalShown(false);
        toast.success('Page permanently deleted');
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
        handleClickAction(undefined, null, 'delete-page-permanently');
    };

    const onPermanentDeletePage = () => {
        dispatch(
            deletePagePermanent({
                pageId: selectedData.id,
                onSuccess: onDeletePagePermanentSuccess,
            }),
        );
    };

    return (
        <>
            <LocalLoader loading={pageFetching}>
                <div className="row">
                    <div className="col-10">
                        <div className="col col-lg-11">
                            <div className="form-group mb-4">
                                <div className="d-flex justify-content-between mb-3">
                                    <h3 className="title-h4 word-break fw-bold">
                                        {page?.title}
                                    </h3>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center me-3">
                                            <Tooltip
                                                id="internalAlert"
                                                content="When a page is set as internal, it is no longer visible in public documentation, 
                                                but can still be accessed and seen by members within the workspace."
                                            >
                                                <AlertCircle
                                                    color="gray"
                                                    size={15}
                                                />
                                            </Tooltip>
                                            <p
                                                style={{
                                                    margin: '0 8px 0 4px',
                                                }}
                                            >
                                                Internal
                                            </p>
                                            <ToggleButton
                                                defaultChecked={
                                                    !!page.is_internal
                                                }
                                                className={`d-flex toggle-small-size ${
                                                    !page.is_internal
                                                        ? 'toggle-disabled-container'
                                                        : ''
                                                }`}
                                                disabled={true}
                                            />
                                        </div>
                                        {hasRights && (
                                            <>
                                                <button
                                                    onClick={(e) =>
                                                        handleClickAction(
                                                            e,
                                                            page,
                                                            pageDeleted[0],
                                                        )
                                                    }
                                                    className="btn-action me-2 edit hover-default"
                                                >
                                                    {page?.deleted_at ? (
                                                        <RotateCcw
                                                            size={13}
                                                            color="#001A37"
                                                        />
                                                    ) : (
                                                        <Edit3
                                                            size={13}
                                                            color="#001A37"
                                                        />
                                                    )}
                                                </button>
                                                <button
                                                    onClick={(e) =>
                                                        handleClickAction(
                                                            e,
                                                            page,
                                                            pageDeleted[1],
                                                        )
                                                    }
                                                    className="btn-action remove hover-remove"
                                                >
                                                    <Trash2
                                                        size={13}
                                                        color="#FC4D70"
                                                    />
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {page?.deleted_at && (
                                    <p className="text-semi-small text-danger mb-3">
                                        {`This page was deleted on ${moment(
                                            page.deleted_at,
                                        ).format('DD/MM/YYYY')} at ${moment(
                                            page.deleted_at,
                                        ).format('HH:mm')}`}
                                    </p>
                                )}
                                {page?.description && (
                                    <div className="mb-3">
                                        <div className="ck ck-line-height">
                                            <Markdown
                                                options={{
                                                    overrides: {
                                                        pre: PreBlock,
                                                    },
                                                }}
                                                className="mb-4 ck ck-paragraph word-break"
                                            >
                                                {page?.description}
                                            </Markdown>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </LocalLoader>
            <Modal
                show={isDeletePageModalShown}
                title="Delete"
                body={
                    !page?.deleted_at
                        ? `This page will be deleted, are you sure ?`
                        : 'This page will be permanently deleted, are you sure ?'
                }
                footer={
                    <>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={
                                !page?.deleted_at
                                    ? onDeletePage
                                    : onPermanentDeletePage
                            }
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickAction(
                                    undefined,
                                    null,
                                    `${
                                        !page?.deleted_at
                                            ? 'delete-page'
                                            : 'delete-page-permanently'
                                    }`,
                                )
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </>
                }
            />
        </>
    );
};

export default PageView;
