import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MoreHorizontal } from 'react-feather';
import { uniqueId } from 'utils/helpers';
import useOutsideClick from 'hooks/useOutsideClick';
import useDropdownPosition from 'hooks/useDropdownPosition';

const ResponsesListItem = ({
    project,
    api,
    response,
    handleClickAction,
    selectedDropdown,
    toggleDropdown,
    myAccount,
}) => {
    const [dropdownId, setDropdownId] = useState(undefined);
    const location = useLocation();

    const hasRights =
        project?.user_role === 'MAINTAINER' ||
        project?.owner.id === myAccount.id ||
        project?.user_role === 'WRITE';
    const isShowDropdown = selectedDropdown === dropdownId;

    const dropdownRef = useRef();
    const handleClickOutside = (e) => {
        toggleDropdown(e, null);
    };

    useOutsideClick(dropdownRef, handleClickOutside);
    useDropdownPosition(dropdownRef, selectedDropdown);

    const isActiveLink =
        location.pathname ===
            `/project/api/components/responses/browse/${response.id}` ||
        location.pathname ===
            `/project/api/components/responses/${response.id}/edit`;

    return (
        <li key={response.id} className="nav-item sidebar-link-item mx-2">
            <Link
                className={
                    (isActiveLink && 'sidebar-link-active') +
                    ' nav-link sidebar-link sidebar-link-route word-break'
                }
                to={`/project/api/components/responses/browse/${response.id}?project=${project.uid}&api=${api.uid}`}
            >
                <span className="me-1">{response.name}</span>
                <div className="action-container">
                    {hasRights && (
                        <>
                            <button
                                type="button"
                                className="btn-action edit hover-default"
                                onClick={(e) => {
                                    const id = uniqueId();
                                    setDropdownId(id);
                                    toggleDropdown(e, id);
                                }}
                            >
                                <MoreHorizontal size={16} color="#000000" />
                            </button>

                            {isShowDropdown && (
                                <ul
                                    ref={dropdownRef}
                                    className="dropdown-list"
                                    style={{ minWidth: '110px' }}
                                >
                                    <li>
                                        <button
                                            className="dropdown-link"
                                            name="edit"
                                            onClick={(e) => {
                                                handleClickAction(
                                                    e,
                                                    response,
                                                    'edit-response',
                                                );
                                                toggleDropdown(e, null);
                                            }}
                                        >
                                            Edit
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="dropdown-link"
                                            name="duplicate"
                                            onClick={(e) => {
                                                handleClickAction(
                                                    e,
                                                    response,
                                                    'duplicate-response',
                                                );
                                                toggleDropdown(e, null);
                                            }}
                                        >
                                            Duplicate
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="dropdown-link"
                                            name="copyToApi"
                                            onClick={(e) =>
                                                handleClickAction(
                                                    e,
                                                    response,
                                                    'copy-response',
                                                )
                                            }
                                        >
                                            Copy to API
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="dropdown-link text-danger"
                                            name="delete"
                                            onClick={(e) =>
                                                handleClickAction(
                                                    e,
                                                    response,
                                                    'delete-response',
                                                )
                                            }
                                        >
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            )}
                        </>
                    )}
                </div>
            </Link>
        </li>
    );
};

export default ResponsesListItem;
