import React from 'react';
import { ProjectAvatar } from 'components/Avatar';

const ApisList = ({ apis, project }) => {
    return (
        <>
            <div className="px-2 pb-2">
                <span className="projects-favorite sub-menu p-2 mt-1">
                    Your apis
                </span>
            </div>
            <ul className="starred-projects-container overflow-auto">
                {apis.map((item, index) => (
                    <li key={index}>
                        <a
                            className="dropdown-item link-dropdown py-2 word-break"
                            href={`/project/api/routes?project=${project.uid}&api=${item.uid}`}
                        >
                            <ProjectAvatar project={item} />
                            {item.name}
                        </a>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default ApisList;
