import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import LocalLoader from 'ui/LocalLoader';
import Modal from 'ui/Modal';
import { InputSearch } from 'ui/Inputs';
import MainContent from 'ui/MainContent';
import ProjectAccessModal from './ProjectAccessModal';
import MembersTable from './MembersTable';
import {
    fetchMemberProjects,
    memberSelectors,
} from 'store/slices/membersSlice';

const ModalFooter = (props) => {
    return (
        <button
            type="submit"
            className={`btn btn-link`}
            onClick={props.onClose}
        >
            Close
        </button>
    );
};

const MembersPage = () => {
    const dispatch = useDispatch();

    const members = useSelector(memberSelectors.getMembers);
    const isMembersFetching = useSelector(memberSelectors.getIsMembersFetching);
    const isMemberProjectsFetching = useSelector(
        memberSelectors.getIsMemberProjectsFetching,
    );
    const memberProjects = useSelector(memberSelectors.getMemberProjects);
    const [showModal, setShowModal] = useState(false);
    const [inputSearch, setInputSearch] = useState('');

    const toggleModal = (e) => {
        if (e.target.id) {
            dispatch(fetchMemberProjects({ uuid: e.target.id }));
        }
        //else {
        //     this.props.clearMemberProjects()
        // }
        setShowModal(!showModal);
    };

    const handleChange = (e) => {
        setInputSearch(e.target.value);
    };

    let membersList =
        !!members &&
        members.filter((member) => {
            const name = `${member.first_name} ${member.last_name}`;

            return name.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1;
        });

    return (
        <MainContent>
            <div className="main-container">
                <Helmet>
                    <title>
                        Members - CDProjects - Create beautiful REST API
                        Documentations
                    </title>
                </Helmet>

                <div className="page-header d-flex flex-row justify-content-between mx-sm-5 mx-3">
                    <h3 className="title-h3">All members</h3>
                </div>

                <div className="d-flex mx-5 mb-3">
                    <div className="input-group input-search-container mb-4">
                        <InputSearch
                            id="input-search"
                            type="text"
                            placeHolder="Search"
                            inputStyle={{
                                width: '300px',
                                padding: '0.5rem 0.8rem 0.5rem 2rem',
                            }}
                            className="form-control"
                            value={inputSearch}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <LocalLoader loading={isMembersFetching}>
                    <MembersTable
                        members={membersList}
                        toggleModal={toggleModal}
                    />
                </LocalLoader>
            </div>

            <Modal
                show={showModal}
                title="Projects access"
                body={
                    <ProjectAccessModal
                        isLoading={isMemberProjectsFetching}
                        projects={memberProjects}
                    />
                }
                footer={<ModalFooter onClose={toggleModal} />}
            />
        </MainContent>
    );
};

export default MembersPage;
