import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import './MembersSelector.scss';
import MembersList from './MembersList';
import LocalLoader from 'ui/LocalLoader';
import useOutsideClick from 'hooks/useOutsideClick';
import { fetchMembers, memberSelectors } from 'store/slices/membersSlice';

const MembersSelector = () => {
    const dispatch = useDispatch();

    const isMembersFetching = useSelector(memberSelectors.getIsMembersFetching);
    const members = useSelector(memberSelectors.getMembers);
    const [showList, setShowList] = useState(false);

    const selectMemberDropdown = useRef();

    useEffect(() => {
        dispatch(fetchMembers());
    }, [dispatch]);

    const handleOutsideClick = () => {
        setShowList(false);
    };

    useOutsideClick(selectMemberDropdown, handleOutsideClick);

    const toggleList = (e) => {
        setShowList(!showList);
    };

    return (
        <div className="btn-group" ref={selectMemberDropdown}>
            <button
                type="button"
                className={`custom-dropdown-btn link-dropdown-btn ${
                    showList ? 'show' : ''
                }`}
                onClick={toggleList}
            >
                People
                <ChevronDown
                    className="custom-dropdown-btn-arrow"
                    size={12}
                    strokeWidth={2}
                    aria-expanded={showList ? 'true' : 'false'}
                />
            </button>
            {showList && (
                <div
                    className={`dropdown-menu link-dropdown-list dropdown-people 
                                        ${showList ? 'show' : ''}`}
                >
                    <div>
                        <LocalLoader loading={isMembersFetching}>
                            <MembersList members={members} />
                        </LocalLoader>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MembersSelector;
