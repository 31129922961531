import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import PageHeader from 'ui/PageTitle';
import { Download } from 'react-feather';
import CustomSelect from 'ui/CustomSelect';
import { projectSelectors } from 'store/slices/projectsSlice';
import {
    apiSelectors,
    exportPostmanCollection,
    exportSwaggerCollection,
} from 'store/slices/apiSlice';
import { authSelectors } from 'store/slices/authSlice';
import { toast } from 'react-toastify';

const collections = [
    { value: 'postman', label: 'Postman' },
    { value: 'swagger', label: 'Swagger' },
];

const ExportPage = () => {
    const dispatch = useDispatch();
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const [collection, setCollection] = useState('postman');

    const hasRights =
        project.user_role === 'MAINTAINER' ||
        myAccount.id === project.owner?.id;

    const handleChangeSelect = (option) => {
        if (option) {
            setCollection(option.value);
        }
    };

    const downloadCollection = (e) => {
        e.preventDefault();

        if (collection === 'postman') {
            dispatch(
                exportPostmanCollection({
                    projectUid: project.uid,
                    apiUid: api.uid,
                    onSuccess,
                    onError,
                }),
            );
        }

        if (collection === 'swagger') {
            dispatch(
                exportSwaggerCollection({
                    projectUid: project.uid,
                    apiUid: api.uid,
                    onSuccess,
                    onError,
                }),
            );
        }
    };

    const onSuccess = (response) => {
        const stringifiedCollection = JSON.stringify(response.data);
        const collectionUrl = window.URL.createObjectURL(
            new Blob([stringifiedCollection]),
        );
        let a = document.createElement('a');
        a.href = collectionUrl;
        a.download = `${response.data?.info?.name || 'collection'}.json`;
        a.click();
    };

    const onError = (error) => {
        toast.error('Something went wrong, please try again later.');
    };

    return (
        <div className="container-fluid p-5">
            <Helmet>
                <title>
                    Export - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between mb-4">
                        <PageHeader margin="mb-0">Export</PageHeader>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <p className="mb-4">
                                Here you can download your routes collection
                            </p>
                            {hasRights && (
                                <>
                                    <div style={{ maxWidth: '500px' }}>
                                        <CustomSelect
                                            name="version"
                                            options={collections}
                                            onChange={handleChangeSelect}
                                            defaultOption={collection}
                                            value={collection}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <a
                                            href="/"
                                            className="d-flex align-items-center"
                                            onClick={downloadCollection}
                                        >
                                            <Download
                                                color="#0d6efd"
                                                size={18}
                                            />
                                            <span className="ms-2 fw-bold text-primary">
                                                {collection === 'postman'
                                                    ? 'Download postman collection'
                                                    : 'Download swagger collection'}
                                            </span>
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExportPage;
