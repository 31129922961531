import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchApi } from './apiSlice';
import { fetchMe } from './authSlice';
import { fetchProject } from './projectsSlice';

export const init = createAsyncThunk(
    'app/init',
    async ({ projectUid, apiUid, apiCode }, { dispatch }) => {
        dispatch(showGlobalLoader());
        let chain = [dispatch(fetchMe())];
        if (projectUid && projectUid !== undefined) {
            chain.push(dispatch(fetchProject({ pid: projectUid })));
        }

        if (apiUid) {
            chain.push(dispatch(fetchApi({ projectUid, apiUid, apiCode })));
        }

        await Promise.all(chain).then((response) => {
            dispatch(hideGlobalLoader());
            dispatch(loaded(true));
        });

        // await Promise.all(chain);
        return dispatch(loaded(true));
    },
);

const initialState = {
    showGlobalLoader: false,
    isLoaded: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showGlobalLoader(state) {
            state.showGlobalLoader = true;
        },
        hideGlobalLoader(state) {
            state.showGlobalLoader = false;
        },
        loaded(state, action) {
            state.isLoaded = action.payload;
        },
    },
    extraReducers: {},
});

//Actions

export const { showGlobalLoader, hideGlobalLoader, loaded } = appSlice.actions;

// Selectors

export const appSelectors = {
    getIsLoaded: (state) => state.app.isLoaded,
    getShowGlobalLoader: (state) => state.app.showGlobalLoader,
};

export default appSlice.reducer;
