import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import arrowUp from '../../../assets/img/arrows/arrow-up-right.svg';
import Modal from 'ui/Modal';
import { authSelectors } from '../../../store/selectors';
import { ModalPayment } from './Modals/ModalPayment/ModalPayment';
import { getPlans, setSelectedPlan } from 'store/slices/billingSlice';
import { FREE_PLAN_PRICE } from './utils/consts';

const CurrentPlan = () => {
    const myAccount = useSelector(authSelectors.getMyAccount);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isShowModal, setIsShowModal] = useState(false);
    const { plan, projects_count, users_count, api_count } = myAccount;

    useEffect(() => {
        const locationPlan = location.state?.plan;
        if (locationPlan) {
            dispatch(setSelectedPlan(locationPlan));
            setIsShowModal(true);
            history.replace();
        } else dispatch(setSelectedPlan(plan));

        return () => {
            setSelectedPlan(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, history]);

    useEffect(() => {
        if (isShowModal) {
            dispatch(getPlans());
        }
    }, [dispatch, isShowModal]);

    const numbersOfUsers =
        plan.max_users_count === null
            ? 'Unlimited'
            : `${users_count} of ${plan.max_users_count}`;
    const numbersOfProjects =
        plan.max_projects_count === null
            ? 'Unlimited'
            : `${projects_count} of ${plan.max_projects_count}`;
    const numbersOfApis =
        plan.max_apis_count === null
            ? 'Unlimited'
            : `${api_count} of ${plan.max_apis_count}`;
    const price = plan.cost ? `€${plan.cost}` : FREE_PLAN_PRICE;

    return (
        <div className="p-4 billing--card-block" style={{ margin: '0 32px' }}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <h3 className="title-h5">Current plan</h3>
                    <span className="billing--status">{plan.name}</span>
                </div>
                <div
                    className="d-flex cursor-pointer"
                    onClick={() => setIsShowModal((prev) => !prev)}
                >
                    <button className="px-2 bg-white">
                        <img src={arrowUp} alt="arrow" className="" />
                    </button>
                    <p className="billing--title billing--paragraph">
                        Change plan
                    </p>
                </div>
            </div>
            <p className="billing--paragraph pt-1">For teams (Unlimited API)</p>
            <div className="pt-4">
                <div className="d-flex align-items-center">
                    <p className="billing--plan-price">{price}</p>
                    {price !== FREE_PLAN_PRICE && (
                        <span className="billing--paragraph__small px-2 pt-3">
                            per month
                        </span>
                    )}
                </div>
            </div>

            <div className="pt-4">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="billing--paragraph billing--paragraph-lineheight">
                        Numbers of APIs
                    </p>
                    <p className="billing--paragraph billing--paragraph-lineheight opacity-100 fw-semibold">
                        {numbersOfApis}
                    </p>
                </div>
                <div className="d-flex justify-content-between align-items-center pt-2">
                    <p className="billing--paragraph billing--paragraph-lineheight">
                        Numbers of projects
                    </p>
                    <p className="billing--paragraph  billing--paragraph-lineheight opacity-100 fw-semibold">
                        {numbersOfProjects}
                    </p>
                </div>
                <div className="d-flex justify-content-between align-items-center pt-2">
                    <p className="billing--paragraph billing--paragraph-lineheight">
                        Numbers of users
                    </p>
                    <p className="billing--paragraph  billing--paragraph-lineheight opacity-100 fw-semibold">
                        {numbersOfUsers}
                    </p>
                </div>
            </div>
            <Modal
                show={isShowModal}
                body={<ModalPayment setIsShowModal={setIsShowModal} />}
            />
        </div>
    );
};

export default CurrentPlan;
