import React, { Fragment } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Shield, Users } from 'react-feather';

import MainContent from 'ui/MainContent';
import { LeftSideBar, LeftSideBarHeader } from 'components/LeftSideBar';
import UserAvatar from 'components/Avatar/UserAvatar';
import Profile from './Profile';
import SecurityPage from './Security/SecurityPage';
import { authSelectors } from 'store/slices/authSlice';

const UserSettingsLayout = () => {
    const myAccount = useSelector(authSelectors.getMyAccount);

    return (
        <Fragment>
            <LeftSideBar>
                <nav>
                    <LeftSideBarHeader>
                        <UserAvatar
                            iconUrl={myAccount.avatar_url}
                            first_name={myAccount.first_name}
                            last_name={myAccount.last_name}
                        />
                        <strong>{`${myAccount.first_name} ${myAccount.last_name}`}</strong>
                    </LeftSideBarHeader>

                    <ul className="nav flex-column">
                        <li className="nav-item sidebar-link-item mx-2">
                            <NavLink
                                className="nav-link sidebar-link"
                                activeClassName="sidebar-link-active"
                                to={`/user/settings/profile`}
                            >
                                <Users
                                    size={20}
                                    className="sidebar-link-icon"
                                    strokeWidth={1.5}
                                />
                                Profile
                            </NavLink>
                        </li>
                        <li className="nav-item sidebar-link-item mx-2">
                            <NavLink
                                activeClassName="sidebar-link-active"
                                className="nav-link sidebar-link"
                                to={`/user/settings/security`}
                            >
                                <Shield
                                    size={20}
                                    className="sidebar-link-icon"
                                    strokeWidth={1.5}
                                />
                                Security
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </LeftSideBar>
            <MainContent>
                <Switch>
                    <Route exact path="/user/settings/profile">
                        <Profile myAccount={myAccount} />
                    </Route>
                    <Route exact path="/user/settings/security">
                        <SecurityPage />
                    </Route>
                </Switch>
            </MainContent>
        </Fragment>
    );
};

export default UserSettingsLayout;
