export function setCookie(name, value, options, keepLogged = true) {
    options = options || {};

    // Set path to avoid extra cookies with the same name
    // (like with path "invite" from api/v1/account/invite
    options.path = '/';

    let expires = options.expires;

    if (typeof expires === 'number' && expires) {
        let d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        options.expires = d;
    }

    // If it's not deleteCookie: expires !== -1
    if (keepLogged && expires !== -1) {
        let d = new Date();
        const year = 1000 * 60 * 60 * 24 * 365;
        d.setTime(d.getTime() + year);

        options.expires = d;
    }

    expires = options.expires;

    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + '=' + value;

    for (let propName in options) {
        updatedCookie += '; ' + propName;
        let propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += '=' + propValue;
        }
    }

    document.cookie = updatedCookie;
}

export function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(';');

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split('=');

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export function deleteCookie(name) {
    setCookie(name, '', {
        expires: -1,
    });
}

/*
export function prolongCookie(name, value) {
    setCookie(name, value, {
        expires: config.KEEP_LOG_TIME_SEC
    }, false);
    setCookie('refresh_token', '', {
        expires: config.KEEP_LOG_TIME_SEC
    }, false)
}
*/
