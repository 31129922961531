import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'ui/Inputs';
import CustomSelect from 'ui/CustomSelect';
import {
    authSelectors,
    fetchCountries,
    updateMyAccount,
} from 'store/slices/authSlice';

const Profile = () => {
    const dispatch = useDispatch();
    const myAccount = useSelector(authSelectors.getMyAccount);
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country: null,
        // phone: '',
    });
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState({});
    // const [image, setImage] = useState({
    //     preview: '',
    //     raw: '',
    // })
    const options = countries?.map((country) => {
        return {
            ...country,
            value: country.iso,
            label: country.name,
        };
    });

    useEffect(() => {
        setData({
            first_name: myAccount.first_name || '',
            last_name: myAccount.last_name || '',
            email: myAccount.email || '',
            country: myAccount.country?.iso || '',
            // phone: myAccount.phone || "",
        });
        dispatch(
            fetchCountries({ onSuccess: setAllCountries, onError: (e) => {} }),
        );
    }, [dispatch, myAccount]);

    const setAllCountries = (data) => {
        setCountries(data);
    };

    const handleChangeSelect = (option) => {
        setData({ ...data, country: option.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateMyAccount({ data, onSuccess, onError }));
    };

    const onSuccess = (response) => {
        toast.success('Your data has been saved.');

        setTimeout(() => {
            window.location.href = '/user/settings/profile';
        }, 700);
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <div className="container-fluid p-5">
            <Helmet>
                <title>
                    Profile - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>

            <div className="row">
                <div className="col">
                    <h3 className="title-h3 mb-4">Profile</h3>
                    <div className="pb-2">
                        <form
                            className="user-profile-form"
                            style={{ width: '500px' }}
                        >
                            {/* <div className="mb-5">
                            <ImageUploadUser addFile={addFile} image={image} />
                        </div> */}
                            <div className="form-group mb-4 row">
                                <div className="col-6">
                                    <Input
                                        type="text"
                                        name="first_name"
                                        className="form-control"
                                        value={data.first_name}
                                        onChange={handleChange}
                                        labelText="Name"
                                        labelClassName="label-main mb-2"
                                        placeHolder="Name"
                                        errorText={errors.first_name}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        type="text"
                                        name="last_name"
                                        className="form-control"
                                        value={data.last_name}
                                        onChange={handleChange}
                                        labelText="Surname"
                                        labelClassName="label-main mb-2"
                                        placeHolder="Surname"
                                        errorText={errors.last_name}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <Input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    value={data.email}
                                    onChange={handleChange}
                                    labelText="Email address"
                                    labelClassName="label-main mb-2"
                                    placeHolder="email"
                                    errorText={errors.email}
                                    isDisabled={true}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label
                                    htmlFor="country"
                                    className="invite-label mb-2"
                                >
                                    Country
                                </label>
                                <CustomSelect
                                    name="country"
                                    options={options}
                                    onChange={handleChangeSelect}
                                    value={data.country}
                                />
                            </div>
                            {/* <div className="form-group mb-4">
                                <Input
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    value={data.phone}
                                    onChange={handleChange}
                                    labelText="Phone number"
                                    labelClassName="label-main mb-2"
                                    placeHolder="Phone number"
                                    errorText={errors.phone}
                                />
                            </div> */}
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="btn btn-primary"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
