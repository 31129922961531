import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Plus } from 'react-feather';

import { countRouteMethod } from 'utils/helpers';
import RoutesListDndContainer from './RoutesListDndContainer';
import GroupFormModal from '../GroupFormModal';
import Modal from 'ui/Modal';
import LocalLoader from 'ui/LocalLoader';
import CustomSelect from 'ui/CustomSelect';
import RequiredMark from 'ui/RequiredMark/RequiredMark';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    copyRouteToApi,
    deleteRoute,
    deleteRouteGroup,
    fetchRoutes,
    fetchRoutesTrash,
    initRoutes,
    routeSelectors,
    setRouteGroup,
} from 'store/slices/routesSlice';
import { pageSelectors } from 'store/slices/pagesSlice';
import { getCookie } from 'utils/cookie';

const RoutesList = ({
    inputSearch,
    routes,
    pages,
    selectedDropdown,
    toggleDropdown,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const apis = useSelector(apiSelectors.getApis);
    const route = useSelector(routeSelectors.getCurrentRoute);
    const groups = useSelector(routeSelectors.getGroups);
    const isRoutesFetching = useSelector(routeSelectors.getIsRoutesFetching);
    const isPagesFetching = useSelector(pageSelectors.getIsPagesFetching);
    const routesSettings = useSelector(routeSelectors.getRoutesListSettings);

    const [data, setData] = useState({ api_uid_copy_to: '' });
    const [modalShown, setModalShown] = useState({
        isCopyRoutesModalShown: false,
        isDeleteRouteModalShown: false,
        isDeleteGroupModalShown: false,
        isGroupFormModalShown: false,
    });
    const [selectedData, setSelectedData] = useState(null);
    const [errors, setErrors] = useState({});

    const {
        isDeleteRouteModalShown,
        isDeleteGroupModalShown,
        isGroupFormModalShown,
        isCopyRoutesModalShown,
    } = modalShown;

    const routesListSettings = useSelector(
        routeSelectors.getRoutesListSettings,
    );

    useLayoutEffect(() => {
        dispatch(initRoutes({ project: project.uid, apiId: api.id }));
        dispatch(fetchRoutesTrash({ project: project.uid, apiId: api.id }));
    }, [api.id, dispatch, project.uid]);

    useEffect(() => {}, [routesListSettings]);

    const withoutGroup = routes.filter((el) => el.group_id === null);
    const hasRights =
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'OWNER' ||
        project.user_role === 'WRITE';
    const selectApisOptionsToCopyApi = apis
        .filter((item) => item.id !== api.id)
        .reduce((acc, item) => {
            acc.push({ value: item.uid, label: item.name });
            return acc;
        }, []);

    const handleClickAction = (e, data, action) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        switch (action) {
            case 'edit-route':
                history.push(
                    `/project/api/routes/${data.id}/edit?project=${project.uid}&api=${api.uid}`,
                );
                break;
            case 'copy-route':
                setModalShown({ ...modalShown, isCopyRoutesModalShown: true });
                setSelectedData(data);
                break;
            case 'delete-route':
                setModalShown({
                    ...modalShown,
                    isDeleteRouteModalShown: !isDeleteRouteModalShown,
                });
                setSelectedData(data);
                break;
            case 'duplicate-route':
                history.push(
                    `/project/api/routes/create?project=${project.uid}&api=${api.uid}`,
                    { ...data, action: 'duplicate' },
                );
                break;
            case 'edit-group':
                setModalShown({
                    ...modalShown,
                    isGroupFormModalShown: !isGroupFormModalShown,
                });
                setSelectedData(data);
                break;
            case 'delete-group':
                setModalShown({
                    ...modalShown,
                    isDeleteGroupModalShown: !isDeleteGroupModalShown,
                });
                setSelectedData(data);
                break;

            default:
                break;
        }
    };

    const onDeleteRoute = async () => {
        await dispatch(deleteRoute({ id: selectedData.id })).unwrap();
        dispatch(fetchRoutes({ project: project.uid, apiId: api.id }));
        dispatch(
            fetchRoutesTrash({
                project: project.uid,
                apiId: api.id,
            }),
        );
        toast.success('Successfully deleted');
        setModalShown({
            ...modalShown,
            isDeleteRouteModalShown: false,
        });
        setSelectedData(null);
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
    };

    const onDeleteGroup = async () => {
        await dispatch(deleteRouteGroup({ id: selectedData.id })).unwrap();
        await dispatch(initRoutes({ project: project.uid, apiId: api.id }));
        dispatch(
            fetchRoutesTrash({
                project: project.uid,
                apiId: api.id,
            }),
        );
        setModalShown({
            ...modalShown,
            isDeleteGroupModalShown: false,
        });
        toast.success('Successfully deleted');
        setSelectedData(null);
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
    };

    const handleChangeSelect = (option) => {
        setData({ ...data, api_uid_copy_to: option.value });
    };

    const moveRoute = async (sourceId, targetId) => {
        const data = {
            group_id: targetId,
        };
        await dispatch(setRouteGroup({ id: sourceId, data })).unwrap();
        dispatch(fetchRoutes({ project: project.uid, apiId: api.id }));
    };

    const resetErrors = () => {
        setErrors({});
    };

    const onSuccessCopyRouteToApi = () => {
        toast.success('The Route has been copied to API');
        setTimeout(
            () =>
                window.location.replace(
                    `/project/api/routes?project=${project.uid}&api=${data.api_uid_copy_to}`,
                ),
            700,
        );
    };

    const onSetError = (error) => {
        setErrors(error.response.data.errors);
    };

    const isCopyModalShown = (value) => {
        setData({ ...data, api_uid_copy_to: '' });
        setModalShown({ ...modalShown, isCopyRoutesModalShown: value });
    };

    const onCopyApi = () => {
        dispatch(
            copyRouteToApi({
                routeId: selectedData.id,
                data,
                onSuccess: onSuccessCopyRouteToApi,
                onError: onSetError,
                isCopyModalShown,
            }),
        );

        resetErrors();
    };

    const routesListSorter = (a, b, { field, direction }) => {
        if (a[field] === b[field]) {
            return 0;
        }

        if (
            a[field].charAt(0).toLowerCase() ===
            b[field].charAt(0).toLowerCase()
        ) {
            if (
                /^[a-z]/.test(a[field].charAt(0)) &&
                /^[A-Z]/.test(b[field].charAt(0))
            ) {
                return direction === 'ASC' ? -1 : 1;
            }
            if (
                /^[a-z]/.test(b[field].charAt(0)) &&
                /^[A-Z]/.test(a[field].charAt(0))
            ) {
                return direction === 'ASC' ? 1 : -1;
            }
        }
        return direction === 'ASC'
            ? a[field].localeCompare(b[field])
            : b[field].localeCompare(a[field]);
    };

    const sortSettings = JSON.parse(getCookie('routes_settings'));
    const settingsFields = {
        field: sortSettings?.sort?.type === 'name' ? 'name' : 'created_at',
        direction: sortSettings?.sort?.direction,
    };

    const sortedRoutes = routes.toSorted((a, b) =>
        routesListSorter(a, b, settingsFields),
    );
    const sortedRoutesWithoutGroup = withoutGroup.toSorted((a, b) =>
        routesListSorter(a, b, settingsFields),
    );

    const commonProps = {
        project,
        api,
        path: location,
        selectedDropdown,
        countMethod: countRouteMethod,
        handleClickAction: handleClickAction,
        moveRoute: moveRoute,
        toggleDropdown: toggleDropdown,
    };

    if (!pages.length && !routes.length && inputSearch)
        return (
            <LocalLoader loading={isRoutesFetching} className="mt-5">
                <div className="create-route-panel not-found">
                    <p className="create-route-panel__desc">
                        No routes or pages found
                    </p>
                </div>
            </LocalLoader>
        );

    if (!routes.length && !groups.length && !hasRights)
        return (
            <LocalLoader loading={isRoutesFetching} className="mt-5">
                <div className="create-route-panel not-found">
                    <p className="create-route-panel__desc">
                        There are no documented API calls at the moment.
                    </p>
                </div>
            </LocalLoader>
        );

    if (!routes.length && !groups.length && hasRights)
        return (
            <LocalLoader loading={isRoutesFetching} className="mt-5">
                <Link
                    to={`/project/api/routes/create?project=${project.uid}&api=${api.uid}`}
                    className={`create-route-panel mt-1 ${
                        location.pathname.includes(
                            '/project/api/routes/create',
                        ) && 'disabled'
                    }`}
                >
                    <h6 className="create-route-panel__title">
                        <Plus size={16} />
                        <span>Create your first route</span>
                    </h6>
                    <p className="create-route-panel__desc">
                        You can edit and share with your team
                    </p>
                </Link>
            </LocalLoader>
        );

    return (
        <LocalLoader
            loading={isRoutesFetching || isPagesFetching}
            className="mt-5"
        >
            <DndProvider backend={HTML5Backend}>
                <RoutesListDndContainer
                    props={commonProps}
                    routes={sortedRoutes}
                    route={route}
                    groups={groups}
                    project={project}
                    withoutGroup={sortedRoutesWithoutGroup}
                    inputSearch={inputSearch}
                    routesSettings={routesSettings}
                />
            </DndProvider>

            <Modal
                show={isDeleteRouteModalShown}
                title="Delete"
                body="This route will be deleted, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDeleteRoute}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickAction(
                                    undefined,
                                    null,
                                    'delete-route',
                                )
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={isDeleteGroupModalShown}
                title="Delete"
                body="This group will be deleted, are you sure?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDeleteGroup}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickAction(
                                    undefined,
                                    null,
                                    'delete-group',
                                )
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />

            <Modal
                show={isCopyRoutesModalShown}
                title="Copy to API"
                body={
                    <div>
                        <LocalLoader loading={isRoutesFetching}>
                            <p className="mb-2">
                                Select api
                                <RequiredMark />
                            </p>

                            <CustomSelect
                                name="copyApi"
                                options={selectApisOptionsToCopyApi}
                                onChange={handleChangeSelect}
                                value={data.api_uid_copy_to}
                                fieldError={errors?.api_uid_copy_to}
                            />
                        </LocalLoader>
                    </div>
                }
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={onCopyApi}
                        >
                            Copy
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                setModalShown({
                                    ...modalShown,
                                    isCopyRoutesModalShown: false,
                                })
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            {isGroupFormModalShown && (
                <Modal
                    show={isGroupFormModalShown}
                    title="Edit group"
                    body={
                        <GroupFormModal
                            selectedData={selectedData}
                            onClose={() =>
                                handleClickAction(undefined, null, 'edit-group')
                            }
                            onSuccess={() =>
                                setModalShown({
                                    ...modalShown,
                                    isGroupFormModalShown: false,
                                })
                            }
                        />
                    }
                />
            )}
        </LocalLoader>
    );
};

export default RoutesList;
