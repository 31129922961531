import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import LocalLoader from 'ui/LocalLoader';
import Modal from 'ui/Modal';
import CustomSelect from 'ui/CustomSelect';
import RequiredMark from 'ui/RequiredMark/RequiredMark';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    copyModelToApi,
    copyResponseToApi,
    deleteModel,
    deleteResponse,
    getModels,
    getResponses,
    getTrashedModels,
    getTrashedResponses,
    resourceSelectors,
} from 'store/slices/resourcesSlice';
import ModelsListConfigurator from './Models/ModelsListConfigurator';
import ResponsesListConfigurator from './Responses/ResponsesListConfigurator';
import { routeSelectors, initRoutes } from 'store/slices/routesSlice';
import { Plus } from 'react-feather';
import { FolderClose, FolderOpen } from 'assets/img/Svg';

const ResourcesList = ({ resources, responses, inputSearch }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const apis = useSelector(apiSelectors.getApis);
    const routes = useSelector(routeSelectors.getRoutes);
    const isResourcesFetching = useSelector(
        resourceSelectors.getIsResourcesFetching,
    );
    const isResponsesFetching = useSelector(
        resourceSelectors.getIsResponsesFetching,
    );

    const [data, setData] = useState({ api_uid_copy_to: '' });
    const [modalShown, setModalShown] = useState({
        isCopyModalShown: false,
        isCopyResponseModalShown: false,
        isDeleteResourceModalShown: false,
        isDeleteResponseModalShown: false,
    });
    const [selectedData, setSelectedData] = useState(null);
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [selectedResource, setSelectedResource] = useState(null);
    const [deleteResourceError, setDeleteResourceError] = useState('');
    const [errors, setErrors] = useState({});
    const [isShowModels, setIsShowModels] = useState(false);
    const [isShowResponses, setIsShowResponses] = useState(false);

    const hasRights =
        project.user_role === 'MAINTAINER' ||
        project.owner.id === myAccount.id ||
        project.user_role === 'WRITE';
    const selectApisOptionsToCopyApi = apis
        ?.filter((item) => item.id !== api.id)
        .reduce((acc, item) => {
            acc.push({ value: item.uid, label: item.name });
            return acc;
        }, []);

    useEffect(() => {
        dispatch(getModels({ apiId: api.id })).unwrap();
        dispatch(getResponses({ apiId: api.id })).unwrap();

        if (!routes?.length) {
            dispatch(
                initRoutes({ project: project.uid, apiId: api.id }),
            ).unwrap();
        }
    }, [dispatch, api, routes?.length, project.id, project.гid, project.uid]);

    useEffect(() => {
        const id = window.location.pathname.includes('edit')
            ? window.location.pathname.split('/').slice(-2)[0]
            : window.location.pathname.split('/').slice(-1)[0];
        const foundCurrentResource = resources?.find((el) => el.id === +id);
        const foundCurrentResponse = responses?.find((el) => el.id === +id);

        if (
            foundCurrentResource &&
            window.location.pathname.includes('models')
        ) {
            setIsShowModels(true);
        }

        if (
            foundCurrentResponse &&
            window.location.pathname.includes('responses')
        ) {
            setIsShowResponses(true);
        }
    }, [resources, responses]);

    const toggleModelsFolder = () => {
        setIsShowModels((prev) => !prev);
    };

    const toggleResponsesFolder = () => {
        setIsShowResponses((prev) => !prev);
    };

    const closeCopyToApiModals = () => {
        setModalShown({
            ...modalShown,
            isCopyModalShown: false,
            isCopyResponseModalShown: false,
        });
        setErrors({});
        setData({ ...data, api_uid_copy_to: '' });
    };

    const handleClickAction = (e, data, action) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        switch (action) {
            case 'edit-model':
                history.push(
                    `/project/api/components/models/${data.id}/edit?project=${project.uid}&api=${api.uid}`,
                );
                break;
            case 'edit-response':
                history.push(
                    `/project/api/components/responses/${data.id}/edit?project=${project.uid}&api=${api.uid}`,
                );
                break;
            case 'duplicate-model':
                history.push(
                    `/project/api/components/models/create?project=${project.uid}&api=${api.uid}`,
                    { ...data, action: 'duplicate' },
                );
                break;
            case 'duplicate-response':
                history.push(
                    `/project/api/components/responses/create?project=${project.uid}&api=${api.uid}`,
                    { ...data, action: 'duplicate' },
                );
                break;
            case 'copy-model':
                setModalShown({
                    ...modalShown,
                    isCopyModalShown: !modalShown.isCopyModalShown,
                });
                setSelectedData(data);
                setSelectedResource('model');

                break;
            case 'copy-response':
                setModalShown({
                    ...modalShown,
                    isCopyResponseModalShown:
                        !modalShown.isCopyResponseModalShown,
                });
                setSelectedData(data);
                setSelectedResource('response');

                break;
            case 'delete-response':
                setModalShown({
                    ...modalShown,
                    isDeleteResponseModalShown:
                        !modalShown.isDeleteResponseModalShown,
                });
                setSelectedData(data);
                setSelectedResource('response');
                setDeleteResourceError([]);
                break;
            case 'delete-model':
                setModalShown({
                    ...modalShown,
                    isDeleteResourceModalShown:
                        !modalShown.isDeleteResourceModalShown,
                });
                setSelectedData(data);
                setSelectedResource('model');
                setDeleteResourceError([]);
                break;
            default:
                break;
        }
    };

    const handleChangeSelect = (option) => {
        setData({ ...data, api_uid_copy_to: option.value });
    };

    const toggleDropdown = (e, id) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setSelectedDropdown(id);
    };

    const onDeleteResourceError = (error) => {
        setDeleteResourceError(error.response.data[0]);
    };

    const onDeleteResourceSuccess = () => {
        toast.success('Model successfully deleted');
        history.push(
            `/project/api/components?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getModels({ apiId: api.id }));
        dispatch(getTrashedModels({ apiId: api.id }));
        handleClickAction(undefined, null, 'delete-model');
    };

    const onDeleteResource = () => {
        dispatch(
            deleteModel({
                resourceId: selectedData.id,
                onSuccess: onDeleteResourceSuccess,
                onError: onDeleteResourceError,
            }),
        );
    };

    const isCopyModalShown = (value) => {
        setData({ ...data, api_uid_copy_to: '' });
        setModalShown({
            ...modalShown,
            isCopyModalShown: value,
            isCopyResponseModalShown: value,
        });

        resetErrors();
    };

    const resetErrors = () => {
        setErrors({});
    };

    const onCopyResourceToApi = () => {
        dispatch(
            copyModelToApi({
                resourceId: selectedData.id,
                data,
                onSuccess: onSuccessCopyResourceToApi,
                onError: onSetError,
                isCopyModalShown,
            }),
        );

        resetErrors();
    };

    const onSuccessCopyResourceToApi = () => {
        toast.success('The model has been copied to API');
        setTimeout(
            () =>
                window.location.replace(
                    `/project/api/components?project=${project.uid}&api=${data.api_uid_copy_to}`,
                ),
            700,
        );
        handleClickAction(undefined, null, 'copy-model');
    };

    const onCopyResponseToApi = () => {
        dispatch(
            copyResponseToApi({
                responseId: selectedData.id,
                data,
                onSuccess: onSuccessCopyResponseToApi,
                onError: onSetError,
                isCopyModalShown,
            }),
        );

        resetErrors();
    };

    const onSuccessCopyResponseToApi = () => {
        toast.success('The response has been copied to API');
        setTimeout(
            () =>
                window.location.replace(
                    `/project/api/components?project=${project.uid}&api=${data.api_uid_copy_to}`,
                ),
            700,
        );
        handleClickAction(undefined, null, 'copy-response');
    };

    const onSetError = (error) => {
        setErrors(error.response.data.errors);
    };

    const onDeleteResponseSuccess = () => {
        toast.success('Response successfully deleted');
        history.push(
            `/project/api/components?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getResponses({ apiId: api.id }));
        dispatch(getTrashedResponses({ apiId: api.id }));
        handleClickAction(undefined, null, 'delete-response');
    };

    const onDeleteResponse = () => {
        dispatch(
            deleteResponse({
                responseId: selectedData.id,
                onSuccess: onDeleteResponseSuccess,
                onError: onDeleteResourceError,
            }),
        );
    };

    const deleteActionsMap = {
        model: onDeleteResource,
        response: onDeleteResponse,
    };

    const sharedProps = {
        selectedDropdown,
        toggleDropdown,
        handleClickAction,
        hasRights,
        inputSearch,
    };

    const isDataFetching = isResourcesFetching || isResponsesFetching;

    return (
        <LocalLoader loading={isDataFetching} className="mt-5">
            <ul className="nav flex-column">
                <li className="nav-item sidebar-link-item mx-2">
                    <div
                        onClick={toggleModelsFolder}
                        className={
                            'nav-link sidebar-link sidebar-link-route wrod-break'
                        }
                    >
                        <div style={{ marginRight: '4px' }}>
                            {isShowModels ? (
                                <FolderOpen style={{ stroke: '#6e2fff' }} />
                            ) : (
                                <FolderClose style={{ stroke: '#6e2fff' }} />
                            )}
                        </div>
                        <span className="me-1">Models</span>
                        <div className="action-container">
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                        `/project/api/components/models/create?project=${project.uid}&api=${api.uid}`,
                                    );
                                }}
                                className="btn-action edit hover-default"
                            >
                                <Plus size={14} color="#000000" />
                            </button>
                        </div>
                    </div>
                </li>
                <ModelsListConfigurator
                    {...sharedProps}
                    resources={resources}
                    isShowModels={isShowModels}
                />
            </ul>
            <ul className="nav flex-column">
                <li className="nav-item sidebar-link-item mx-2">
                    <div
                        onClick={toggleResponsesFolder}
                        className={
                            'nav-link sidebar-link sidebar-link-route wrod-break'
                        }
                    >
                        <div style={{ marginRight: '4px' }}>
                            {isShowResponses ? (
                                <FolderOpen style={{ stroke: '#00c26e' }} />
                            ) : (
                                <FolderClose style={{ stroke: '#00c26e' }} />
                            )}
                        </div>
                        <span className="me-1">Responses</span>
                        <div className="action-container">
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                        `/project/api/components/responses/create?project=${project.uid}&api=${api.uid}`,
                                    );
                                }}
                                className="btn-action edit hover-default"
                            >
                                <Plus size={14} color="#000000" />
                            </button>
                        </div>
                    </div>
                </li>
                <ResponsesListConfigurator
                    {...sharedProps}
                    responses={responses}
                    isShowResponses={isShowResponses}
                />
            </ul>
            <>
                <Modal
                    show={
                        modalShown.isDeleteResourceModalShown ||
                        modalShown.isDeleteResponseModalShown
                    }
                    title="Delete"
                    body={
                        <>
                            <p>{`This ${selectedResource} will be deleted, are you sure ?`}</p>
                            <p className="text-danger mt-3">
                                {deleteResourceError}
                            </p>
                        </>
                    }
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={deleteActionsMap[selectedResource]}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    handleClickAction(
                                        undefined,
                                        null,
                                        `delete-${selectedResource}`,
                                    )
                                }
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />

                <Modal
                    show={
                        modalShown.isCopyModalShown ||
                        modalShown.isCopyResponseModalShown
                    }
                    title="Copy to API"
                    body={
                        <div>
                            <LocalLoader loading={isResourcesFetching}>
                                <p className="mb-2">
                                    Select api
                                    <RequiredMark />
                                </p>
                                <CustomSelect
                                    name="copyToApi"
                                    options={selectApisOptionsToCopyApi}
                                    onChange={handleChangeSelect}
                                    value={data.api_uid_copy_to}
                                    fieldError={errors?.api_uid_copy_to}
                                />
                            </LocalLoader>
                        </div>
                    }
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={
                                    selectedResource === 'model'
                                        ? onCopyResourceToApi
                                        : onCopyResponseToApi
                                }
                            >
                                Copy
                            </button>
                            <button
                                type="button"
                                onClick={closeCopyToApiModals}
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
            </>
        </LocalLoader>
    );
};

export default ResourcesList;
