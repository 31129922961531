import React, { useRef, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MoreHorizontal } from 'react-feather';
import { toast } from 'react-toastify';

import { countRouteMethod as countMethod } from 'utils/helpers';
import Modal from 'ui/Modal';
import useOutsideClick from 'hooks/useOutsideClick';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import { pageSelectors } from 'store/slices/pagesSlice';
import {
    fetchRoutesTrash,
    initRoutes,
    permanentDeleteRoute,
    restoreRoute,
    routeSelectors,
} from 'store/slices/routesSlice';
import useDropdownPosition from 'hooks/useDropdownPosition';

export const RoutesTrash = ({
    routesTrash,
    setSelectedDropdown,
    selectedDropdown,
    toggleDropdown,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const isPagesTrashFetching = useSelector(
        pageSelectors.getIsPagesTrashFetching,
    );
    const routes = useSelector(routeSelectors.getRoutes);

    const [isDeleteRouteModalShown, setIsDeleteRouteModalShown] =
        useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const dropdownRef = useRef();

    const hasRights =
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'OWNER' ||
        project.user_role === 'WRITE';

    useEffect(() => {
        dispatch(fetchRoutesTrash({ project: project.uid, apiId: api.id }));
    }, [api.id, dispatch, project.uid]);

    const handleClickOutside = (e) => {
        setSelectedDropdown(null);
    };

    useOutsideClick(dropdownRef, handleClickOutside);
    useDropdownPosition(dropdownRef, selectedDropdown);

    const handleClickAction = (e, data, action) => {
        e.stopPropagation();
        e.preventDefault();

        switch (action) {
            case 'restore-route':
                onRestoreRoute(data);
                break;
            case 'delete-route':
                if (!data) {
                    toggleDropdown(undefined, null);
                }

                setIsDeleteRouteModalShown(!isDeleteRouteModalShown);
                setSelectedData(data);
                break;

            default:
                break;
        }
    };

    const onRestoreRoute = async (data) => {
        const foundDuplicatedRoute = routes.find(
            (el) => el.name === data.name && el.url === data.url,
        );

        if (foundDuplicatedRoute) {
            toast.error('You already have route with the same name and url', {
                autoClose: 5000,
            });
            return;
        }

        await dispatch(restoreRoute({ routeId: data.id })).unwrap();
        dispatch(initRoutes({ project: project.uid, apiId: api.id }));
        dispatch(fetchRoutesTrash({ project: project.uid, apiId: api.id }));
        toast.success('Route successfully restored');

        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
    };

    const onDeleteRoute = async () => {
        await dispatch(
            permanentDeleteRoute({ routeId: selectedData.id }),
        ).unwrap();
        dispatch(fetchRoutesTrash({ project: project.uid, apiId: api.id }));

        toast.success('Route permanently deleted');

        if (selectedData) {
            setIsDeleteRouteModalShown(false);
            setSelectedData(null);
        }

        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
    };

    return (
        <>
            <ul className={`nav flex-column`}>
                {!!routesTrash.length &&
                    !isPagesTrashFetching &&
                    routesTrash.map((route, index) => {
                        const isActiveLink =
                            location.pathname ===
                            `/project/api/routes/browse/${route.id}`;
                        const isShowDropdown = selectedDropdown === route.id;

                        return (
                            <li
                                key={index}
                                className="nav-item sidebar-link-item mx-2"
                            >
                                <Link
                                    className={
                                        (isActiveLink &&
                                            'sidebar-link-active') +
                                        ' nav-link sidebar-link sidebar-link-route word-break'
                                    }
                                    to={`/project/api/routes/browse/${route.id}?project=${project.uid}&api=${api.uid}`}
                                >
                                    {countMethod(route.method)}
                                    {route.name ?? route.url}
                                    <div className="action-container">
                                        {hasRights && (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={(e) =>
                                                        toggleDropdown(
                                                            e,
                                                            route.id,
                                                        )
                                                    }
                                                    className="btn-action edit hover-default"
                                                >
                                                    <MoreHorizontal
                                                        size={16}
                                                        color="#000000"
                                                    />
                                                </button>
                                                {isShowDropdown && (
                                                    <ul
                                                        ref={dropdownRef}
                                                        className="dropdown-list"
                                                    >
                                                        <li>
                                                            <button
                                                                className="dropdown-link"
                                                                name="edit"
                                                                onClick={(e) =>
                                                                    handleClickAction(
                                                                        e,
                                                                        route,
                                                                        'restore-route',
                                                                    )
                                                                }
                                                            >
                                                                Restore
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="dropdown-link text-danger"
                                                                name="delete"
                                                                onClick={(e) =>
                                                                    handleClickAction(
                                                                        e,
                                                                        route,
                                                                        'delete-route',
                                                                    )
                                                                }
                                                            >
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
            <Modal
                show={isDeleteRouteModalShown}
                title="Delete"
                body="This route will be permanently deleted, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDeleteRoute}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={(e) =>
                                handleClickAction(e, null, 'delete-route')
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </>
    );
};

export default RoutesTrash;
