import TableInvoiceItem from './TableInvoiceItem';

const TableInvoice = ({ items, downloadInvoice }) => {
    return (
        <div className="project-list">
            <table className="table table-hover table-main projects-table">
                <thead className="table-main-head">
                    <tr className="projects-table-row">
                        <th className="projects-table-title">Invoices</th>
                        <th className="projects-table-title px-5" />
                        <th className="projects-table-title px-5" />
                        <th className="projects-table-title px-5" />
                        <th className="projects-table-title px-5" />
                        <th className="projects-table-title">Billing date</th>
                        <th className="projects-table-title">Status</th>
                        <th className="projects-table-title">Amount</th>
                        <th className="projects-table-title">Plan</th>
                        <th className="projects-table-title">Action</th>
                    </tr>
                </thead>
                <tbody className="table-main-body">
                    {items && items.length > 0 ? (
                        items.map((item, index) => (
                            <TableInvoiceItem
                                key={index}
                                item={item}
                                downloadInvoice={downloadInvoice}
                            />
                        ))
                    ) : (
                        <tr>
                            <td colSpan="12" className="text-center">
                                No invoices found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default TableInvoice;
