import React, { Fragment, useEffect, useState } from 'react';
import Select, { components } from 'react-select';

const defaultStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        color: '#001a37',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '400',
        backgroundColor: 'white',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 999,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#adb5bd',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? '#f6f7f9'
            : state.isSelected
              ? '#f6f7f9;'
              : 'white',
        ':active': {
            // backgroundColor: state.isSelected ? 'yellow' : 'pink'
        },
        color: '#001a37',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        display: 'flex',
        alignItems: 'center',
        opacity: state.isDisabled ? 0.5 : 1, // add opacity for disabled state
        pointerEvents: state.isDisabled ? 'none' : 'auto', // add pointerEvents for disabled state
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        display: 'flex',
        border: '2px solid #edeff3',
        borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : '',
        color: '#001a37',
        borderRadius: '6px',
        // ...(isError && {
        //     border: "1px solid #dc3545",
        //     borderColor: "#dc3545 !important"
        // }),
        '&:hover': {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        },
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

const Menu = (props) => {
    return (
        <Fragment>
            <components.Menu {...props}>
                <div>{props.children}</div>
            </components.Menu>
        </Fragment>
    );
};

const Option = (props) => {
    return (
        <Fragment>
            <components.Option {...props}>{props.children}</components.Option>
        </Fragment>
    );
};

const FormErrorText = ({ text }) => {
    if (text) {
        return (
            <div className="invalid-feedback" style={{ display: 'block' }}>
                {text}
            </div>
        );
    }
    return null;
};
const CustomSelect = ({
    options,
    defaultOption,
    onChange,
    className,
    value,
    customStyles,
    fieldError,
    isMulti,
    isMultiSelect,
    isDisabled,
    menuPortalTarget,
    placeholder,
    name,
    isClearable,
}) => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (fieldError) {
            showError();
        }
        if (!fieldError) {
            clearError();
        }
    }, [fieldError]);

    const showError = () => {
        setIsError(true);
    };

    const clearError = () => {
        // Remove all error styles
        setIsError(false);
    };

    const errorText = fieldError ? fieldError[0] : '';
    // const styles = customStyles ? customStyles(fieldError) : defaultStyles(fieldError);

    const styles = {
        ...defaultStyles,
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (provided, state) =>
            isError
                ? {
                      ...provided,
                      border: `2px solid #dc3545`,
                      borderColor: '#dc3545 !important',
                      width: '100%',
                      display: 'flex',
                      color: '#001a37',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      height: '44px',
                  }
                : {
                      ...provided,
                      width: '100%',
                      display: 'flex',
                      borderColor: state.isFocused ? '#86b7fe' : '#edeff3',
                      borderWidth: '2px',
                      boxShadow: state.isFocused
                          ? '0 0 0 0.25rem rgb(13 110 253 / 25%)'
                          : '',
                      color: '#001a37',
                      minHeight: '44px',
                      borderRadius: '6px',
                      '&:hover': {
                          // Overwrittes the different states of border
                          borderColor: state.isFocused ? '#86b7fe' : '#edeff3',
                      },
                      zIndex: state.isFocused ? 1 : 0,
                  },
        ...customStyles,
    };

    const getValue = isMulti
        ? options && options.filter((obj) => value.includes(obj.value))
        : isMultiSelect
          ? value
          : options.filter((option) => option.value === value);

    const props = {
        className,
        options,
        onChange,
        placeholder,
        value: getValue,
        onFocus: clearError,
        classNamePrefix: 'select-main',
        isDisabled,
        name,
        isClearable,
    };

    return (
        <div>
            <Select
                {...props}
                isMulti={isMultiSelect}
                // className={className}
                // classNamePrefix="select-main"
                // options={options}
                components={{ Menu, Option }}
                styles={styles}
                defaultOption={defaultOption}
                menuPortalTarget={
                    menuPortalTarget === 'null' ? null : document.body
                }
                // onChange={onChange}
                // value={getValue}
                // onFocus={this.clearError}
            />
            {isError && <FormErrorText text={errorText} />}
        </div>
    );
};
export default CustomSelect;
