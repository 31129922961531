import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MoreHorizontal } from 'react-feather';

import PropTypes from 'prop-types';
import moment from 'moment/moment';
import Markdown from 'markdown-to-jsx';
import PreBlock from 'ui/PreBlock';
import TextEditor from 'components/TextEditor';
import useOutsideClick from 'hooks/useOutsideClick';
import { uniqueId } from 'utils/helpers';
import { projectSelectors } from 'store/slices/projectsSlice';
import { authSelectors } from 'store/slices/authSlice';
import { routeSelectors } from 'store/slices/routesSlice';
import {
    getComments,
    resetComments,
    resetCommentsStart,
    updateComment,
} from 'store/slices/commentsSlice';

const CommentsListItem = ({
    comment,
    toggleDropdown,
    selectedDropdown,
    handleClickAction,
    type,
}) => {
    const dispatch = useDispatch();

    const project = useSelector(projectSelectors.getCurrentProject);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const route = useSelector(routeSelectors.getCurrentRoute);

    const [showInputForUpdate, setShowInputForUpdate] = useState(false);
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState({});
    const [dropdownId, setDropdownId] = useState(undefined);
    const isShowDropdown = selectedDropdown === dropdownId;
    const dropdownRef = useRef(null);
    const hasRights =
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'OWNER' ||
        project.user_role === 'WRITE';

    useOutsideClick(dropdownRef, () => toggleDropdown(undefined, null));

    const onSuccessCommentUpdate = (response) => {
        toast.success('The comment has been updated');
        setShowInputForUpdate(false);
        dispatch(resetComments());
        dispatch(resetCommentsStart());
        dispatch(getComments({ type, typeId: route.id, start: 0 }));
    };

    const onErrorCommentUpdate = (error) => {
        setErrors(error?.response.data.message);
    };

    const handleEditorChange = (editorData) => {
        setDescription(editorData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const data = {
            body: description,
        };
        dispatch(
            updateComment({
                data,
                commentId: comment.id,
                onSuccess: onSuccessCommentUpdate,
                onerror: onErrorCommentUpdate,
            }),
        );
    };

    return (
        <li className="pb-4 d-flex w-100 comment-item">
            <div style={{ marginRight: '16px' }}>
                <img
                    src={comment?.user_avatar}
                    alt="avatar"
                    className="comment-image"
                />
            </div>
            <div className="w-100">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <h6 className="text-5h fw-bold">{comment.user_name}</h6>
                        <span className="px-3" style={{ opacity: '0.7' }}>
                            {`${moment(comment.created_at).format(
                                'DD/MM/YYYY',
                            )} at ${moment(comment.created_at).format(
                                'HH:mm',
                            )}`}
                        </span>
                    </div>
                    {myAccount.id === comment.user_id && hasRights && (
                        <div className="action-container">
                            <button
                                type="button"
                                className="btn-action edit hover-default comment-button-group"
                                onClick={(e) => {
                                    const id = uniqueId();
                                    setDropdownId(id);
                                    toggleDropdown(e, id);
                                }}
                            >
                                <MoreHorizontal size={16} color="#000000" />
                            </button>
                            {isShowDropdown && (
                                <ul
                                    ref={dropdownRef}
                                    className="dropdown-list"
                                    style={{ minWidth: '110px' }}
                                >
                                    <li>
                                        <button
                                            className="dropdown-link"
                                            name="edit"
                                            onClick={(e) => {
                                                setShowInputForUpdate(
                                                    !showInputForUpdate,
                                                );
                                                setDescription(comment.body);
                                                handleClickAction(
                                                    e,
                                                    null,
                                                    'update-comment',
                                                );
                                            }}
                                        >
                                            Edit
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="dropdown-link text-danger"
                                            name="delete"
                                            onClick={(e) =>
                                                handleClickAction(
                                                    e,
                                                    comment,
                                                    'delete-comment',
                                                )
                                            }
                                        >
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            )}
                        </div>
                    )}
                </div>
                <>
                    {showInputForUpdate ? (
                        <form onSubmit={handleSubmit} className="pt-1">
                            <TextEditor
                                description={description}
                                handleEditorChange={handleEditorChange}
                                errorText={errors?.body}
                                editorClassName={'ck-line-height'}
                                textEditorForComment={true}
                            />

                            <button
                                type="submit"
                                className="btn btn-primary mt-4 pl-3"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn mt-4"
                                onClick={() =>
                                    setShowInputForUpdate(!showInputForUpdate)
                                }
                            >
                                Cancel
                            </button>
                        </form>
                    ) : (
                        <>
                            {comment.body && (
                                <div className="">
                                    <div className="ck ck-line-height">
                                        <Markdown
                                            options={{
                                                overrides: {
                                                    pre: PreBlock,
                                                },
                                            }}
                                            className="ck word-break  m-0"
                                        >
                                            {comment.body}
                                        </Markdown>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            </div>
        </li>
    );
};

CommentsListItem.propTypes = {
    comment: PropTypes.object.isRequired,
    toggleDropdown: PropTypes.func,
    selectedDropdown: PropTypes.string,
    handleClickAction: PropTypes.func,
    type: PropTypes.string,
};

export default CommentsListItem;
