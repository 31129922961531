import { capitalize } from 'utils/helpers';
import { getCardIcon } from 'pages/Billing/ManageBilling/utils/utils';

export const CardInfo = ({ card }) => {
    const { brand, last4, exp_month, exp_year } = card?.card;

    return (
        <div className="billing--modal-card">
            <div className="d-flex align-items-start">
                <div className="mt-1">
                    <img
                        src={getCardIcon(brand)}
                        alt={getCardIcon(brand)}
                        width={40}
                        height={28}
                    />
                </div>
                <div className="d-flex flex-column px-3">
                    <h5 className="billing--paragraph opacity-100 fw-semibold">
                        {`${capitalize(brand)} ending in **** ${last4}`}
                    </h5>
                    <p className="billing--paragraph">{`Expiry ${exp_month}/${exp_year}`}</p>
                </div>
            </div>
        </div>
    );
};
