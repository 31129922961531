import { useState } from 'react';

import { Input } from 'ui/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'store/selectors';
import { updateMyAccount } from 'store/slices/authSlice';
import { toast } from 'react-toastify';

const ContactEmail = () => {
    const dispatch = useDispatch();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const [selectedEmail, setSelectedEmail] = useState(
        myAccount?.alternative_email ? 'alternative_email' : 'current_email',
    );
    const [email, setEmail] = useState(myAccount?.alternative_email || '');
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        setSelectedEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const data = { alternative_email: email };
        dispatch(updateMyAccount({ data, onSuccess, onError }));
    };

    const onSuccess = () => {
        toast.success('Your data has been saved.');
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <div className="p-4 billing--card-block">
            <h3 className="title-h5">Contact email</h3>
            <p className="billing--paragraph pt-1">
                Where should invoices be sent?
            </p>

            <div className="w-100">
                <form>
                    <div className="form-check mt-4 mb-0">
                        <Input
                            className="form-check-input"
                            type="radio"
                            name="selectedVariant"
                            value="current_email"
                            checked={selectedEmail === 'current_email'}
                            onChange={handleInputChange}
                            id="current-email"
                        />
                        <label
                            className="form-check-label px-2 "
                            htmlFor="current-email"
                        >
                            <h4 className="billing--title">
                                Send to my account email
                            </h4>
                            <p className="billing--paragraph pt-1">
                                {myAccount.email}
                            </p>
                        </label>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-3 mb-0">
                        <div className="form-check ">
                            <Input
                                className="form-check-input d-block"
                                type="radio"
                                name="selectedVariant"
                                value="alternative_email"
                                checked={selectedEmail === 'alternative_email'}
                                onChange={handleInputChange}
                                id="alternative-email"
                            />
                            <label
                                className="form-check-label px-2"
                                htmlFor="alternative-email"
                            >
                                <h4 className="billing--title">
                                    Send to an alternative email
                                </h4>
                            </label>
                        </div>
                        <button
                            className="btn btn-primary"
                            onSubmit={handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                    <Input
                        type="email"
                        name="email"
                        className="form-control mt-3"
                        labelClassName="form-label"
                        placeHolder="Enter alternative email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isDisabled={selectedEmail === 'current_email'}
                        errorText={errors?.alternative_email}
                    />
                </form>
            </div>
        </div>
    );
};

export default ContactEmail;
