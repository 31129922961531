import React, { useState } from 'react';

import { Input } from 'ui/Inputs';
import defaultImg from 'assets/img/default.svg';
import ImageCrop from 'components/ImageCrop';
import LocalLoader from 'ui/LocalLoader';

const REGEXP_ACCEPT_IMAGE = /(image\/gif|image\/jpe?g|image\/png)/i;

const CreateModal = ({
    onSubmit,
    onClose,
    onCreated,
    isAccessToCreateProject,
}) => {
    const [data, setData] = useState({ name: '' });
    const [imageProperties, setImageProperties] = useState({
        sourceImage: '',
        loadedImage: {},
        fullImage: {},
        croppedImage: {},
        currentLogo: '',
    });
    const [file, setFile] = useState({
        isWrongFileType: false,
        isFileLoading: false,
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const formData = () => {
        const fd = new FormData();

        fd.append('logo_thumb', imageProperties.croppedImage);
        // fd.append('logo', imageProperties.fullImage);
        fd.append('name', data.name);

        return fd;
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const dataForm = formData();
        const headers = imageProperties.currentLogo
            ? { 'Content-Type': 'multipart/form-data' }
            : { 'Content-Type': 'aplication/json' };
        const newData = imageProperties.currentLogo ? dataForm : data;

        onSubmit(newData, onCreated, onResponse, onError, headers);
    };

    const onResponse = (response) => {
        resetState();
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const onFileSelect = (e) => {
        setErrors((prev) => ({
            ...prev,
            logo: '',
        }));

        if (e.target.files && e.target.files[0]) {
            if (!REGEXP_ACCEPT_IMAGE.test(e.target.files[0].type)) {
                setFile({
                    ...file,
                    isWrongFileType: true,
                    isFileOversized: false,
                });
                return;
            }

            const targetFile = e.target.files[0];
            const reader = new FileReader();

            reader.onloadstart = () =>
                setFile({
                    isFileLoading: true,
                    isWrongFileType: false,
                });
            reader.onloadend = () => setFile({ ...file, isFileLoading: false });
            reader.onload = (e) => {
                setImageProperties({
                    ...imageProperties,
                    sourceImage: e.target.result,
                    loadedImage: targetFile,
                });

                setFile({
                    ...file,
                    isWrongFileType: false,
                });
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onSaveAvatar = (croppedImage) => {
        const imageUrl = window.URL.createObjectURL(croppedImage);

        setImageProperties({
            ...imageProperties,
            sourceImage: '',
            fullImage: imageProperties.loadedImage,
            croppedImage,
            currentLogo: imageUrl,
        });
    };

    const onCancelAvatarSelection = () => {
        setImageProperties({ ...imageProperties, sourceImage: '' });
    };

    const resetState = () => {
        setData({ name: '' });
        setImageProperties({
            sourceImage: '',
            loadedImage: {},
            fullImage: {},
            croppedImage: {},
            currentLogo: '',
        });
        setErrors({});
        setFile({
            isWrongFileType: false,
            isFileLoading: false,
        });
    };

    const onEditCurrentLogo = () => {
        if (!imageProperties.currentLogo) return;

        const reader = new FileReader();

        reader.onload = (e) => {
            setImageProperties({
                ...imageProperties,
                sourceImage: e.target.result,
            });
            setFile({
                ...file,
                isWrongFileType: false,
            });
        };

        reader.readAsDataURL(imageProperties.fullImage);
    };

    const onCloseCreateModal = () => {
        onClose();
        resetState();
    };

    return (
        <>
            <div className="modal-body">
                {imageProperties.sourceImage ? (
                    <ImageCrop
                        sourceImage={imageProperties.sourceImage}
                        saveAvatar={onSaveAvatar}
                        cancelAvatarSelection={onCancelAvatarSelection}
                    />
                ) : (
                    <div>
                        <div className="form-group mt-3">
                            <Input
                                type="text"
                                name="name"
                                className="form-control mt-2"
                                value={data.name}
                                onChange={handleChange}
                                labelText="Name"
                                labelClassName="label-main fw-bold"
                                placeHolder="Enter project name"
                                errorText={errors.name}
                                isRequired={true}
                                isDisabled={!isAccessToCreateProject}
                            />
                        </div>
                        {!isAccessToCreateProject && (
                            <p className="mt-2 ">
                                To create more projects please upgrade your plan
                            </p>
                        )}
                        <div className="form-group mt-3">
                            <span className="fw-bold">Logo</span>
                            <div className="mt-2 mb-2 d-flex align-items-center">
                                <div className="position-relative">
                                    <img
                                        className={`border-radius-8 user-select-none ${
                                            imageProperties.currentLogo &&
                                            'cursor-pointer'
                                        }`}
                                        src={
                                            imageProperties.currentLogo ||
                                            defaultImg
                                        }
                                        alt="logo"
                                        width="56px"
                                        height="56px"
                                        onClick={onEditCurrentLogo}
                                    />
                                </div>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="input-file"
                                    accept="image/gif,image/jpeg,image/png"
                                    onChange={onFileSelect}
                                />
                                <div className="mx-3">
                                    <label
                                        className="input-file-label btn-link"
                                        htmlFor="file"
                                    >
                                        Upload logo
                                    </label>
                                    {imageProperties.currentLogo && (
                                        <span
                                            className="link-danger text-decoration-underline cursor-pointer d-block mt-3"
                                            onClick={() => {
                                                setImageProperties({
                                                    ...imageProperties,
                                                    croppedImage: {},
                                                    fullImage: {},
                                                    currentLogo: '',
                                                });
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    logo: '',
                                                }));
                                            }}
                                        >
                                            Remove
                                        </span>
                                    )}
                                </div>

                                <LocalLoader
                                    loading={file.isFileLoading}
                                    size={20}
                                />
                            </div>
                            {file.isWrongFileType && (
                                <span className="text-danger d-block mt-3">
                                    Wrong file format. Supported formats: jpeg,
                                    jpg, png, gif.
                                </span>
                            )}
                            {errors?.logo_thumb && (
                                <span className="text-danger d-block mt-3">
                                    {errors?.logo_thumb[0]}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {!imageProperties.sourceImage && (
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        disabled={!isAccessToCreateProject}
                    >
                        Create
                    </button>

                    <button
                        type="button"
                        onClick={onCloseCreateModal}
                        className="btn btn-link"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                </div>
            )}
        </>
    );
};

export default CreateModal;
