import React, { Fragment, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { MoreHorizontal } from 'react-feather';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'ui/Modal';
import LocalLoader from 'ui/LocalLoader';
import useOutsideClick from 'hooks/useOutsideClick';
import useDropdownPosition from 'hooks/useDropdownPosition';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    deleteResponsePermanent,
    getResponses,
    getTrashedResponses,
    resourceSelectors,
    restoreResponse,
} from 'store/slices/resourcesSlice';

const ResponsesTrash = ({ responsesTrashList, inputSearch, isShowModels }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const isResponsesTrashFetching = useSelector(
        resourceSelectors.getIsResponsesTrashFetching,
    );

    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [isDeleteResponseModalShown, setIsDeleteResponseModalShown] =
        useState(false);

    const dropdownRef = useRef();

    const hasRights =
        project.user_role === 'MAINTAINER' ||
        project.owner.id === myAccount.id ||
        project.user_role === 'WRITE';

    const handleClickOutside = (e) => {
        toggleDropdown(e, null);
    };
    useOutsideClick(dropdownRef, handleClickOutside);
    useDropdownPosition(dropdownRef, selectedDropdown);
    const toggleDropdown = (e, id) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setSelectedDropdown(id);
    };

    const handleClickAction = (e, data, action) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        switch (action) {
            case 'restore-response':
                dispatch(
                    restoreResponse({
                        responseId: data.id,
                        onSuccess: onRestoreResponseSuccess,
                    }),
                );
                break;
            case 'delete-response':
                setIsDeleteResponseModalShown(!isDeleteResponseModalShown);
                setSelectedData(data);
                break;

            default:
                break;
        }
    };

    const onPermanentDeleteResponse = () => {
        dispatch(
            deleteResponsePermanent({
                responseId: selectedData.id,
                onSuccess: onDeleteResponsePermanentSuccess,
            }),
        );
    };

    const onDeleteResponsePermanentSuccess = () => {
        handleClickAction(undefined, null, 'delete-response');
        toast.success('Response permanently deleted');
        history.push(
            `/project/api/components?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(
            getTrashedResponses({ projectUid: project.uid, apiId: api.id }),
        );
    };

    const onRestoreResponseSuccess = () => {
        toast.success('Response succesfully restored');
        history.push(
            `/project/api/components?project=${project.uid}&api=${api.uid}`,
        );

        dispatch(getResponses({ apiId: api.id }));
        dispatch(
            getTrashedResponses({ projectUid: project.uid, apiId: api.id }),
        );
    };

    if (!responsesTrashList.length && inputSearch)
        return (
            <LocalLoader loading={isResponsesTrashFetching} className="mt-5">
                <div
                    className={`nav flex-column nav-children ${
                        !isShowModels && 'd-none'
                    }`}
                >
                    <div className="create-route-panel not-found">
                        <p className="create-route-panel__desc">
                            No responses found
                        </p>
                    </div>
                </div>
            </LocalLoader>
        );

    return (
        <LocalLoader loading={isResponsesTrashFetching} className="mt-5">
            <>
                <div
                    className={`nav flex-column nav-children ${
                        !isShowModels && 'd-none'
                    }`}
                >
                    <ul className={`nav flex-column`}>
                        {responsesTrashList?.map((response) => {
                            const isShowDropdown =
                                selectedDropdown === response.id;
                            return (
                                <li
                                    key={response.id}
                                    className="nav-item sidebar-link-item mx-2"
                                >
                                    <NavLink
                                        className=" nav-link sidebar-link sidebar-link-route word-break"
                                        to={`/project/api/components/responses/browse/${response.id}?project=${project.uid}&api=${api.uid}`}
                                        activeClassName="sidebar-link-active nav-link sidebar-link sidebar-link-route word-break"
                                    >
                                        <span className="me-1">
                                            {response.name}
                                        </span>
                                        <div className="action-container">
                                            {hasRights && (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="btn-action edit hover-default"
                                                        onClick={(e) => {
                                                            toggleDropdown(
                                                                e,
                                                                response.id,
                                                            );
                                                        }}
                                                    >
                                                        <MoreHorizontal
                                                            size={16}
                                                            color="#000000"
                                                        />
                                                    </button>
                                                    {isShowDropdown && (
                                                        <ul
                                                            ref={dropdownRef}
                                                            className="dropdown-list"
                                                        >
                                                            <li>
                                                                <button
                                                                    className="dropdown-link"
                                                                    type="button"
                                                                    name="edit"
                                                                    onClick={(
                                                                        e,
                                                                    ) =>
                                                                        handleClickAction(
                                                                            e,
                                                                            response,
                                                                            'restore-response',
                                                                        )
                                                                    }
                                                                >
                                                                    Restore
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    className="dropdown-link text-danger"
                                                                    type="button"
                                                                    name="delete"
                                                                    onClick={(
                                                                        e,
                                                                    ) =>
                                                                        handleClickAction(
                                                                            e,
                                                                            response,
                                                                            'delete-response',
                                                                        )
                                                                    }
                                                                >
                                                                    Delete
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <Modal
                    show={isDeleteResponseModalShown}
                    title="Delete"
                    body="This response will be permanently deleted, are you sure?"
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onPermanentDeleteResponse}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={(e) =>
                                    handleClickAction(
                                        undefined,
                                        null,
                                        'delete-response',
                                    )
                                }
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
            </>
        </LocalLoader>
    );
};

export default ResponsesTrash;
