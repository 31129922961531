import { useEffect, useState } from 'react';
import { Calendar } from 'react-feather';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import TableInvoice from './TableInvoice';
import { useDispatch, useSelector } from 'react-redux';
import {
    billingSelectors,
    getInvoices,
    downloadInvoice,
} from 'store/slices/billingSlice';
import LocalLoader from 'ui/LocalLoader';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const InvoiceMain = () => {
    const dispatch = useDispatch();
    const invoices = useSelector(billingSelectors.getAllInvoices);
    const isFetching = useSelector(billingSelectors.getIsInvoicesFetching);

    const [params, setParams] = useState({
        search: '',
        from: moment().subtract(1, 'month').format(DATE_FORMAT),
        to: moment().format(DATE_FORMAT),
    });

    useEffect(() => {
        dispatch(getInvoices({ params }));
    }, [dispatch, params]);

    const handleDate = (date, name) => {
        setParams({ ...params, [name]: moment(date).format(DATE_FORMAT) });
    };

    const onInvoiceDownload = async (invoiceId) => {
        const response = await dispatch(downloadInvoice(invoiceId)).unwrap();

        onSuccessInvoiceDownload(response);
    };

    const onSuccessInvoiceDownload = (response) => {
        const stringifiedCollection = JSON.stringify(response);
        const collectionUrl = window.URL.createObjectURL(
            new Blob([stringifiedCollection]),
        );
        const a = document.createElement('a');
        a.href = collectionUrl;
        a.download = `${response.info.name}.json`;
        a.click();
    };

    return (
        <div className="pt-5 mx-5">
            <div className="d-flex align-items-center pb-4">
                <h2 className="title-h3">Billing and invoicing</h2>
                <div className="ms-4 position-relative">
                    <Calendar size={16} className="input-icon" />
                    <DatePicker
                        value={params.from}
                        onChange={(date) => handleDate(date, 'from')}
                        maxDate={new Date()}
                    />
                </div>
                <div className="ms-3 position-relative">
                    <Calendar size={16} className="input-icon" />
                    <DatePicker
                        value={params.to}
                        onChange={(date) => handleDate(date, 'to')}
                        minDate={params.from}
                        maxDate={new Date()}
                    />
                </div>
            </div>
            {isFetching ? (
                <LocalLoader />
            ) : (
                <TableInvoice
                    items={invoices}
                    downloadInvoice={onInvoiceDownload}
                />
            )}
        </div>
    );
};

export default InvoiceMain;
