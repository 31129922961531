import React from 'react';
import { Star } from 'react-feather';
import { ProjectAvatar } from 'components/Avatar';

export const StarredProjects = (props) => {
    const { projects, changeProject } = props;

    return (
        <>
            <div className={projects?.length ? 'px-2 pb-2' : 'px-2'}>
                <span className="projects-favorite sub-menu p-2 mt-1">
                    Starred
                </span>
            </div>
            <div className="starred-projects-container overflow-auto">
                {projects.map((project) => {
                    return (
                        <div key={project.id} className="d-flex">
                            <a
                                className="dropdown-item link-dropdown py-2 text-break"
                                href={`/project?project=${project.uid}`}
                                onClick={changeProject}
                                name={project.name}
                                id={project.uid}
                            >
                                <ProjectAvatar project={project} />
                                <span className="me-2">{project.name}</span>
                                <span className="header-project-star">
                                    <Star
                                        size={14}
                                        className="header-project-star-img"
                                    />
                                </span>
                            </a>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
