import { Helmet } from 'react-helmet';

import MainContent from 'ui/MainContent';
import ManageBilling from './ManageBilling/ManageBilling';
import InvoiceMain from './TableBilling/InvoiceMain';

const BillingPage = () => {
    return (
        <MainContent>
            <div className="main-container">
                <Helmet>
                    <title>Billing - CDP</title>
                </Helmet>

                <div className="page-header d-flex flex-column mx-sm-5 mx-3 mb-5">
                    <h1 className="title-h3">Billing</h1>
                    <p className="pt-2 billing--paragraph">
                        Manage your billing and payment details
                    </p>
                </div>
                <ManageBilling />
                <InvoiceMain />
            </div>
        </MainContent>
    );
};

export default BillingPage;
