import { Input } from 'ui/Inputs';
import closeIcon from '../../../../../../assets/img/x.svg';

const ModalAddCard = ({ setIsShowModal, setIsShowModalAddCard }) => {
    const closeModal = () => {
        setIsShowModalAddCard(false);
        setIsShowModal(true);
    };
    return (
        <div className="modal-body ">
            <div>
                <div className="modal-header border-bottom-0 align-items px-0 pb-4">
                    <h5 className="modal-title fw-semibold">
                        Add new payment card
                    </h5>
                    <div onClick={closeModal} className="cursor-pointer">
                        <img src={closeIcon} alt="close" />
                    </div>
                </div>
                <div className="billing--modal-card billing--modal-card-background border-0 p-3 m-0">
                    <h6 className="title-h5">Payment method</h6>
                    <div>
                        <div className="form-group my-3">
                            <Input
                                type="text"
                                name="title"
                                className="form-control"
                                value={'John Doe'}
                                labelText="Card header name"
                                labelClassName="form-label opacity-75"
                                placeHolder="Enter card name"
                            />
                        </div>
                        <div className="form-group mb-3">
                            <Input
                                type="text"
                                name="title"
                                className="form-control"
                                value={'4455 8899 0948 0012'}
                                labelText="Card number"
                                labelClassName="form-label opacity-75"
                                placeHolder="Enter card name"
                            />
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                            <div className="form-group w-50">
                                <Input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    value={'10/28'}
                                    labelText="Expiration date"
                                    labelClassName="form-label opacity-75"
                                    placeHolder="Enter card name"
                                />
                            </div>
                            <div className="form-group w-50">
                                <Input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    value={'***'}
                                    labelText="CVC"
                                    labelClassName="form-label opacity-75"
                                    placeHolder="Enter card name"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex gap-3 align-items-center mt-4 mb-3">
                    <div className="form-group w-50">
                        <Input
                            type="text"
                            name="title"
                            className="form-control"
                            value={'John'}
                            labelText="First name"
                            labelClassName="form-label fw-semibold"
                            placeHolder="Enter your first name"
                        />
                    </div>
                    <div className="form-group w-50">
                        <Input
                            type="text"
                            name="title"
                            className="form-control"
                            value={'Doe'}
                            labelText="Last name"
                            labelClassName="form-label fw-semibold"
                            placeHolder="Enter your last name"
                        />
                    </div>
                </div>
                <div className="form-group mb-3">
                    <Input
                        type="text"
                        name="title"
                        className="form-control"
                        value={''}
                        labelText="Company"
                        labelClassName="form-label fw-semibold"
                        placeHolder="Enter your company"
                    />
                </div>
                <div className="form-group mb-3">
                    <Input
                        type="text"
                        name="title"
                        className="form-control"
                        value={''}
                        labelText="Address"
                        labelClassName="form-label fw-semibold"
                        placeHolder="Enter card your address"
                    />
                </div>
                <div className="form-group mb-4">
                    <Input
                        type="text"
                        name="title"
                        className="form-control"
                        value={''}
                        labelText="Country"
                        labelClassName="form-label fw-semibold"
                        placeHolder="Enter card your country"
                    />
                </div>
            </div>
            <div className="d-flex gap-3 align-items-center">
                <button type="submit" className="btn btn-primary w-50">
                    Save
                </button>
                <button
                    type="button"
                    onClick={closeModal}
                    className="btn w-50"
                    style={{ color: '#0d6efd' }}
                    data-dismiss="modal"
                >
                    Return back to Payment
                </button>
            </div>
        </div>
    );
};

export default ModalAddCard;
