import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconCheck from 'assets/img/landing/Icon-checkmark.svg';

const pricingCardItemContent = [
    { content: 'Unlimited APIs', free: '1 API', medium: '10 APIs' },
    { content: 'Unlimited Users', free: '1 User', medium: '10 Users' },
    { content: 'Multiple environments' },
    { content: 'Import/Export your collections (Postman, Swagger)' },
    { content: 'Internal documentation space' },
    { content: 'Publish documentation under your company domain' },
    { content: 'Route history', lineThrough: true },
    { content: 'Comments', lineThrough: true },
    { content: 'Backups', lineThrough: true },
    { content: 'Notifications', lineThrough: true },
    { content: 'Support 24/7', lineThrough: true },
    { content: 'Branded documentation page', lineThrough: true, popular: true },
    { content: 'Mock server', lineThrough: true, popular: true },
    { content: 'SSO', lineThrough: true, popular: true },
];

const defaultRedirect = '/signup';

const PricingCard = (props) => {
    const {
        description,
        subscription,
        status,
        price,
        features,
        term,
        addSubscription,
        plan,
        redirect = defaultRedirect,
        currentPlan,
    } = props;

    const isCurrentPlan = currentPlan?.code === plan?.code;

    const handleButton = () => {
        if (isCurrentPlan) return;
        if (plan) {
            addSubscription(plan);
        }
    };

    return (
        <div
            className={
                status === 'medium'
                    ? 'card pricing-card pricing-card__medium mb-4'
                    : 'card pricing-card mb-4'
            }
        >
            <div className="card-body p-md-4">
                <div className="pricing-card__header">
                    {status === 'medium' ? (
                        <div className="d-flex justify-content-between">
                            <p className="mb-3 pricing-card__subscription">
                                {subscription}
                            </p>
                            <div>
                                <span className="pricing-card__popular">
                                    Popular
                                </span>
                            </div>
                        </div>
                    ) : (
                        <p className="mb-3 pricing-card__subscription">
                            {subscription}
                        </p>
                    )}
                    <div className="d-flex align-items-center">
                        <h2 className="card-title pricing-card__title mb-3">
                            {status !== 'enterprise'
                                ? `€${price}`
                                : 'Individually'}
                        </h2>
                        <p className="mx-2 mb-3 pricing-card__text">
                            {status !== 'enterprise' && status !== 'free'
                                ? `${term}`
                                : ''}
                        </p>
                    </div>
                    <p className="mb-4 pricing-card__text pricing-card__text-font">
                        {description}
                    </p>
                </div>
                <div className="pricing-card__divider" />
                <p className="pt-4 pb-2 pricing-card__features">FEATURES</p>
                <p className="pricing-card__text pricing-card__text-font">
                    {features}
                </p>
                <ul className="list-unstyled mt-3 pricing-card__list w-100">
                    {pricingCardItemContent.map((item, index) => (
                        <li
                            className={classNames('pricing-card__item', {
                                'pricing-card__item--status':
                                    item.lineThrough && status === 'free',
                            })}
                            style={{
                                opacity:
                                    item.popular && status === 'medium'
                                        ? '0.4'
                                        : '',
                                textDecorationLine:
                                    item.popular && status === 'medium'
                                        ? 'line-through'
                                        : '',
                            }}
                            key={index}
                        >
                            <div className="d-flex align-items-center">
                                <img
                                    src={iconCheck}
                                    alt="checkmark"
                                    className="pricing-card__icon"
                                    style={{
                                        color:
                                            (item.lineThrough &&
                                                status === 'free') ||
                                            (item.popular &&
                                                status === 'medium')
                                                ? '#001A37'
                                                : '#00BF80',
                                    }}
                                />
                                <p>
                                    {status === 'free' &&
                                        `${item.free || item.content}`}
                                    {status === 'medium' &&
                                        `${item?.medium || item.content}`}
                                    {status === 'enterprise' &&
                                        `${item.content}`}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>

                <div className="text-center" onClick={handleButton}>
                    {redirect === defaultRedirect ? (
                        <a
                            href={defaultRedirect}
                            className="btn btn-block btn-primary mx-auto w-100 pricing-card__btn"
                        >
                            Get Started
                        </a>
                    ) : (
                        <button
                            className="btn btn-block btn-primary mx-auto w-100 pricing-card__btn"
                            disabled={isCurrentPlan}
                        >
                            {isCurrentPlan ? 'Current plan' : 'Change plan'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

PricingCard.propTypes = {
    description: PropTypes.string,
    subscription: PropTypes.string,
    className: PropTypes.string,
    price: PropTypes.string,
    term: PropTypes.string,
    status: PropTypes.string,
    features: PropTypes.string,
    addSubscription: PropTypes.func,
};

export default PricingCard;
