import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header/Header';
import { ToastContainer } from 'react-toastify';

import GlobalLoader from 'ui/GlobalLoader';
import ConfirmEmail from 'components/ConfirmEmail';
import {
    apiSelectors,
    appSelectors,
    authSelectors,
    projectSelectors,
} from 'store/selectors';
import { init } from 'store/slices/appSlice';
import { isCreateProjectModalShown } from 'store/slices/projectsSlice';
import { isCreateApiModalShown } from 'store/slices/apiSlice';
import { confirmEmail } from 'store/slices/authSlice';
import CookieConsentBanner from 'components/CookieConsentBanner';

const MasterLayout = ({ children }) => {
    const dispatch = useDispatch();
    const { projectCode, apiCode } = useParams();

    const isLoaded = useSelector(appSelectors.getIsLoaded);
    const showGlobalLoader = useSelector(appSelectors.getShowGlobalLoader);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);

    useEffect(() => {
        addMiddleware();

        const queryParams = new URLSearchParams(window.location.search);
        const projectUid = queryParams.get('project') || projectCode;
        const apiUid = queryParams.get('api') || apiCode;

        dispatch(init({ projectUid, apiUid, apiCode }));
    }, [apiCode, dispatch, projectCode]);

    const addMiddleware = () => {};

    const onClickCreateProject = () => {
        dispatch(isCreateProjectModalShown(true));
    };

    const onClickCreateApi = () => {
        dispatch(isCreateApiModalShown(true));
    };

    const onClickConfirmEmail = (data, onSuccess) => {
        dispatch(confirmEmail({ data, onSuccess }));
    };

    return isLoaded ? (
        <div className="master-container overflow-hidden">
            <ConfirmEmail
                myAccount={myAccount}
                onClickConfirm={onClickConfirmEmail}
            />
            <Header
                onClickCreateProject={onClickCreateProject}
                onClickCreateApi={onClickCreateApi}
                project={project}
                api={api}
                myAccount={myAccount}
            />
            <div className="d-flex justify-content-start main-layout">
                {children}
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                icon={false}
            />
            <CookieConsentBanner />
            <GlobalLoader isLoading={showGlobalLoader} />
        </div>
    ) : (
        <GlobalLoader isLoading={true} />
    );
};

export default MasterLayout;
