import React from 'react';
import PropTypes from 'prop-types';
import { Search } from 'react-feather';

const InputSearch = ({
    id,
    name,
    type,
    className,
    placeHolder,
    value,
    isDisabled,
    inputStyle,
    labelStyle,
    onChange,
}) => {
    return (
        <label
            htmlFor="input-search"
            className="position-relative"
            style={labelStyle}
        >
            <Search size={16} className="input-icon" />
            <input
                id={id}
                name={name}
                type={type}
                style={inputStyle}
                className={className}
                value={value}
                onChange={onChange}
                placeholder={placeHolder}
                disabled={isDisabled}
            />
        </label>
    );
};

InputSearch.defaultProps = {
    type: 'text',
    value: '',
    name: '',
    className: '',
    placeHolder: '',
    inputStyle: {},
    labelStyle: {},
    isFullWidth: false,
    onChange: () => {},
};

InputSearch.propTypes = {
    /** Input tag type default is text. */
    type: PropTypes.string,
    /** Set value/Text to the input tag */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Placeholder of the input tag */
    placeHolder: PropTypes.string,
    /** Input disabled or not */
    isDisabled: PropTypes.bool,
    /** Used to set input width 100% */
    isFullWidth: PropTypes.bool,
    /** Function to call on input Change */
    onChange: PropTypes.func,
    /** Name of the input tag */
    name: PropTypes.string,
    /** Id of the input tag */
    id: PropTypes.string,
    /** Input element ClassName */
    className: PropTypes.string,
    /** Input element styles */
    inputStyle: PropTypes.object,
    /** Label element styles */
    labelStyle: PropTypes.object,
};
export default InputSearch;
