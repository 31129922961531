import { Input } from 'ui/Inputs';
import { getCardIcon } from 'pages/Billing/ManageBilling/utils/utils';
import { AddPaymentCard } from 'pages/Billing/ManageBilling/AddPaymentCard';
import plusIcon from 'assets/img/plus.svg';
import { capitalize } from 'utils/helpers';
import { Trash2 } from 'react-feather';

export const CardList = ({
    cards,
    selectedCardId,
    handleCardElementChange,
    handleChangeCard,
    handleChangeCardName,
    handleShowNewCard,
    showNewCard,
    newCardIdInput,
    cardName,
    error,
    handleDeleteCard,
}) => {
    return (
        <div>
            <p className="billing--paragraph pt-3">Saved cards</p>
            {cards.map(({ card, id }) => (
                <div
                    key={id}
                    className="billing--modal-card d-flex align-items-center"
                >
                    <Input
                        className="form-check-input"
                        type="radio"
                        id={id}
                        checked={selectedCardId === id}
                        onChange={() => handleChangeCard(id)}
                    />
                    <label className="form-check-label ms-3 d-flex align-items-center justify-content-between w-100" htmlFor={id}>
                        <div className="d-flex align-items-start">
                            <div className="mt-1">
                                <img
                                    src={getCardIcon(card.brand)}
                                    alt={getCardIcon(card.brand)}
                                    width={40}
                                    height={40}
                                />
                            </div>
                            <div className="d-flex flex-column px-3">
                                <h5 className="billing--paragraph opacity-100 fw-semibold">
                                    {capitalize(card.brand)} ending in ****{' '}
                                    {card.last4}
                                </h5>
                                <p className="billing--paragraph">
                                    {`Expiry ${card.exp_month}/${card.exp_year}`}
                                </p>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="text-danger fw-bold d-flex align-items-center bg-white"
                            onClick={() => handleDeleteCard(id)}
                        >
                            <Trash2 color="#dc3545" size={18} />
                        </button>
                    </label>
                </div>
            ))}
            {!showNewCard ? (
                <div
                    className="billing--modal-card justify-content-center align-items-center d-flex cursor-pointer"
                    onClick={() => handleShowNewCard(true)}
                >
                    <div className="d-flex">
                        <img src={plusIcon} alt="Add new card" />
                        <p
                            className="billing--paragraph opacity-100 fw-semibold ms-2"
                            style={{ color: '#0d6efd' }}
                        >
                            Add new payment card
                        </p>
                    </div>
                </div>
            ) : (
                <div className="billing--modal-card d-flex align-items-center billing--new-card">
                    <Input
                        className="form-check-input"
                        type="radio"
                        name="paymentMethod"
                        id={newCardIdInput}
                        checked={selectedCardId === newCardIdInput}
                        onChange={() => handleChangeCard(newCardIdInput)}
                    />
                    <AddPaymentCard
                        cardName={cardName}
                        handleCardElementChange={handleCardElementChange}
                        handleChangeCardName={handleChangeCardName}
                        error={error}
                    />
                </div>
            )}
        </div>
    );
};
