import moment from 'moment/moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { countRouteMethod, createShallowCopy } from 'utils/helpers';

export default function RecentRoutes({
    routes,
    routesTrash,
    pages,
    pagesTrash,
    project,
    api,
}) {
    const setTag = (array, tag) => {
        return array.map((el) => {
            const objCopy = createShallowCopy(el);
            objCopy.tag = tag;
            return objCopy;
        });
    };

    const taggedRoutes = setTag(routes, 'routes');
    const taggedTrashedRoutes = setTag(routesTrash, 'routes');
    const taggedPages = setTag(pages, 'pages');
    const taggedTrashPages = setTag(pagesTrash, 'pages');

    const sortAndCobineData = (...args) => {
        const sortCallback = (a, b) =>
            new Date(b.updated_at) - new Date(a.updated_at);

        const result = args.reduce((acc, el) => {
            const sortedArray = el?.toSorted(sortCallback);
            const lastUpdatetdElements = sortedArray.slice(0, 5);

            return [...acc, ...lastUpdatetdElements];
        }, []);
        return result.toSorted(sortCallback).slice(0, 5);
    };

    const recentData = sortAndCobineData(
        taggedRoutes,
        taggedTrashedRoutes,
        taggedPages,
        taggedTrashPages,
    );

    return (
        <div className="mt-4">
            <h6>Recent changes</h6>
            <ul className="mt-3">
                {recentData?.map((el, i) => (
                    <li key={i}>
                        {el.method && (
                            <span>{countRouteMethod(el.method)}</span>
                        )}
                        {el.tag === 'pages' && (
                            <span
                                style={{
                                    marginRight: '4px',
                                    fontSize: '10px',
                                    minWidth: '31px',
                                    height: '14px',
                                    textAlign: 'center',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    color: '#000000',
                                }}
                            >
                                PAGE
                            </span>
                        )}
                        <Link
                            to={`/project/api/${el?.tag}/browse/${el?.id}?project=${project?.uid}&api=${api?.uid}`}
                            className="me-2"
                        >
                            <span>{el?.name || el?.title}</span>
                        </Link>
                        {el.tag === 'routes' && <span>-</span>}
                        <span>
                            {el?.url && (
                                <span className="mx-2 recent-data__path">
                                    {el?.url}
                                </span>
                            )}
                            <span className="recent-data__path">|</span>
                            <span className="ms-2 recent-data__date">
                                {el?.deleted_at === el?.updated_at && (
                                    <>
                                        <span className="me-1 recent-data__deleted">
                                            deleted
                                        </span>
                                        {moment(el?.deleted_at).format(
                                            'DD/MM/YYYY hh:mm',
                                        )}
                                    </>
                                )}
                                {el?.created_at === el?.updated_at && (
                                    <>
                                        <span className="me-1 recent-data__created">
                                            created
                                        </span>
                                        {moment(el?.updated_at).format(
                                            'DD/MM/YYYY hh:mm',
                                        )}
                                    </>
                                )}
                                {el?.created_at !== el?.updated_at &&
                                    !el?.deleted_at && (
                                        <>
                                            <span className="me-1 recent-data__updated">
                                                updated
                                            </span>
                                            {moment(el?.updated_at).format(
                                                'DD/MM/YYYY hh:mm',
                                            )}
                                        </>
                                    )}
                            </span>
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}
