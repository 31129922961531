import { useState } from 'react';
import Modal from 'ui/Modal';
import CheckoutForm from './components/CheckoutForm';
import ModalAddCard from './components/ModalAddCard';
import PlanForm from './components/PlanForm';
import { ResultPayment } from './components/ResultPayment';

export const ModalPayment = ({ setIsShowModal }) => {
    const [isShowModalAddCard, setIsShowModalAddCard] = useState(false);
    const [resultPayment, setResultPayment] = useState(null);

    return (
        <>
            <div className="modal-body">
                {resultPayment ? (
                    <ResultPayment
                        setIsShowModal={setIsShowModal}
                        setResultPayment={setResultPayment}
                        resultPayment={resultPayment}
                    />
                ) : (
                    <>
                        <PlanForm setIsShowModal={setIsShowModal} />
                        <CheckoutForm
                            setResultPayment={setResultPayment}
                            setIsShowModal={setIsShowModal}
                        />
                    </>
                )}
                <Modal
                    show={isShowModalAddCard}
                    body={
                        <ModalAddCard
                            setIsShowModal={setIsShowModal}
                            setIsShowModalAddCard={setIsShowModalAddCard}
                        />
                    }
                />
            </div>
        </>
    );
};
