import visaIcon from 'assets/img/visa-card.svg';
import masterCardIcon from 'assets/img/master-card.svg';
import defaultCard from 'assets/img/default-payment-card.svg';

export const getCardIcon = (brand) => {
    switch (brand.toLowerCase()) {
        case 'visa':
            return visaIcon;
        case 'mastercard':
            return masterCardIcon;
        default:
            return defaultCard;
    }
};
