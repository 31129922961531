import { useContext, useState } from 'react';
import { useOutsideClickAlternative } from './hooks/useOutsideClickAlternative';
import { Cross, Tip } from './assets/Svg';
import CustomSelect from 'ui/CustomSelect';
import { createShallowCopy, isValidValue, resolveType } from './helpers';
import { Input } from 'ui/Inputs';
import { Trash2 } from 'react-feather';
import { SchemaTypeContext } from './JsonSchemaEditor';
import { useEffect } from 'react';
import {
    ArrayExtraProps,
    BooleanExtraProps,
    IntegerExtraProps,
    NumberExtraProps,
    ObjectExtraProps,
    StringExtraProps,
} from './ExtraPropsForms';
import { omit } from 'utils/helpers';

const formatModelName = (str) => {
    const found = str.match(/\[.*?\]/g);
    return found ? found[0].slice(1, -1) : str;
};

const behaviourOptions = [
    { value: 'read/write', label: 'Read/Write' },
    { value: 'read', label: 'Read' },
    { value: 'write', label: 'Write' },
];

export default function TypesBox({
    listItem,
    onChangeType,
    schemaName,
    currentModel,
    close,
    rowTypePosition,
    models,
    changeNullable,
    changeEnum,
    changeEnumValues,
    changeProperties,
    typeBoxIsOpen,
    changeExtraProperties,
}) {
    const {
        type,
        subType,
        isNullable,
        parent,
        enumValue,
        behaviour,
        isRequired,
        description,
        extraProps,
    } = listItem;
    const [rowTypeWidth, setRowTypeWidth] = useState(rowTypePosition.width);
    const [rowTypeLeft, setRowTypeLeft] = useState(rowTypePosition.left);
    const [selectedType, setSelectedType] = useState(resolveType(type));
    const [selectedSubType, setSelectedSubType] = useState(
        resolveType(subType) || 'none',
    );
    const [selectedTab, setSelectedTab] = useState(
        selectedType === '$ref' ? 'models' : 'types',
    );
    const [selectedModel, setSelectedModel] = useState(currentModel || '');
    const [nullable, setNullable] = useState(!!isNullable);
    const [enumValuesList, setEnumValuesList] = useState(enumValue || []);
    const types = ['object', 'array', 'number', 'integer', 'string', 'boolean'];
    const subTypes = [
        'none',
        'object',
        'array',
        'number',
        'integer',
        'string',
        'boolean',
        '$ref',
    ];
    const combiners = ['oneOf', 'anyOf', 'allOf'];
    const [properties, setProperties] = useState({
        behaviour: behaviour,
        isRequired: !!isRequired,
        description: description,
    });
    const [extraProperties, setExtraProperties] = useState(extraProps);
    const schemaType = useContext(SchemaTypeContext);
    const box = useOutsideClickAlternative(close);

    useEffect(() => {
        setExtraProperties(extraProps);
    }, [extraProps]);

    const onChange = (e) => {
        const { id: type } = e.target.dataset;

        setSelectedType(type);
        setSelectedModel('');
        setEnumValuesList([]);

        if (type !== 'array') setSelectedSubType('none');

        onChangeType({ type, subType: null });
    };

    const onSelectChange = ({ label }, tab) => {
        setSelectedModel(label);
        if (tab === 'models') setSelectedType('');
        setNullable(false);
        if (selectedTab === 'models') setSelectedSubType('none');
        const type = tab === 'models' ? '$ref' : listItem.type[0];
        const subType = tab === 'types' ? '$ref' : null;
        onChangeType({ type, subType }, label);
    };

    const onChangeSubType = (e) => {
        const { id: subType } = e.target.dataset;
        setSelectedSubType(subType);
        setSelectedModel('');
        onChangeType({
            type: listItem.type[0],
            subType: subType === 'none' ? null : subType,
        });
    };

    const changeTab = (e) => {
        const { dataset } = e.target;

        setSelectedTab(dataset.tabType);
    };

    const onChangeIsNullable = (e) => {
        setNullable((prev) => !prev);

        changeNullable(e);
    };

    const onChangeEnum = () => {
        setSelectedType('string');
        setSelectedModel('');
        setSelectedSubType('none');
        setNullable(false);

        changeEnum();
    };

    const addEnumValue = () => {
        setEnumValuesList((prev) => [...prev, '']);
    };

    const onEnumInputChange = (e, i) => {
        const newData = [...enumValuesList];
        const value = e.target.value;

        newData[i] = value;
        setEnumValuesList(newData);
        changeEnumValues(newData);
    };

    const onDeleteEnumValue = (idx) => {
        const newData = [...enumValuesList];
        const result = newData.filter((el, i) => i !== idx);
        setEnumValuesList(result);
        changeEnumValues(result);
    };

    const onChangeProperties = (e) => {
        const id = e.target.id;
        const newData = createShallowCopy(properties);

        if (id === 'required') {
            newData.isRequired = e.target.checked;
            setProperties((prev) => ({
                ...prev,
                isRequired: e.target.checked,
            }));
        }

        if (id === 'description') {
            newData.description = e.target.value;
            setProperties((prev) => ({ ...prev, description: e.target.value }));
        }

        changeProperties(newData);
    };

    const onChangeExtraProperties = (e) => {
        const newData = createShallowCopy(extraProperties);
        const { id, type, value } = e.target;

        if (!value) {
            const newExtraProps = omit(newData, id);

            setExtraProperties(newExtraProps);
            changeExtraProperties(newExtraProps);

            return;
        }

        const newValue =
            type === 'checkbox'
                ? e.target.checked
                : type === 'number' && value
                  ? Number(value)
                  : value;

        newData[id] = newValue;
        setExtraProperties((prev) => ({
            ...prev,
            [id]: newValue,
        }));

        changeExtraProperties(newData);
    };

    const onFormatSelectChange = (option) => {
        const newData = createShallowCopy(extraProperties);

        if (!isValidValue(option)) {
            const newExtraProps = omit(newData, 'format');

            setExtraProperties(newExtraProps);
            changeExtraProperties(newExtraProps);

            return;
        }

        newData.format = option.value;

        setExtraProperties((prev) => ({ ...prev, format: option.value }));
        changeExtraProperties(newData);
    };

    const onBehaviourSelectChange = (option) => {
        const newData = createShallowCopy(properties);

        newData.behaviour = option.value;

        setProperties((prev) => ({ ...prev, behaviour: option.value }));
        changeProperties(newData);
    };

    useEffect(() => {
        if (typeBoxIsOpen && rowTypeWidth !== rowTypePosition.width) {
            setRowTypeWidth(rowTypeWidth);
        } else {
            setRowTypeWidth(rowTypePosition.width);
        }

        if (typeBoxIsOpen && rowTypeLeft !== rowTypePosition.left) {
            setRowTypeLeft(rowTypeLeft);
        } else {
            setRowTypeLeft(rowTypePosition.left);
        }
    }, [
        rowTypeLeft,
        rowTypePosition.left,
        rowTypePosition.width,
        rowTypeWidth,
        typeBoxIsOpen,
    ]);

    const modelsList = models
        .reduce((acc, model) => {
            acc.push({
                value: Object.keys(model)[0],
                label: Object.keys(model)[0],
            });
            return acc;
        }, [])
        .filter((el) => el.label !== schemaName);

    const isSchemaRoot = !parent;
    const isNullableButtonDisabled =
        isSchemaRoot || type === '$ref' || subType === '$ref' || enumValue;

    const extraPropsMap = {
        string: (
            <StringExtraProps
                extraProps={extraProperties}
                onChangeExtraProperties={onChangeExtraProperties}
                onFormatSelectChange={onFormatSelectChange}
            />
        ),
        integer: (
            <IntegerExtraProps
                extraProps={extraProperties}
                onChangeExtraProperties={onChangeExtraProperties}
                onFormatSelectChange={onFormatSelectChange}
            />
        ),
        number: (
            <NumberExtraProps
                extraProps={extraProperties}
                onChangeExtraProperties={onChangeExtraProperties}
                onFormatSelectChange={onFormatSelectChange}
            />
        ),
        boolean: (
            <BooleanExtraProps
                extraProps={extraProperties}
                onChangeExtraProperties={onChangeExtraProperties}
            />
        ),
        object: (
            <ObjectExtraProps
                extraProps={extraProperties}
                onChangeExtraProperties={onChangeExtraProperties}
            />
        ),
        array: (
            <ArrayExtraProps
                extraProps={extraProperties}
                onChangeExtraProperties={onChangeExtraProperties}
            />
        ),
    };

    return (
        <div
            ref={box}
            className="JsonSchemaEditor__schema-types-modal"
            style={{
                top: rowTypePosition.top + window.scrollY - 450,
                left: rowTypeLeft + rowTypeWidth + 12,
            }}
        >
            <div className="JsonSchemaEditor__schema-types-modal-inner-wrapper">
                <div className="mb-3">
                    <button
                        className={
                            selectedTab === 'types'
                                ? 'JsonSchemaEditor__tab-button JsonSchemaEditor__tab-button--active me-2'
                                : 'JsonSchemaEditor__tab-button me-2'
                        }
                        onClick={changeTab}
                        data-tab-type="types"
                        type="button"
                    >
                        Types
                    </button>
                    <button
                        className={
                            selectedTab === 'models'
                                ? 'JsonSchemaEditor__tab-button JsonSchemaEditor__tab-button--active me-2'
                                : 'JsonSchemaEditor__tab-button me-2'
                        }
                        onClick={changeTab}
                        data-tab-type="models"
                        type="button"
                    >
                        Models
                    </button>
                </div>
                {selectedTab === 'models' && (
                    <div className="ps-1">
                        <span className="JsonSchemaEditor__schema-types-modal-title">
                            MODELS
                        </span>
                        <CustomSelect
                            name="models"
                            options={modelsList}
                            onChange={(options) =>
                                onSelectChange(options, 'models')
                            }
                            value={
                                selectedSubType === '$ref' && selectedModel
                                    ? ''
                                    : selectedModel
                            }
                        />
                    </div>
                )}
                {selectedTab === 'types' && (
                    <div>
                        <span className="JsonSchemaEditor__schema-types-modal-title">
                            TYPE
                        </span>
                        <div className="JsonSchemaEditor__schema-types-modal-radio-group">
                            {types.map((el, i) => (
                                <label
                                    className={
                                        selectedType === el && !enumValue
                                            ? 'JsonSchemaEditor__schema-types-modal-radio-checkbox JsonSchemaEditor__schema-types-modal-radio-checkbox--active'
                                            : 'JsonSchemaEditor__schema-types-modal-radio-checkbox'
                                    }
                                    key={i}
                                >
                                    <span className="input-wrapper">
                                        <input
                                            type="radio"
                                            data-id={el}
                                            checked={
                                                el === selectedType &&
                                                !enumValue
                                            }
                                            onChange={onChange}
                                        />
                                    </span>
                                    <span>{el}</span>
                                </label>
                            ))}
                            <label
                                className={
                                    enumValue
                                        ? 'JsonSchemaEditor__schema-types-modal-radio-checkbox JsonSchemaEditor__schema-types-modal-radio-checkbox--active'
                                        : 'JsonSchemaEditor__schema-types-modal-radio-checkbox'
                                }
                                key="enum"
                            >
                                <span className="input-wrapper">
                                    <input
                                        type="radio"
                                        id="enum"
                                        checked={enumValue || ''}
                                        onChange={onChangeEnum}
                                    />
                                </span>
                                <span>enum</span>
                            </label>
                        </div>
                        {selectedType === 'array' && (
                            <div className="mt-3">
                                <span className="JsonSchemaEditor__schema-types-modal-title">
                                    SUBTYPE
                                </span>
                                <div className="JsonSchemaEditor__schema-types-modal-radio-group">
                                    {subTypes.map((el, i) => (
                                        <label
                                            className={
                                                selectedSubType === el
                                                    ? 'JsonSchemaEditor__schema-types-modal-radio-checkbox JsonSchemaEditor__schema-types-modal-radio-checkbox--active'
                                                    : 'JsonSchemaEditor__schema-types-modal-radio-checkbox'
                                            }
                                            key={i}
                                        >
                                            <span className="input-wrapper">
                                                <input
                                                    type="radio"
                                                    data-id={el}
                                                    checked={
                                                        el === selectedSubType
                                                    }
                                                    onChange={onChangeSubType}
                                                />
                                            </span>
                                            <span>{el}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="mt-3">
                            <span className="JsonSchemaEditor__schema-types-modal-title">
                                COMBINERS
                            </span>
                            <div className="JsonSchemaEditor__schema-types-modal-radio-group">
                                {combiners.map((el, i) => (
                                    <label
                                        className={
                                            selectedType === el && !enumValue
                                                ? 'JsonSchemaEditor__schema-types-modal-radio-checkbox JsonSchemaEditor__schema-types-modal-radio-checkbox--active'
                                                : 'JsonSchemaEditor__schema-types-modal-radio-checkbox'
                                        }
                                        key={i}
                                    >
                                        <span className="input-wrapper">
                                            <input
                                                type="radio"
                                                data-id={el}
                                                checked={
                                                    el === selectedType &&
                                                    !enumValue
                                                }
                                                onChange={onChange}
                                            />
                                        </span>
                                        <span>{el}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                        {selectedSubType === '$ref' && (
                            <div className="mt-3 ps-1">
                                <span className="JsonSchemaEditor__schema-types-modal-title">
                                    MODELS
                                </span>
                                <CustomSelect
                                    name="models"
                                    options={modelsList}
                                    onChange={(options) =>
                                        onSelectChange(options, 'types')
                                    }
                                    value={formatModelName(selectedModel)}
                                />
                            </div>
                        )}
                        <div className="my-2"></div>
                        {enumValue && (
                            <>
                                <div className="JsonSchemaEditor__schema-types-enum-list">
                                    {enumValuesList.map((el, i) => (
                                        <div key={i} className="p-1 d-flex">
                                            <Input
                                                type="text"
                                                className="JsonSchemaEditor__schema-types-enum-list-input"
                                                value={el}
                                                autoFocus={!el && true}
                                                onChange={(e) =>
                                                    onEnumInputChange(e, i)
                                                }
                                            />
                                            <div className="ms-2">
                                                <button
                                                    className="JsonSchemaEditor__delete-button"
                                                    onClick={() =>
                                                        onDeleteEnumValue(i)
                                                    }
                                                    type="button"
                                                >
                                                    <Trash2 size={16} />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="p-1">
                                    <button
                                        onClick={addEnumValue}
                                        className="JsonSchemaEditor__generate-tab-button w-100"
                                        type="button"
                                        style={{ fontSize: '18px' }}
                                    >
                                        Add +
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                )}
                <div className="mt-3">
                    {schemaType === 'model' && !isSchemaRoot && (
                        <div className="mb-3 ps-1">
                            <label className="JsonSchemaEditor__schema-types-modal-title">
                                BEHAVIOUR
                            </label>
                            <CustomSelect
                                name="behaviour"
                                options={behaviourOptions}
                                onChange={onBehaviourSelectChange}
                                value={properties.behaviour}
                            />
                        </div>
                    )}
                    <div className="JsonSchemaEditor__schema-types-modal-extra-props">
                        {schemaType !== 'response' && !isSchemaRoot && (
                            <div className="d-flex align-items-center mb-3 ps-1 me-3">
                                <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    checked={properties.isRequired}
                                    onChange={onChangeProperties}
                                    id="required"
                                />
                                <label className="ms-2" htmlFor="required">
                                    Required
                                </label>
                            </div>
                        )}
                        <div className="d-flex align-items-center mb-3 ps-1 me-3">
                            <input
                                className="form-check-input mt-0"
                                type="checkbox"
                                checked={nullable}
                                onChange={onChangeIsNullable}
                                id="nullable"
                                disabled={isNullableButtonDisabled}
                            />
                            <label
                                className={`${
                                    isNullableButtonDisabled
                                        ? 'disabled ms-2'
                                        : 'ms-2'
                                }`}
                                htmlFor="nullable"
                            >
                                Nullable
                            </label>
                        </div>
                        <div className="d-flex align-items-center mb-3 ps-1">
                            <input
                                className="form-check-input mt-0"
                                type="checkbox"
                                defaultChecked={
                                    extraProperties?.deprecated || false
                                }
                                onChange={onChangeExtraProperties}
                                id="deprecated"
                            />
                            <label className="ms-2" htmlFor="deprecated">
                                Deprecated
                            </label>
                        </div>
                    </div>
                    {!enumValue && extraPropsMap[selectedType]}
                    <div className="ps-1 pb-1">
                        <Input
                            type="textarea"
                            id="description"
                            className="form-control"
                            value={properties.description}
                            onChange={onChangeProperties}
                            labelClassName="JsonSchemaEditor__schema-types-modal-title"
                            labelText="DESCRIPTION"
                            placeHolder="Description..."
                            rows="4"
                            limit={255}
                        />
                        <div className="mt-2 JsonSchemaEditor__schema-types-modal-description-counter">
                            {`${properties.description.length}/255`}
                        </div>
                    </div>
                </div>
                <div
                    className="JsonSchemaEditor__schema-types-modal-close-button-wrapper"
                    style={{ position: 'absolute', top: 16, right: 16 }}
                >
                    <button
                        className="JsonSchemaEditor__schema-types-modal-close-button"
                        onClick={() => close()}
                        type="button"
                    >
                        <Cross />
                    </button>
                </div>
            </div>
            <div className="d-flex flex-row-reverse mt-2">
                <button
                    style={{ maxWidth: 'fit-content' }}
                    className="JsonSchemaEditor__generate-tab-button"
                    type="button"
                    onClick={() => close()}
                >
                    Save
                </button>
            </div>
            <div className="JsonSchemaEditor__schema-types-modal-tip">
                <Tip />
            </div>
        </div>
    );
}
