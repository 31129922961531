import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocalLoader from 'ui/LocalLoader';
import { billingSelectors, setSelectedPlan } from 'store/slices/billingSlice';
import closeIcon from 'assets/img/x.svg';
import { PlanList } from './PlanList';
import { authSelectors } from 'store/slices/authSlice';

const PlanForm = ({ setIsShowModal }) => {
    const myAccount = useSelector(authSelectors.getMyAccount);
    const isPlansFetching = useSelector(billingSelectors.getIsPlansFetching);
    const plans = useSelector(billingSelectors.getAllPlans);
    const selectedPlanId = useSelector(billingSelectors.getSelectedPlanId);
    const dispatch = useDispatch();
    const handleChangePlan = useCallback(
        (plan) => {
            dispatch(setSelectedPlan(plan));
        },
        [dispatch],
    );

    const onCloseModal = () => {
        setIsShowModal((prev) => !prev);
        dispatch(setSelectedPlan(myAccount?.plan));
    };
    return (
        <LocalLoader loading={isPlansFetching}>
            <form>
                <div>
                    <div className="modal-header border-bottom-0 align-items px-0 pt-0">
                        <h5 className="modal-title fw-semibold">
                            Payment details
                        </h5>
                        <div onClick={onCloseModal} className="cursor-pointer">
                            <img src={closeIcon} alt="close" />
                        </div>
                    </div>
                    <PlanList
                        handleChangePlan={handleChangePlan}
                        selectedPlanId={selectedPlanId}
                        plans={plans}
                    />
                    <a
                        href="/plans"
                        className="d-inline-block pt-2"
                        target="_blank"
                    >
                        Check our plans
                    </a>
                </div>
            </form>
        </LocalLoader>
    );
};

export default PlanForm;
