import { CardList } from './components/CardsList';
import closeIcon from 'assets/img/x.svg';
import { useModalCardsList } from './hooks/useModalCardsList';

export const ModalCardsList = ({ setIsShowModal }) => {
    const {
        cards,
        selectedCardId,
        handleChangeCard,
        handleShowNewCard,
        showNewCard,
        cardName,
        handleCardElementChange,
        handleChangeCardName,
        error,
        newCardIdInput,
        isLoading,
        currentDefaultCard,
        handleSubmit,
        handleDeleteCard,
        resetCardsInputs,
    } = useModalCardsList();

    const isDisabled =
        selectedCardId === currentDefaultCard || isLoading || error;

    return (
        <div className="modal-body">
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="modal-header border-bottom-0 align-items p-0">
                        <h5 className="modal-title fw-semibold">
                            Change payment method
                        </h5>
                        <div
                            onClick={() => {
                                setIsShowModal((prev) => !prev);
                                resetCardsInputs();
                            }}
                            className="cursor-pointer"
                        >
                            <img src={closeIcon} alt="close" />
                        </div>
                    </div>
                </div>
                <CardList
                    cards={cards}
                    selectedCardId={selectedCardId}
                    handleCardElementChange={handleCardElementChange}
                    handleChangeCard={handleChangeCard}
                    handleChangeCardName={handleChangeCardName}
                    handleShowNewCard={handleShowNewCard}
                    showNewCard={showNewCard}
                    newCardIdInput={newCardIdInput}
                    cardName={cardName}
                    error={error}
                    handleDeleteCard={handleDeleteCard}
                />
                <button
                    type="submit"
                    className="btn btn-primary w-100 mt-4"
                    disabled={isDisabled}
                >
                    {isLoading ? 'Processing...' : 'Save changes'}
                </button>
            </form>
        </div>
    );
};
