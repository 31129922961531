import React from 'react';
import { ProjectAvatar } from 'components/Avatar';

const RecentProjects = ({ projects }) => {
    return (
        <>
            <div className="px-2 pb-2">
                <span className="projects-favorite sub-menu p-2">Recent</span>
            </div>
            {projects.slice(0, 3).map((project) => (
                <div key={project.id} className="d-flex">
                    <a
                        className="dropdown-item link-dropdown py-2 word-break"
                        href={`/project?project=${project.uid}`}
                        name={project.name}
                        id={project.uid}
                    >
                        <ProjectAvatar project={project} />
                        {project.name}
                    </a>
                </div>
            ))}
        </>
    );
};

export default RecentProjects;
