import invoiceIcon from 'assets/img/invoice-icon.svg';
import invoiceIconAction from 'assets/img/invoice-action.svg';
import { formatDate } from 'utils/helpers';
const TableInvoiceItem = ({ item, downloadInvoice }) => {
    const { title, billingDate, isPaid, amount, plan, id } = item;
    const statusText = isPaid ? 'paid' : 'unpaid';

    return (
        <tr className="table-main-row projects-table-item">
            <td>
                <div className="d-flex align-items-center">
                    <img src={invoiceIcon} alt="invoiceIcon" />
                    <p className="billing--paragraph opacity-100 fw-semibold px-2">
                        {title}
                    </p>
                </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{formatDate(billingDate)}</td>
            <td>
                <div className={`billing--item-status ${statusText}`}>
                    <span style={{ color: isPaid ? '#27AE60' : '#FC4D70' }}>
                        {statusText.toUpperCase()}
                    </span>
                </div>
            </td>
            <td>€{amount}</td>
            <td>{plan}</td>
            <td className="cursor-pointer">
                <div onClick={() => downloadInvoice(id)}>
                    <img src={invoiceIconAction} alt="deleteInvoice" />
                </div>
            </td>
        </tr>
    );
};

export default TableInvoiceItem;
