import React from 'react';
import { Helmet } from 'react-helmet';

import PageHeader from 'ui/PageTitle';
import InviteForm from './InviteForm';
import PendingInvites from './PendingInvites';
import MembersList from './MembersList';

const MembersPage = ({ project }) => {
    const hasRights =
        project.user_role === 'MAINTAINER' || project.user_role === 'OWNER';

    return (
        <div className="main-container m-5">
            <Helmet>
                <title>
                    Members - {project.name} - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>

            <PageHeader>Members</PageHeader>

            <div>
                {hasRights && (
                    <>
                        <InviteForm project={project} />
                        <PendingInvites project={project} />
                    </>
                )}
                <MembersList project={project} />
            </div>
        </div>
    );
};

export default MembersPage;
