import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PublicRoute from 'routes/PublicRoute';
import IndexPage from 'pages/LandingPage/IndexPage';
import LoginPage from 'pages/Login/LoginPage';
import SignupPage from 'pages/SignUp/SignupPage';
import ForgotPasswordPage from 'pages/ForgotPassword/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPassword/ResetPasswordPage';
import PrivateRoute from 'routes/PrivateRoute';
import ProjectsPage from 'pages/Projects/ProjectsPage';
import Logout from 'pages/Logout/Logout';
import MembersPage from 'pages/Members/MembersPage';
import MemberProfilePage from 'pages/Members/MemberProfilePage';
import UserSettingsLayout from 'pages/UserSettings/UserSettingsLayout';
import ProjectSettingsLayout from 'pages/ProjectSettings/ProjectSettingsLayout';
import ApiSettingsContainer from 'pages/ApiSettings/ApiSettingsContainer';
import InvitationSignUpPage from 'pages/InvitationSignUp/InvitationSignUpPage';
import ProjectPage from 'pages/Project/ProjectPage';
import RoutesContainer from 'pages/Routes/RoutesContainer';
import ResourcesContainer from 'pages/Resources/ResourcesContainer';
import HistoryContainer from 'pages/History/HistoryContainer';
import TransfersPage from 'pages/TransfersPage/TransfersPage';
import VerificationPage from 'pages/VerificationPage/VerificationPage';
import GoogleCallback from 'pages/Social/GoogleCallback';
import BillingPage from 'pages/Billing/BillingPage';
import ErrorBoundary from 'ui/ErrorBoundary';
import AppErrorFallback from 'components/AppErrorFallback/AppErrorFallback';
import PlansPage from 'pages/Plans/PlansPage';
// import PartnersPage from 'pages/Partners/PartnersPage';

export default function App() {
    return (
        <BrowserRouter>
            <ErrorBoundary fallback={<AppErrorFallback />}>
                <Switch>
                    <Route
                        path="/account/verify"
                        component={VerificationPage}
                    />
                    <PublicRoute exact path="/" component={IndexPage} />
                    {/* <PublicRoute exact path="/partners" component={PartnersPage} /> */}
                    <PublicRoute exact path="/login" component={LoginPage} />
                    <PublicRoute exact path="/signup" component={SignupPage} />
                    <PublicRoute
                        exact
                        path="/forgot-password"
                        component={ForgotPasswordPage}
                    />
                    <PublicRoute
                        exact
                        path="/reset-password"
                        component={ResetPasswordPage}
                    />
                    <PublicRoute
                        exact
                        path="/invite"
                        component={InvitationSignUpPage}
                    />
                    <PublicRoute
                        path="/google/auth/callback"
                        component={GoogleCallback}
                    />

                    <PrivateRoute path={`/logout`} component={Logout} />

                    <PrivateRoute
                        path="/user/settings"
                        component={UserSettingsLayout}
                    />
                    <PrivateRoute
                        path="/project/settings"
                        component={ProjectSettingsLayout}
                    />

                    <PrivateRoute
                        exact
                        path="/projects"
                        component={ProjectsPage}
                    />
                    <PrivateRoute
                        exact
                        path="/members"
                        component={MembersPage}
                    />
                    <PrivateRoute
                        path="/people/:id"
                        component={MemberProfilePage}
                    />

                    <PrivateRoute
                        exact
                        path="/billing"
                        component={BillingPage}
                    />

                    <PrivateRoute
                        exact
                        path="/transfers"
                        component={TransfersPage}
                    />

                    <PrivateRoute path="/plans" component={PlansPage} />

                    <PrivateRoute
                        exact
                        path="/project"
                        component={ProjectPage}
                    />
                    <PrivateRoute
                        exact
                        path="/project/:projectCode"
                        component={ProjectPage}
                    />
                    <PrivateRoute
                        path="/project/api/history"
                        component={HistoryContainer}
                    />
                    <PrivateRoute
                        path="/project/api/components"
                        component={ResourcesContainer}
                    />
                    <PrivateRoute
                        path="/project/api/settings"
                        component={ApiSettingsContainer}
                    />
                    <PrivateRoute
                        path="/project/api"
                        component={RoutesContainer}
                    />
                    <PrivateRoute
                        path="/project/:projectCode/api/:apiCode"
                        component={RoutesContainer}
                    />
                    <PrivateRoute
                        path="/r/:projectCode/:apiCode"
                        component={RoutesContainer}
                    />
                </Switch>
            </ErrorBoundary>
        </BrowserRouter>
    );
}
