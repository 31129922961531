import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import UserAvatar from 'components/Avatar/UserAvatar';
import { authSelectors } from 'store/slices/authSlice';

const ProfileSelector = () => {
    const myAccount = useSelector(authSelectors.getMyAccount);

    const userName = myAccount.first_name + ' ' + myAccount.last_name;

    return (
        <div className="btn-group">
            <button
                type="button"
                className="header-round-btn header-avatar-btn"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <UserAvatar
                    iconUrl={myAccount.avatar_url}
                    first_name={myAccount.first_name}
                    last_name={myAccount.last_name}
                    className="header-avatar-img"
                />
            </button>
            <ul className="dropdown-menu">
                <li className="px-2 pb-2">
                    <span className="projects-favorite sub-menu p-2 mt-1 d-block word-break">
                        {userName}
                    </span>
                </li>
                <li>
                    <a
                        className="dropdown-item link-dropdown header-link text-link-normal"
                        href="/user/settings/profile"
                    >
                        Settings
                    </a>
                </li>
                <li>
                    <a
                        className="dropdown-item link-dropdown header-link text-link-normal"
                        href="/billing"
                    >
                        Billing
                    </a>
                </li>
                <li>
                    <a
                        className="dropdown-item link-dropdown header-link text-link-normal"
                        href="/transfers"
                    >
                        Transfers
                    </a>
                </li>
                <li>
                    <hr className="dropdown-divider header-divider" />
                </li>
                <li>
                    <Link
                        className="dropdown-item link-dropdown header-link"
                        to="/logout"
                    >
                        Logout
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default ProfileSelector;
