import React from 'react';
import { Link } from 'react-router-dom';
import { UserAvatar } from 'components/Avatar';

const MembersTable = ({ members, toggleModal }) => {
    return (
        <div className="mx-5">
            <table className="table table-main">
                <thead className="table-main-head">
                    <tr className="table-main-row">
                        <th className="projects-table-title">Name</th>
                        <th className="projects-table-title">Email</th>
                        <th className="projects-table-title">Actions</th>
                        <th className="projects-table-title">Last login</th>
                    </tr>
                </thead>
                <tbody className="table-main-body">
                    {members.map((member) => {
                        return (
                            <tr key={member.uuid} className="table-main-row">
                                <td>
                                    <UserAvatar
                                        iconUrl={member.avatar_url}
                                        first_name={member.first_name}
                                        last_name={member.last_name}
                                    />
                                    <Link
                                        className="table-main-link projects-table-name"
                                        to={`/people/${member.uuid}`}
                                    >
                                        {`${member.first_name} ${member.last_name}`}
                                    </Link>
                                </td>
                                {/* <td className="text-link-normal">{member.description || 'Some description'}</td> */}
                                <td>{member.email}</td>
                                <td>
                                    <button
                                        type="button"
                                        id={member.uuid}
                                        className="btn-link-main text-link-normal"
                                        onClick={(id) => toggleModal(id)}
                                    >
                                        View access
                                    </button>
                                </td>
                                <td>{member.last_login_at}</td>
                            </tr>
                        );
                    })}
                    {members.length === 0 && (
                        <tr>
                            <td colSpan="3" className="text-center">
                                No members found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default MembersTable;
