import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const getPages = createAsyncThunk(
    'pages/getPages',
    async ({ projectUid, apiId }, { dispatch }) => {
        try {
            dispatch(isPagesFetching(true));
            const response = await http.get(
                `/api/v1/project/${projectUid}/api/${apiId}/pages`,
            );
            dispatch(isPagesFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isPagesFetching(false));
            console.log(error);
        } finally {
            dispatch(isPagesFetching(false));
        }
    },
);

export const getTrashedPages = createAsyncThunk(
    'pages/getTrashedPages',
    async ({ projectUid, apiId }, { dispatch }) => {
        try {
            dispatch(isTrashedPagesFetching(true));
            const response = await http.get(
                `/api/v1/project/${projectUid}/api/${apiId}/page/trash`,
            );
            dispatch(isTrashedPagesFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isTrashedPagesFetching(false));
            console.log(error);
        } finally {
            dispatch(isTrashedPagesFetching(false));
        }
    },
);

export const createPage = createAsyncThunk(
    'pages/createPage',
    async ({ data, apiId, onSuccess, onError }) => {
        try {
            const response = await http.post(
                `/api/v1/project/api/${apiId}/page`,
                data,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

export const getPage = createAsyncThunk(
    'pages/getPage',
    async ({ pageId }, { dispatch }) => {
        try {
            dispatch(isPageFetching(true));
            const response = await http.get(
                `/api/v1/project/api/page/${pageId}`,
            );
            dispatch(isPageFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isPageFetching(false));
        } finally {
            dispatch(isPageFetching(false));
        }
    },
);

export const updatePage = createAsyncThunk(
    'pages/updatePage',
    async ({ data, pageId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `/api/v1/project/api/page/${pageId}`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        }
    },
);

export const deletePage = createAsyncThunk(
    'pages/deletePage',
    async ({ pageId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(
                `/api/v1/project/api/page/${pageId}`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deletePagePermanent = createAsyncThunk(
    'pages/deletePagePermanent',
    async ({ pageId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/api/page/${pageId}/destroy`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const restorePage = createAsyncThunk(
    'pages/restorePage',
    async ({ pageId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/api/page/${pageId}/restore`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const emptyPagesTrash = createAsyncThunk(
    'pages/emptyPagesTrash',
    async ({ pUid, apiId }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(`api/v1/project/${pUid}/api/${apiId}/page/trash`);
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

const initialState = {
    pages: [],
    page: {},
    pagesTrash: [],
    isPageFetching: false,
    isPagesFetching: false,
    isPagesTrashFetching: false,
};

export const pagesSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        isPagesFetching(state, action) {
            state.isPagesFetching = action.payload;
        },
        isPageFetching(state, action) {
            state.isPageFetching = action.payload;
        },
        isTrashedPagesFetching(state, action) {
            state.isTrashedPagesFetching = action.payload;
        },
    },
    extraReducers: {
        [getPage.fulfilled]: (state, action) => {
            state.page = action.payload;
        },
        [getPages.fulfilled]: (state, action) => {
            state.pages = action.payload;
        },
        [getTrashedPages.fulfilled]: (state, action) => {
            state.pagesTrash = action.payload;
        },
    },
});

// Actions

export const { isPagesFetching, isPageFetching, isTrashedPagesFetching } =
    pagesSlice.actions;

// Selectors

export const pageSelectors = {
    getCurrentPage: (state) => state.pages.page,
    getPages: (state) => state.pages.pages,
    getPagesTrash: (state) => state.pages.pagesTrash,
    getIsPagesTrashFetching: (state) => state.pages.isPagesTrashFetching,
    getIsPagesFetching: (state) => state.pages.isPagesFetching,
    getIsPageFetching: (state) => state.pages.isPageFetching,
};

export default pagesSlice.reducer;
