import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { billingSelectors, getPlans } from 'store/slices/billingSlice';
import LocalLoader from 'ui/LocalLoader';
import MainContent from 'ui/MainContent';
import PricingCard from 'pages/LandingPage/PricingCard';
import { useEffect } from 'react';
import { authSelectors } from 'store/selectors';

const PlansPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const plans = useSelector(billingSelectors.getAllPlans);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentPlan = myAccount?.plan;

    const isPlansFetching = useSelector(billingSelectors.getIsPlansFetching);
    const addSubscription = (plan) => {
        history.push('/billing', {
            plan,
        });
    };
    useEffect(() => {
        dispatch(getPlans());
    }, [dispatch]);

    const singlePlan = plans.find((plan) => plan.code === 'FREE');
    const mediumPlan = plans.find((plan) => plan.code === 'MEDIUM');
    const enterprisePlan = plans.find((plan) => plan.code === 'ENTERPRICE');

    return (
        <MainContent>
            <div className="main-container plansContainer">
                <Helmet>
                    <title>Plans - CDP</title>
                </Helmet>

                <div className="page-header d-flex flex-column mx-sm-5 mx-3 mb-5">
                    <h1 className="title-h3">Plans</h1>
                    <p className="pt-2 plansContainer__subheader">
                        Choose the subscription plan for your needs
                    </p>
                </div>
                <LocalLoader loading={isPlansFetching}>
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-center my-3 my-md-5">
                        <PricingCard
                            subscription={'Free'}
                            price={'0'}
                            description={'For Your Startup'}
                            term={'Monthly'}
                            features={'Everything you need for startup...'}
                            amountUsers={'10'}
                            amountMemory={'2'}
                            support={'Email support'}
                            textButton={'Sign Up for Free'}
                            status={'free'}
                            addSubscription={addSubscription}
                            redirect={'/billing'}
                            currentPlan={currentPlan}
                            plan={singlePlan}
                        />
                        <PricingCard
                            subscription={'Medium'}
                            price={'49'}
                            description={'For Medium Teams'}
                            term={'Monthly'}
                            features={'Everything in free plan plus...'}
                            amountUsers={'20'}
                            amountMemory={'10'}
                            support={'Priority email support'}
                            textButton={'Get Started'}
                            style={{ margin: '0 32px' }}
                            status={'medium'}
                            addSubscription={addSubscription}
                            plan={mediumPlan}
                            redirect={'/billing'}
                            currentPlan={currentPlan}
                        />
                        <PricingCard
                            subscription={'Enterprise'}
                            price={'Individually'}
                            term={'Monthly'}
                            description={'For Big Companies Unlimited APIs'}
                            features={'Everything in medium plan plus...'}
                            amountUsers={'30'}
                            amountMemory={'15'}
                            support={'Phone and email support'}
                            textButton={'Contact us'}
                            status={'enterprise'}
                            addSubscription={addSubscription}
                            plan={enterprisePlan}
                            redirect={'/billing'}
                            currentPlan={currentPlan}
                        />
                    </div>
                </LocalLoader>
            </div>
        </MainContent>
    );
};

export default PlansPage;
