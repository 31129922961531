import React, { Fragment } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ArrowLeftCircle, FileText, Users } from 'react-feather';
import { useSelector } from 'react-redux';

import MainContent from 'ui/MainContent';
import { LeftSideBar, LeftSideBarHeader } from 'components/LeftSideBar';
import { ProjectAvatar } from 'components/Avatar';
import SummaryPage from './Summary/SummaryPage';
import MembersPage from './Members/MembersPage';
import { projectSelectors } from 'store/slices/projectsSlice';

const ProjectSettingsLayout = () => {
    const project = useSelector(projectSelectors.getCurrentProject);
    const { pathname } = useLocation();

    return (
        <Fragment>
            <LeftSideBar>
                <nav>
                    <LeftSideBarHeader>
                        <div className="d-flex align-items-center">
                            <ProjectAvatar project={project} />
                            <strong>{project ? project.name : ''}</strong>
                        </div>
                    </LeftSideBarHeader>

                    <a className="mx-2 mb-2 link-dropdown" href="/projects">
                        <ArrowLeftCircle
                            size={24}
                            className="mx-2"
                            strokeWidth={2}
                        />
                        Back to projects
                    </a>

                    <ul className="nav flex-column">
                        <li className="nav-item sidebar-link-item mx-2">
                            <a
                                className={
                                    (pathname === '/project/settings/summary' &&
                                        'sidebar-link-active ') +
                                    'nav-link sidebar-link'
                                }
                                href={`/project/settings/summary?project=${project.uid}`}
                            >
                                <FileText
                                    size={20}
                                    className="sidebar-link-icon mr-2"
                                    strokeWidth={1.5}
                                />
                                Summary
                            </a>
                        </li>
                        <li className="nav-item sidebar-link-item mx-2">
                            <a
                                className={
                                    (pathname === '/project/settings/members' &&
                                        'sidebar-link-active ') +
                                    'nav-link sidebar-link'
                                }
                                href={`/project/settings/members?project=${project.uid}`}
                            >
                                <Users
                                    size={20}
                                    className="sidebar-link-icon"
                                    strokeWidth={1.5}
                                />
                                Members
                            </a>
                        </li>
                    </ul>
                </nav>
            </LeftSideBar>
            <MainContent>
                <Switch>
                    <Route
                        exact
                        path="/project/settings/summary"
                        component={SummaryPage}
                    />
                    <Route exact path="/project/settings/members">
                        <MembersPage project={project} />
                    </Route>
                </Switch>
            </MainContent>
        </Fragment>
    );
};

export default ProjectSettingsLayout;
