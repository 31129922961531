import { useState } from 'react';

import Modal from 'ui/Modal';

import visaIcon from 'assets/img/visa-card.svg';
import editIcon from 'assets/img/icon - edit.svg';
import { useSelector } from 'react-redux';
import { billingSelectors } from 'store/slices/billingSlice';
import LocalLoader from 'ui/LocalLoader';
import { capitalize } from 'utils/helpers';
import { ModalCardsList } from './Modals/ModalCardsList';

const PaymentMethod = () => {
    const cards = useSelector(billingSelectors.getAllCards);
    const isLoadingCards = useSelector(billingSelectors.getIsCardsFetching);

    const [isShowModal, setIsShowModal] = useState(false);

    if (isLoadingCards) {
        return <LocalLoader />;
    }

    if (!cards?.length) return null;
    // TODO: after be be ready (need field for selected current card)
    const { brand, last4, exp_month, exp_year } = cards[0].card;

    return (
        <div className="p-4 billing--card-block">
            <h3 className="title-h5">Payment method </h3>
            <p className="billing--paragraph pt-1 pb-4">
                Change how you pay for your plan
            </p>

            <div
                className="billing--payment-card w-100"
                style={{ marginBottom: '48px' }}
            >
                <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex align-items-start">
                        <div className="mt-1">
                            <img src={visaIcon} alt="payment" />
                        </div>
                        <div className="d-flex flex-column px-3">
                            <h5 className="billing--paragraph billing--paragraph-lineheight opacity-100 fw-semibold">
                                {`${capitalize(brand)} ending in **** ${last4}`}
                            </h5>
                            <p className="billing--paragraph billing--paragraph-lineheight">
                                {`Expiry ${exp_month}/${exp_year}`}
                            </p>
                        </div>
                    </div>
                    <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => setIsShowModal((prev) => !prev)}
                    >
                        <img src={editIcon} alt="edit" className="d-block" />
                        <p className="billing--paragraph fs-6 px-2">Edit</p>
                    </div>
                </div>
            </div>
            <Modal
                show={isShowModal}
                body={<ModalCardsList setIsShowModal={setIsShowModal} />}
            />
        </div>
    );
};

export default PaymentMethod;
